import axios, { AxiosRequestConfig } from 'axios';
import { CLIConfig } from './types';

export let apiConfig: CLIConfig;
export let isDevelopment = process.env.NODE_ENV === 'development';
export let isCloud = process.env.REACT_APP_TOKEN_SOURCE === 'cloud';

const envBackendUrl = 'http://oa.buzz.ar';

if (isCloud) {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  apiConfig = {
    port: process.env.REACT_APP_STARTING_PORT || '',
    token: params.token || '',
    projectId: params.project || '',
    scenarioId: params.scenario || '',
    // backendUrl: process.env.REACT_APP_BACKEND_URL || '',
    backendUrl: envBackendUrl || '',
  };

  urlSearchParams.delete('token');
  urlSearchParams.delete('project');
  urlSearchParams.delete('scenario');
  window.history.replaceState(
    null,
    '',
    '?' + urlSearchParams + window.location.hash
  );
} else if (isDevelopment) {
  apiConfig = {
    port: process.env.REACT_APP_STARTING_PORT || '',
    token: process.env.REACT_APP_STARTING_TOKEN || '',
    projectId: process.env.REACT_APP_STARTING_PROJECT_ID || '',
    scenarioId: process.env.REACT_APP_STARTING_SCENARIO_ID || '',
    // backendUrl: process.env.REACT_APP_BACKEND_URL || '',
    backendUrl: envBackendUrl || '',
  };
} else {
  apiConfig = window.require('electron').remote.getGlobal('apiConfig');
}

export const BACKEND_URL = `${apiConfig.backendUrl}:${apiConfig.port}`;
export const api = axios.create({
  baseURL: BACKEND_URL,
});

export const apiRequest = (config: AxiosRequestConfig) => {
  const newConfigs = { ...config };
  const token = sessionStorage.jwt;

  if (!newConfigs.headers) {
    newConfigs.headers = {};
  }

  if (token) {
    newConfigs.headers.Authorization = `Bearer ${token}`;
  }

  newConfigs.headers['Content-Type'] =
    newConfigs.headers['Content-Type'] || 'application/json;charset=utf-8';

  newConfigs.headers.Accept = newConfigs.headers.Accept || 'application/json';

  return newConfigs;
};

export const configureAxios = () => {
  if (!apiConfig) {
    throw new Error('Cannot use OA requests without cli config');
  }

  const apiReq = api.interceptors.request.use(apiRequest);

  const request = axios.interceptors.request.use(
    (res) => res,
    (error) => {
      throw new Error(error.message);
    }
  );

  const response = axios.interceptors.response.use(
    (res) => res,
    (error) => {
      throw new Error(error.message);
    }
  );

  return () => {
    isDevelopment && axios.interceptors.request.eject(request);
    isDevelopment && axios.interceptors.response.eject(response);
    axios.interceptors.request.eject(apiReq);
  };
};
