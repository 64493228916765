import React, { useEffect, useState } from 'react';
import { Divider } from '@material-ui/core';
import BorderClearIcon from '@material-ui/icons/BorderClear';
import GridOnIcon from '@material-ui/icons/GridOn';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import PanoramaOutlinedIcon from '@material-ui/icons/PanoramaOutlined';
import {
  RedoOutlined,
  StorageOutlined,
  UndoOutlined,
} from '@material-ui/icons';

import { AllowedTransforms, PointerModes } from '../../types/default-objects';
import { SubstepAction } from '../../buzzcommon';

import styles from './styles.module.scss';
import { ArrowIcon, MoveIcon, ThreeDimensionalIcon } from '../../images/icons';
import { StylizedFab, StylizedSwitch } from '../stylized';

interface Props {
  activeAction?: SubstepAction | undefined;
  onWireframeEnable: () => void;
  onWireframeDisable: () => void;
  onToggleSkyBox: (enable: boolean) => void;
  onGridHelperEnable: (enable: boolean) => void;
  onAxesHelperEnable: (enable: boolean) => void;
  onPointerModeChanged: (mode: PointerModes) => void;
  onMetadataModalOpen: () => void;
}

const EditorToolbar = (props: Props) => {
  const {
    activeAction,
    onWireframeEnable,
    onWireframeDisable,
    onGridHelperEnable,
    onAxesHelperEnable,
    onPointerModeChanged,
    onToggleSkyBox,
    onMetadataModalOpen,
  } = props;

  const [isSkyBoxEnabled, setIsSkyBoxEnabled] = useState(false);
  const [wireframeEnabled, setWireframeEnabled] = useState(false);
  const [gridHelperEnabled, setGridHelperEnabled] = useState(true);
  const [axesHelperEnabled, setAxesHelperEnabled] = useState(true);
  const [pointerMode, setPointerMode] = useState<PointerModes>(
    PointerModes.select
  );

  useEffect(() => {
    if (!activeAction) handlePointerMode(PointerModes.select);
  }, [activeAction]);

  const handleSkyBoxToggleClick = () => {
    onToggleSkyBox(!isSkyBoxEnabled);
    setIsSkyBoxEnabled(!isSkyBoxEnabled);
  };

  const handleWireframeClick = () => {
    if (wireframeEnabled) {
      onWireframeDisable();
    } else {
      onWireframeEnable();
    }

    setWireframeEnabled(!wireframeEnabled);
  };

  const handleGridHelperClick = () => {
    onGridHelperEnable(!gridHelperEnabled);
    setGridHelperEnabled(!gridHelperEnabled);
  };

  const handleAxesHelperClick = () => {
    onAxesHelperEnable(!axesHelperEnabled);
    setAxesHelperEnabled(!axesHelperEnabled);
  };

  const handlePointerMode = (mode: PointerModes) => {
    setPointerMode(mode);
    onPointerModeChanged(mode ?? '');
  };

  return (
    <div className={styles.editorSettings}>
      <div className={styles.environmentButtons}>
        <StylizedFab
          onClick={() => onMetadataModalOpen()}
          tooltipTitle="Global metadata"
        >
          <StorageOutlined />
        </StylizedFab>

        <StylizedFab tooltipTitle="Undo">
          <UndoOutlined />
        </StylizedFab>

        <StylizedFab tooltipTitle="Redo">
          <RedoOutlined />
        </StylizedFab>

        <Divider
          orientation="vertical"
          flexItem={true}
          className={styles.divider}
        />

        <StylizedFab
          tooltipTitle="Skybox"
          onClick={handleSkyBoxToggleClick}
          isActive={isSkyBoxEnabled}
        >
          <PanoramaOutlinedIcon />
        </StylizedFab>

        <StylizedFab
          tooltipTitle="Wireframe"
          onClick={handleWireframeClick}
          isActive={wireframeEnabled}
        >
          <BorderClearIcon />
        </StylizedFab>

        <StylizedFab
          tooltipTitle="Grid"
          onClick={handleGridHelperClick}
          isActive={gridHelperEnabled}
        >
          <GridOnIcon />
        </StylizedFab>

        <StylizedFab
          tooltipTitle="Axes"
          onClick={handleAxesHelperClick}
          isActive={axesHelperEnabled}
        >
          <MergeTypeIcon />
        </StylizedFab>
      </div>

      <div className={styles.interactionButtons}>
        <StylizedFab
          buttonType="secondary"
          tooltipTitle="Select"
          onClick={() => handlePointerMode(PointerModes.select)}
          isActive={pointerMode === PointerModes.select}
        >
          <ArrowIcon />
        </StylizedFab>

        {AllowedTransforms.move.includes(
          activeAction ? activeAction.actionType : ''
        ) && (
          <StylizedFab
            buttonType="secondary"
            tooltipTitle="Move"
            onClick={() => handlePointerMode(PointerModes.move)}
            isActive={pointerMode === PointerModes.move}
          >
            <MoveIcon />
          </StylizedFab>
        )}

        {AllowedTransforms.rotate.includes(
          activeAction ? activeAction.actionType : ''
        ) && (
          <StylizedFab
            buttonType="secondary"
            tooltipTitle="Rotate"
            onClick={() => handlePointerMode(PointerModes.rotate)}
            isActive={pointerMode === PointerModes.rotate}
          >
            <ThreeDimensionalIcon />
          </StylizedFab>
        )}
      </div>

      <div className={styles.lightMode}>
        <p>Light mode</p>
        <StylizedSwitch />
      </div>
    </div>
  );
};

export default EditorToolbar;
