import { Remote } from 'electron';

export const remote: Remote | false =
  process.env.NODE_ENV !== 'development' &&
  process.env.REACT_APP_TOKEN_SOURCE !== 'cloud' &&
  window.require('electron').remote;

export const handleOnAppClose = (callback: (event: Electron.Event) => void) => {
  if (remote) remote.getCurrentWindow().on('close', callback);
};

export const destroyCurrentWindow = () =>
  remote && remote.getCurrentWindow().destroy();
