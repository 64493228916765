import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';

import styles from './styles.module.scss';
import { StylizedModal, StylizedButton } from '../../stylized';

interface IProps {
  open: boolean;
  onClose: () => void;
  onSaveProject: (save: boolean) => Promise<void>;
}

const SaveModal = (props: IProps) => {
  const { open, onClose, onSaveProject } = props;

  const [isProjectSaving, setIsProjectSaving] = useState(false);

  const projectSavedHandler = async (save: boolean) => {
    setIsProjectSaving(true);

    try {
      await onSaveProject(save);
    } catch (error) {
      console.log(error.message);
    }

    setIsProjectSaving(false);
  };

  return (
    <StylizedModal
      open={open}
      onClose={onClose}
      title="Unsaved changes"
      className={styles.saveModal}
    >
      <div className={styles.modalContent}>
        <p className={styles.description}>
          This project has unsaved changes. Do you want to save them?
        </p>

        <div className={styles.buttons}>
          <StylizedButton
            colorType="secondary"
            onClick={onClose}
            disabled={isProjectSaving}
            className={styles.button}
          >
            Cancel
          </StylizedButton>

          <div className={styles.actionButtons}>
            <StylizedButton
              colorType="secondary"
              onClick={() => projectSavedHandler(false)}
              disabled={isProjectSaving}
              className={styles.button}
            >
              Don't save
            </StylizedButton>

            <StylizedButton
              colorType="default"
              className={styles.button}
              onClick={() => projectSavedHandler(true)}
              disabled={isProjectSaving}
            >
              {isProjectSaving ? (
                <CircularProgress size={24} />
              ) : (
                <span>Save</span>
              )}
            </StylizedButton>
          </div>
        </div>
      </div>
    </StylizedModal>
  );
};

export default SaveModal;
