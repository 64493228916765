import React from 'react';
import { TrashBin } from '../../images/icons';
import { StylizedButton } from '../stylized';

import styles from './styles.module.scss';

export interface ListElement {
  id: number;
  name: string;
}

interface Props {
  elements: ListElement[];
  title: string;
  addButtonText: string;
  onSelect: (id: number) => void;
  onDelete: (id: number) => void;
  onElementAdd: () => void;
}

const PageList = (props: Props) => {
  const { elements, title, addButtonText, onSelect, onDelete, onElementAdd } =
    props;

  const renderElements = () => {
    return elements.map((element) => {
      return (
        <div
          className={styles.element}
          key={element.id}
          onClick={() => onSelect(element.id)}
        >
          <p className={styles.elementName}>{element.name}</p>
          <TrashBin
            className={styles.actionIcon}
            onClick={(e) => {
              e.stopPropagation();
              onDelete(element.id);
            }}
          />
        </div>
      );
    });
  };

  return (
    <div className={styles.elementsBlock}>
      <p className={styles.title}>{title}</p>
      <div className={styles.elements}>{renderElements()}</div>
      <StylizedButton
        onClick={onElementAdd}
        colorType="add-button"
        className={styles.addButton}
      >
        {addButtonText}
      </StylizedButton>
    </div>
  );
};

export default PageList;
