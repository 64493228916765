import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { ArProjectStep } from '../../buzzcommon';
import { TrashBin } from '../../images/icons';
import PageList, { ListElement } from '../page-list';
import styles from './styles.module.scss';
import { ConfirmationModal, HtmlEditorModal } from '../modals';
import { StylizedButton, StylizedScrollbars } from '../stylized';

interface Props {
  step: ArProjectStep;
  stepIndex: number;
  onClose: () => void;
  onSubstepSelect: (stepIndex: number, substepIndex?: number) => void;
  onSubStepCreate: (
    name: string,
    stepId: number,
    subStepId?: number | undefined
  ) => void;
  deleteSubstep: (id: number) => void;
  deleteStep: (id: number) => void;
  onStepChange: (name: string, description: string) => void;
  createAction: () => void;
  deleteAction: (id: number) => void;
  selectAction: (id: number) => void;
  postScenarioFile: (file: File) => Promise<any>;
  formScenarioFileUrl: (fileName: string) => string;
}

interface ConfirmationModalProps {
  message: React.ReactNode;
  onSubmit: () => void;
}

const StepPage = (props: Props) => {
  const {
    step,
    stepIndex,
    onClose,
    onSubstepSelect,
    onSubStepCreate,
    deleteSubstep,
    deleteStep,
    createAction,
    deleteAction,
    selectAction,
    postScenarioFile,
    formScenarioFileUrl,
  } = props;

  const [confirmationModal, setConfirmationModal] =
    useState<ConfirmationModalProps>();
  const [isHtmlEditorOpen, setIsHtmlEditorOpen] = useState(false);
  const [elements, setElements] = useState<ListElement[]>();
  const [isDefaultStep, setIsDefaultStep] = useState(false);

  useEffect(() => {
    if (stepIndex === 0) {
      setElements(
        step.substeps[0].actions.map((action, index) => {
          return { id: index, name: action.actionType };
        })
      );
    } else {
      setElements(
        step.substeps.map((substep, index) => {
          return { id: index, name: substep.name };
        })
      );
    }

    setIsDefaultStep(stepIndex === 0);
  }, [step]);

  const handleSubstepSelect = (id: number) => onSubstepSelect(stepIndex, id);

  const handleSubstepDelete = (substepIndex: number) => {
    if (!step.substeps[substepIndex]) return;

    updateConfirmationModal(
      'substep',
      substepIndex,
      elements ? elements.length : 0,
      deleteSubstep
    );
  };

  const handleSubstepCreate = () => {
    const substepsLength = step.substeps.length;
    const substepIndex = substepsLength ? substepsLength : 0;

    onSubStepCreate(`Substep ${substepIndex + 1}`, stepIndex, substepIndex);
  };

  const handleStepDelete = () => {
    updateConfirmationModal(
      'step',
      stepIndex,
      elements ? elements.length : 0,
      deleteStep
    );
  };

  const updateConfirmationModal = (
    objectName: 'step' | 'substep',
    objectIndex: number,
    subobjectAmount: number,
    action: (id: number) => void
  ) => {
    let subobjectName: string;
    if (objectName === 'step') {
      subobjectName = objectIndex === 0 ? 'action' : 'substep';
    } else {
      subobjectName = 'action';
    }

    setConfirmationModal({
      message: (
        <>
          {`The ${objectName} includes `}
          {step.substeps.length ? (
            <span className={styles.subobjects}>
              {subobjectAmount} {subobjectName}s
            </span>
          ) : (
            <span>no {subobjectName}</span>
          )}
          . Are you sure you want to delete it?
        </>
      ),
      onSubmit: () => {
        action(objectIndex);
        setConfirmationModal(undefined);
      },
    });
  };

  const handlerStepChange = (name: string, description: string) => {
    props.onStepChange(name, description);
    setIsHtmlEditorOpen(false);
  };

  return (
    <div className={styles.step}>
      <div className={styles.stepHeaderWrapper}>
        <div className={styles.stepHeader}>
          <p className={styles.stepName}>{step.name}</p>
          <div className={styles.description}>
            {ReactHtmlParser(step.description)}
          </div>
        </div>

        <StylizedButton
          colorType="default"
          className={styles.editStep}
          onClick={() => setIsHtmlEditorOpen(true)}
        >
          Edit
        </StylizedButton>
      </div>

      <div className={styles.scrollbarsWrapper}>
        <StylizedScrollbars>
          <div className={styles.main}>
            {elements && (
              <PageList
                elements={elements}
                title={isDefaultStep ? 'Actions:' : 'Substeps:'}
                addButtonText={isDefaultStep ? '+ Add action' : '+ Add substep'}
                onSelect={isDefaultStep ? selectAction : handleSubstepSelect}
                onDelete={isDefaultStep ? deleteAction : handleSubstepDelete}
                onElementAdd={
                  isDefaultStep ? createAction : handleSubstepCreate
                }
              />
            )}
          </div>
        </StylizedScrollbars>
      </div>

      <div className={styles.buttons}>
        <Button className={styles.closeButton} onClick={() => onClose()}>
          Close
        </Button>

        {!isDefaultStep && (
          <Button
            className={styles.deleteSubstepButton}
            onClick={handleStepDelete}
          >
            <TrashBin className={styles.trashBinIcon} />
            Delete step
          </Button>
        )}
      </div>

      {!!confirmationModal && (
        <ConfirmationModal
          open={!!confirmationModal}
          onClose={() => setConfirmationModal(undefined)}
          title="Delete confirmation"
          message={confirmationModal.message}
          onSubmit={confirmationModal.onSubmit}
        />
      )}

      {isHtmlEditorOpen && step && (
        <HtmlEditorModal
          open={isHtmlEditorOpen}
          onClose={() => setIsHtmlEditorOpen(false)}
          onStepChange={handlerStepChange}
          step={step}
          postScenarioFile={postScenarioFile}
          formScenarioFileUrl={formScenarioFileUrl}
        />
      )}
    </div>
  );
};

export default StepPage;
