// tslint:disable: max-classes-per-file
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { promisifyLoader } from './utils/Promisify';
import {
  Vector3,
  GridHelper,
  Matrix4,
  Quaternion,
  Vector4,
  Euler,
  MeshStandardMaterial,
  Object3D,
  MeshNormalMaterial,
} from 'three';
import { nextSubstepIndexes, previousSubstep } from './utils/project-utils';
import {
  getObjectByNodeId,
  createMaterialPreview,
  modelIdToNodeId,
  disposeObject,
  calculateSubstepObjectStates,
  updateObjectStatesFromSubstep,
  calculateTransformMatrix,
  setObjectWorldMatrix,
  setObjectEffects,
  flashEffectColor,
  setObjectsToStates,
  previousSubstepIndexes,
  objectMaxSize,
} from '../../buzzcommon/utils/BuzzArUtils';
import {
  getMaterialsFromObject3D,
  getSelectedArMaterials,
  prepareMaterialsFromOriginal,
  reduceMaterial,
  setArMaterialToObject,
} from '../../buzzcommon/utils/MaterialUtils';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { Wireframe } from 'three/examples/jsm/lines/Wireframe';
import { WireframeGeometry2 } from 'three/examples/jsm/lines/WireframeGeometry2';
import { isArray } from 'util';
import {
  TransformBasis,
  defaultAction,
  ActionTypes,
  PointerModes,
} from '../../types/default-objects';
import clone from 'clone';
import { green } from '@material-ui/core/colors';
import {
  ActionEffect,
  ActionTransform,
  AnimationObject,
  ArMaterial,
  ArModel,
  ArScenarioProject,
  changedActionParam,
  MetadataSet,
  ObjectState,
  ReducedMaterial,
  ReducedObject3D,
  RGBColor,
  SubstepAction,
  Tool,
} from '../../buzzcommon';

export class Object3DIdent extends THREE.Object3D {
  ID: number = 0;
}

export class GroupIdent extends THREE.Group {
  ID: number = 0;
}

export class MeshIdent extends THREE.Mesh {
  ID: number = 0;
}

export enum SelectedObjectType {
  None = 0,
  SelectionGroup,
  RotationAxisStart,
  RotationAxisEnd,
  TransformCenter,
}

export enum GroupType {
  Scene = 'scene',
  Group = 'group',
  Rotaton = 'rotation',
  Wrapper = 'wrapper',
  Selection = 'selection',
  Center = 'center',
}

export enum MaterialType {
  Original = 0,
  OriginalWireframe,
  HelperDefault,
  SelectedDefault,
  SelectedWireframe,
}

export interface SceneElement {
  id: number;
  nodeId: number;
  name: string;
  type: string;
  children: SceneElement[];
}

export class EditorEngine {
  threeRootElement?: HTMLDivElement;
  project?: ArScenarioProject;
  scene?: THREE.Scene;
  sceneStructure?: SceneElement;
  selectedObjectIds: number[] = new Array<number>();
  selectionGroup?: THREE.Group;
  animationObjects = new Array<AnimationObject>();
  selectedObjectType: SelectedObjectType = SelectedObjectType.SelectionGroup;
  draggedTool?: Tool;

  loadingTextMesh?: THREE.Object3D;
  showLoading = false;

  camera?: THREE.PerspectiveCamera;
  gridHelper?: THREE.GridHelper;
  axesHelper?: THREE.AxesHelper;
  skybox?: THREE.Mesh;

  lights: THREE.Light[] = [];
  orbitControls?: OrbitControls;
  transformControls?: TransformControls;
  renderer?: THREE.WebGLRenderer;
  requestID: number = 0;
  raycaster = new THREE.Raycaster();
  mouse = new THREE.Vector2();
  mouseDown = new THREE.Vector2();
  wireframeMode = false;
  pointerMode = PointerModes.select;
  multiselect = false;

  editingAction?: SubstepAction;
  currentStepId: number = 0;
  currentSubstepId: number = 0;
  currentActionId: number = 0;

  rotationAxis = new THREE.Group();
  transformCenter = new THREE.Group();

  rotationAxisAngle: number = 90;
  translationVector = new THREE.Group();

  helperSphereRadius = 1;

  selectedWireframeMaterial = new THREE.MeshStandardMaterial({
    roughness: 0,
    metalness: 0.1,
    fog: true,
    skinning: true,
    wireframe: true,
    color: '#FF0000',
  });
  selectedDefaultMaterial = new THREE.MeshStandardMaterial({
    roughness: 0,
    metalness: 0.1,
    fog: true,
    skinning: false,
    wireframe: false,
    color: '#FF0000',
    transparent: true,
    opacity: 0.7,
  });
  helperDefaultMaterial = new THREE.MeshStandardMaterial({
    roughness: 0,
    metalness: 0.1,
    fog: true,
    skinning: false,
    wireframe: false,
    color: '#0000FF',
    transparent: true,
    opacity: 0.3,
    depthTest: false,
  });

  init = (rootElement: HTMLDivElement, project: ArScenarioProject) => {
    if (rootElement != null) this.threeRootElement = rootElement;
    this.project = project;
  };

  setMultiselect = (enabled: boolean) => {
    this.multiselect = enabled;
  };

  getSceneRootObjects = () => {
    const objects: Object3D[] = [];
    if (this.scene && this.scene.children) {
      this.scene.children.forEach((object) => {
        if (
          object &&
          object.userData &&
          object.userData.groupType === GroupType.Group
        ) {
          objects.push(object);
        }
      });
    }

    return objects;
  };

  fitCameraToObjects = (objects: THREE.Object3D[], offset: number) => {
    if (this.camera === undefined || this.scene === undefined) {
      return;
    }

    const boundingBox = new THREE.Box3();
    objects.forEach((object) => boundingBox.expandByObject(object));

    const center = boundingBox.getCenter(new THREE.Vector3());
    const size = boundingBox.getSize(new THREE.Vector3());

    const startDistance = center.distanceTo(this.camera.position);
    // here we must check if the screen is horizontal or vertical, because camera.fov is
    // based on the vertical direction.
    const endDistance =
      this.camera.aspect > 1
        ? (size.y / 2 + offset) / Math.abs(Math.tan(this.camera.fov / 2))
        : (size.y / 2 + offset) /
          Math.abs(Math.tan(this.camera.fov / 2)) /
          this.camera.aspect;

    this.camera.position.set(
      (this.camera.position.x * endDistance) / startDistance,
      (this.camera.position.y * endDistance) / startDistance,
      (this.camera.position.z * endDistance) / startDistance
    );
    this.camera.lookAt(center);

    if (this.gridHelper) {
      disposeObject(this.gridHelper);
    }

    this.gridHelper = new THREE.GridHelper(
      Math.max(size.x, size.y, size.z) * 2,
      100
    );
    // this.gridHelper.position.set(center.x, boundingBox.min.y, center.z);
    this.gridHelper.position.set(0, 0, 0);
    this.gridHelper.setColors(
      new THREE.Color(0xff0000),
      new THREE.Color(0xffffff)
    );
    this.scene.add(this.gridHelper);

    if (this.axesHelper) {
      disposeObject(this.axesHelper);
    }

    this.axesHelper = new THREE.AxesHelper(Math.max(size.x, size.y, size.z));
    this.scene.add(this.axesHelper);
  };

  fitLightsToObjects = (objects: THREE.Object3D[]) => {
    const boundingBox = new THREE.Box3();
    objects.forEach((object) => boundingBox.expandByObject(object));

    const size = boundingBox.getSize(new THREE.Vector3());
    const maxDistance = Math.max(size.x, size.y, size.z);

    this.lights[0].position.set(0, 1.5 * maxDistance, 0);
    this.lights[1].position.set(maxDistance, maxDistance, maxDistance);
    this.lights[2].position.set(-maxDistance, -1.5 * maxDistance, -maxDistance);
  };

  sceneSetup = () => {
    // get container dimensions and use them for scene sizing
    if (!this.threeRootElement) {
      return;
    }

    const width = this.threeRootElement.clientWidth;
    const height = this.threeRootElement.clientHeight;

    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(
      new THREE.Color(this.threeRootElement.style.backgroundColor)
    );
    this.scene.name = 'Scenario 1';
    this.scene.userData.groupType = GroupType.Scene;

    this.camera = new THREE.PerspectiveCamera(
      75, // fov = field of view
      width / height, // aspect ratio
      0.1, // near plane
      4000000 // far plane
    );

    this.camera.position.z = 100;

    this.renderer = new THREE.WebGLRenderer({ antialias: true });

    this.renderer.setSize(width, height);
    this.threeRootElement.appendChild(this.renderer.domElement); // mount using React ref

    this.orbitControls = new OrbitControls(
      this.camera,
      this.renderer.domElement
    );
    // const orbit = this.orbitControls;

    this.transformControls = new TransformControls(
      this.camera,
      this.renderer.domElement
    );
    this.transformControls.setSize(2.2);
    this.transformControls.addEventListener('change', (event) => {
      if (
        this.renderer !== undefined &&
        this.scene !== undefined &&
        this.camera !== undefined
      ) {
        // this.renderer.render(this.scene, this.camera);
        if (engine.editingAction) {
          engine.updateRotationAxisLine();
        }
      }
    });

    const engine = this;

    this.transformControls.addEventListener(
      'dragging-changed',
      function (event) {
        if (engine.orbitControls) {
          engine.orbitControls.enabled = !event.value;
        }
        if (engine.editingAction && !event.value) {
          engine.onEditActionChanged();
          // engine.startEditingActionHelperAnimation();
        }
      }
    );

    this.lights[0] = new THREE.PointLight(0xffffff, 2, 0);
    this.lights[1] = new THREE.PointLight(0xffffff, 2, 0);
    this.lights[2] = new THREE.PointLight(0xffffff, 2, 0);

    this.lights[0].position.set(0, 45, 0);
    this.lights[1].position.set(35, 35, 35);
    this.lights[2].position.set(-35, -45, -35);

    this.scene.add(this.lights[0]);
    this.scene.add(this.lights[1]);
    this.scene.add(this.lights[2]);

    this.orbitControls.update();
    this.scene.add(this.transformControls);
    this.addLoadingText();
    this.renderer.render(this.scene, this.camera);

    this.selectionGroup = new THREE.Group();
    this.initSelectionGroup();
    this.scene.add(this.selectionGroup);

    this.createRotationAxis();
    this.createTransformCenter();

    this.addSkybox();

    this.addBackground();

    return this.scene;
  };

  addBackground = () => {
    if (this.scene) {
      const loader = new THREE.TextureLoader();
      loader.setCrossOrigin('');

      const bgTexture = loader.load('textures/background.jpg');
      this.scene.background = bgTexture;
      bgTexture.wrapS = THREE.MirroredRepeatWrapping;
      bgTexture.wrapT = THREE.MirroredRepeatWrapping;
    }
  };

  addSkybox = () => {
    function createPathStrings(filename: string) {
      const basePath = `https://raw.githubusercontent.com/codypearce/some-skyboxes/master/skyboxes/${filename}/`;
      const baseFilename = basePath + filename;
      const fileType = filename === 'purplenebula' ? '.png' : '.jpg';
      const sides = ['ft', 'bk', 'up', 'dn', 'rt', 'lf'];
      const pathStings = sides.map((side) => {
        return baseFilename + '_' + side + fileType;
      });

      return pathStings;
    }

    function createMaterialArray(filename: string) {
      const skyboxImagepaths = createPathStrings(filename);
      const materialArray = skyboxImagepaths.map((image) => {
        const texture = new THREE.TextureLoader().load(image);

        return new THREE.MeshBasicMaterial({
          map: texture,
          side: THREE.BackSide,
        });
      });
      return materialArray;
    }

    if (this.scene) {
      // const materialArray = createMaterialArray('purplenebula');
      // const materialArray = createMaterialArray('afterrain');
      // const materialArray = createMaterialArray('aqua9');
      const materialArray = createMaterialArray('flame');

      const skyboxGeo = new THREE.BoxGeometry(10000, 10000, 10000);
      this.skybox = new THREE.Mesh(skyboxGeo, materialArray);
      this.skybox.visible = false;

      this.scene.add(this.skybox);
    }
  };

  loadProgress = (event: ProgressEvent<EventTarget>) => {};

  resizeCanvasToDisplaySize = () => {
    const canvas = this.renderer?.domElement;
    if (
      canvas !== undefined &&
      this.camera !== undefined &&
      this.renderer !== undefined &&
      this.threeRootElement !== undefined
    ) {
      // look up the size the canvas is being displayed
      const width = this.threeRootElement.clientWidth;
      const height = this.threeRootElement.clientHeight;

      // adjust displayBuffer size to match
      if (canvas.width !== width || canvas.height !== height) {
        // you must pass false here or three.js sadly fights the browser
        canvas.width = width;
        canvas.height = height;

        this.camera.aspect = width / height;
        this.camera.updateProjectionMatrix();

        this.renderer.setSize(width, height, true);
      }
    }
  };

  addLoadingText = () => {
    const loader = new THREE.FontLoader();
    const scene = this.scene;
    const engine = this;
    this.showLoading = true;

    loader.load(
      'https://threejs.org/examples/fonts/helvetiker_regular.typeface.json',
      function (font) {
        const material = new THREE.MeshPhongMaterial({
          color: 0x444444,
        });
        const textGeom = new THREE.TextGeometry('Loading...', {
          font,
          size: 10,
          height: 1,
          bevelSize: 1,
        });

        engine.loadingTextMesh = new THREE.Mesh(textGeom, material);

        textGeom.computeBoundingBox();
        if (textGeom !== null && textGeom.boundingBox !== null) {
          const textWidth =
            textGeom.boundingBox.max.x - textGeom.boundingBox.min.x;
          engine.loadingTextMesh.position.set(-50, -5, -50);
          // engine.loadingTextMesh.scale.set(5, 5, 5);
          // engine.loadingTextMesh.rotation.set(0.5, 0.5, 0.5);
          scene?.add(engine.loadingTextMesh);
        }
      },
      function (event) {
        console.log(event);
      },
      function (event) {
        console.log(event);
      }
    );
  };

  removeLoadingText = () => {
    this.showLoading = false;
  };

  enableWireframeMode = () => {
    if (this.scene != null) {
      this.wireframeMode = true;
      const selectedIds = this.selectedObjectIds;
      const selectedWireframeMaterial = this.selectedWireframeMaterial;

      this.scene.traverse((child: THREE.Object3D) => {
        if (
          child instanceof THREE.Mesh &&
          child.type === 'Mesh' &&
          (child as MeshIdent).ID !== undefined
        ) {
          child.material = selectedIds.includes(child.id)
            ? selectedWireframeMaterial
            : child.userData.originalWireframeMaterial;
        }
      });
    }
  };

  disableWireframeMode = () => {
    if (this.scene != null) {
      this.wireframeMode = false;
      const selectedIds = this.selectedObjectIds;
      const selectedDefaultMaterial = this.selectedDefaultMaterial;

      this.scene.traverse(function (child: THREE.Object3D) {
        if (
          child instanceof THREE.Mesh &&
          child.type === 'Mesh' &&
          (child as MeshIdent).ID !== undefined
        ) {
          child.material = selectedIds.includes(child.id)
            ? selectedDefaultMaterial
            : child.userData.originalMaterial;
        }
      });
    }
  };

  enableSkybox = (enable: boolean) => {
    if (this.skybox) {
      this.skybox.visible = enable;
      this.showTransformControls('setCenter');
      // TODO: For test, then Remove
      // const mats = this.getProjectSelectedArMaterials();
      // console.log(mats);

      // if (this.scene) {
      //   const mat:ArMaterial = {
      //     metalness: 0.7,
      //     color: {r: 0, g: 1, b: 0},
      //     uuid: "",
      //     name: "test",
      //     opacity: 1,
      //     transparent: false,
      //   }
      //   const img = createMaterialPreview(mat);
      // }
      // TODO: For test, then Remove
    } else {
      this.showTransformControls('finishCenter');
    }
  };

  enableGridHelper = (enable: boolean) => {
    if (this.gridHelper !== undefined) {
      this.gridHelper.visible = enable;
    }
    // TODO: START REMOVE! Currently it is for the test.
    // this.restoreOriginalPosition();
    // if (this.project?.steps[0].substeps[0].actions[0]) {
    //  this.editAction(0, 0, this.project?.steps[0].substeps[0].actions[0]);
    // }
    this.startEditingActionHelperAnimation();
    // TODO: END REMOVE! Currently it is for the test.
  };

  enableAxisHelper = (enable: boolean) => {
    if (this.axesHelper !== undefined) {
      this.axesHelper.visible = enable;
    }
  };

  fbxToObject3d = async (path: string) => {
    const FBXPromiseLoader = promisifyLoader(
      new FBXLoader(),
      this.loadProgress
    );
    return FBXPromiseLoader.load(path);
  };

  loadModels = async (
    url: (uniqueId: string) => string,
    arModels: ArModel[],
    onModelsLoaded: (object: THREE.Scene, fbxModels: THREE.Object3D[]) => void
  ) => {
    if (!this.scene) {
      return;
    }

    const FBXPromiseLoader = promisifyLoader(
      new FBXLoader(),
      this.loadProgress
    );
    const promises: Promise<THREE.Object3D>[] = [];

    const wrapObject = this.wrapObject;

    const fbxModels: THREE.Object3D[] = [];
    for (const arModel of arModels) {
      try {
        const fbxModel: THREE.Object3D = await FBXPromiseLoader.load(
          url(arModel.lowLodFile.uniqueId)
        );
        fbxModels.push(fbxModel);
        console.log('MODEL', fbxModel);
        if (this.scene != null) {
          this.prepareFbxModel(
            fbxModel,
            arModel.name,
            modelIdToNodeId(arModel.id)
          );
          console.log('WRAPPED MODEL BEFORE ADD', fbxModel);
          this.scene.add(fbxModel);
          console.log('WRAPPED MODEL AFTER ADD', fbxModel);
        }
      } catch (error) {
        // TODO: handle if some model file cannot be loaded
      }
    }
    if (!fbxModels?.length) {
      // TODO:handle when models cannot be loaded
      return;
    }
    this.buildSceneStructure();
    console.log(this.sceneStructure);

    this.removeLoadingText();

    if (this.camera) {
      const sceneObjects = this.getSceneRootObjects();

      this.fitCameraToObjects(sceneObjects, 1);
      this.fitLightsToObjects(sceneObjects);
      this.camera.updateProjectionMatrix();
      if (this.scene && this.project) {
        updateObjectStatesFromSubstep(this.scene, this.project, 0, 0);
        onModelsLoaded(this.scene, fbxModels);
      }
    }

    this.rescaleHelpers();

    return this.scene;
  };

  deleteModelFromScene = (modelId: number) => {
    const nodeId = modelIdToNodeId(modelId);
    if (this.scene) {
      const object = getObjectByNodeId(this.scene, nodeId);
      if (object && object.parent) {
        const parent = object.parent;
        parent.remove(object);
        disposeObject(object);
      }
    }

    this.buildSceneStructure();

    return this.scene;
  };

  exportModel = (modelId: number): ReducedObject3D | null => {
    let reducedModel: ReducedObject3D | null = null;
    this.scene?.children.forEach((object: THREE.Object3D) => {
      if ((object as GroupIdent).ID === modelIdToNodeId(modelId)) {
        reducedModel = this.reduceObject3D(object);
      }
    });

    return reducedModel;
  };

  splitNodeByMaterial = (nodeId: number): THREE.Object3D => {
    return {} as THREE.Object3D;
  };

  reduceObject3D = (object: THREE.Object3D): ReducedObject3D | null => {
    let reducedObject3D: ReducedObject3D | null = null;
    if (object) {
      const reducedMaterials = new Array<ReducedMaterial>();
      if (object instanceof THREE.Mesh) {
        if (object.material && isArray(object.material)) {
          object.material.forEach((material) => {
            const reducedMaterial = reduceMaterial(material);
            if (reducedMaterial) {
              reducedMaterials.push(reducedMaterial);
            }
          });
        }
      }

      const reducedChildren = new Array<ReducedObject3D>();
      if (object.children && isArray(object.children)) {
        object.children.forEach((child) => {
          const reducedChild = this.reduceObject3D(child);
          if (reducedChild) {
            reducedChildren.push(reducedChild);
          }
        });
      }

      reducedObject3D = {
        nodeId: (object as Object3DIdent).ID,
        name: object.name,
        scale: {
          x: object.scale.x,
          y: object.scale.y,
          z: object.scale.z,
        },
        position: {
          x: object.position.x,
          y: object.position.y,
          z: object.position.z,
        },
        rotation: {
          x: object.quaternion.x,
          y: object.quaternion.y,
          z: object.quaternion.z,
          w: object.quaternion.w,
        },
        type: object.type,
        uuid: object.uuid,
        visible: object.visible,
        materials: reducedMaterials,
        children: reducedChildren,
      };
    }
    return reducedObject3D;
  };

  onCanvasMouseDown = (event: React.MouseEvent) => {
    if (
      !this.renderer ||
      !this.scene ||
      !this.camera ||
      !this.orbitControls ||
      !this.threeRootElement
    ) {
      return;
    }
    const rootElement = this.threeRootElement;
    const width = rootElement.clientWidth;
    const height = rootElement.clientHeight;
    const offset = this.threeRootElement.getClientRects()[0];
    this.mouseDown.x = ((event.clientX - offset.left) / width) * 2 - 1;
    this.mouseDown.y = -((event.clientY - offset.top) / height) * 2 + 1;
  };

  prepareFbxModel = (
    fbxModel: THREE.Object3D,
    name: string,
    modelId: number
  ) => {
    // Apply model name from project instead of original model name from file.
    fbxModel.name = name;

    // Save original materials.
    fbxModel.traverse((child: THREE.Object3D) => {
      this.prepareObjectInitialParameters(child);
    });

    if (fbxModel instanceof THREE.Group) {
      if ((fbxModel as GroupIdent).ID === undefined) {
        // If no ID then assign ID related to file ID.
        (fbxModel as GroupIdent).ID = modelId;
      }
    }
    if (fbxModel.name === undefined || fbxModel.name.length === 0) {
      fbxModel.name = name;
    }

    fbxModel.updateMatrix();
    fbxModel.updateMatrixWorld();
  };

  prepareObjectInitialParameters = (object: THREE.Object3D) => {
    if (object) {
      // Correct object rotation according to userData.transformData stored by FbxLoader.
      if (
        object.userData !== undefined &&
        object.userData.transformData !== undefined &&
        object.userData.transformData.rotation !== undefined
      ) {
        object.setRotationFromEuler(
          new THREE.Euler(
            (object.userData.transformData.rotation[0] / 180) * Math.PI,
            (object.userData.transformData.rotation[1] / 180) * Math.PI,
            (object.userData.transformData.rotation[2] / 180) * Math.PI,
            object.userData.transformData.eulerOrder
          )
        );
      }

      // Rename object with NodeId.
      let nodeIdString = '';
      if ((object as GroupIdent).ID) {
        nodeIdString = ' ' + (object as GroupIdent).ID;
      }
      if (object.name === undefined || object.name.length === 0) {
        object.name = 'Node';
      }
      object.name += nodeIdString;

      // Store original material and prepare wireframe material.
      if (object instanceof THREE.Mesh && object.type === 'Mesh') {
        object.userData.originalMaterial = object.material;
        object.userData.meshType = 'object';

        let wireframeMaterial;
        let transparentMaterial;
        let standardMaterial;

        if (isArray(object.material)) {
          wireframeMaterial = new Array<THREE.Material>();
          transparentMaterial = new Array<THREE.Material>();
          standardMaterial = new Array<THREE.Material>();

          object.material.forEach((material) => {
            const newMaterials = prepareMaterialsFromOriginal(material);
            wireframeMaterial.push(newMaterials.wireframe);
            transparentMaterial.push(newMaterials.transparent);
            standardMaterial.push(newMaterials.standard);
          });
        } else {
          const newMaterials = prepareMaterialsFromOriginal(object.material);
          wireframeMaterial = newMaterials.wireframe;
          transparentMaterial = newMaterials.transparent;
          standardMaterial = newMaterials.standard;
        }

        object.userData.originalWireframeMaterial = wireframeMaterial;
        object.userData.originalTransparentMaterial = transparentMaterial;
        object.userData.originalWireframeMaterial.needsUpdate = true;

        // Set prepared standard material instead original.
        if (standardMaterial) {
          object.material = standardMaterial;
          object.userData.originalMaterial = standardMaterial;
        }

        object.updateMatrix();
        object.updateMatrixWorld();
      } else {
        object.userData.groupType = GroupType.Group;
      }

      // Store original world matrix.
      if (object.userData !== undefined) {
        object.userData.originalWorldMatrix = object.matrixWorld.clone();
      }

      // Store original parent.
      if (object.parent) {
        object.userData.wrapper = object.parent;
      } else {
        object.userData.wrapper = this.scene;
      }
    }
  };

  wrapObject = (object: THREE.Object3D) => {
    // Wrapper group will have identity matrix to keep same object coordinates.
    const group = new THREE.Group();
    group.userData.groupType = GroupType.Wrapper;
    group.userData.childMeshId = object.id;
    group.userData.childMeshNodeId = (object as Object3DIdent).ID;
    group.name = object.name;

    object.parent?.add(group);
    object.parent?.remove(object);
    group.add(object);
    object.userData.wrapper = group;
    object.updateMatrix();
    object.updateMatrixWorld();
  };

  buildSceneStructure = () => {
    function getRandomInt(min: number, max: number) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min; // Including max, excluding min.
    }

    const buildSubstructure = (parent: THREE.Object3D) => {
      if (
        (parent?.type === 'Mesh' ||
          parent?.type === 'Group' ||
          parent?.type === 'Scene') &&
        parent.userData.groupType !== GroupType.Selection &&
        parent.userData.groupType !== GroupType.Rotaton
      ) {
        // Scan only groups and scene. Don't touch meshes, because they must have own wrappers.
        if (
          parent?.children.length &&
          parent.userData.groupType !== GroupType.Wrapper
        ) {
          const substructure: SceneElement = {
            id: parent?.id,
            name: parent.name,
            type: parent.type,
            children: [],
            nodeId: (parent as Object3DIdent).ID,
          };

          parent.children.forEach((item) => {
            const child = buildSubstructure(item);
            if (child) {
              substructure.children.push(child);
            }
          });
          return substructure;
        } else if (parent.type === 'Mesh') {
          const substructure: SceneElement = {
            id: parent.id,
            name: parent.name,
            type: 'Mesh',
            children: [],
            nodeId: (parent as MeshIdent).ID,
          };
          return substructure;
        } else {
          return undefined;
        }
      }
    };
    if (this.scene) {
      this.sceneStructure = buildSubstructure(this.scene);
      if (this.sceneStructure) {
        this.sceneStructure.nodeId = 1;
      }
    }
  };

  getAllParents = (object: THREE.Object3D, collectedParentIds: number[]) => {
    if (object !== null) {
      let parent = object?.parent;
      if (object.userData.wrapper !== undefined) {
        // Use wrapper for wrapped objects instead of current parent.
        parent = object.userData.wrapper;
      }

      if (parent !== null) {
        // Add only unique items.
        if (collectedParentIds.indexOf(parent.id) === -1) {
          collectedParentIds.push(parent.id);
        }
        collectedParentIds = this.getAllParents(parent, collectedParentIds);
      }
    }
    return collectedParentIds;
  };

  getAllParentsOfIds = (ids: number[]) => {
    let parentIds: number[] = [];
    ids.forEach((id) => {
      const object = this.scene?.getObjectById(id);
      if (object !== undefined) {
        parentIds = this.getAllParents(object, parentIds);
      }
    });
    return parentIds;
  };

  onCanvasClick = (event: React.MouseEvent) => {
    if (
      !this.renderer ||
      !this.scene ||
      !this.camera ||
      !this.orbitControls ||
      !this.threeRootElement
    ) {
      return;
    }

    if (
      this.pointerMode === PointerModes.select ||
      this.pointerMode === PointerModes.move ||
      this.pointerMode === PointerModes.rotate
    ) {
      // Only in these modes handle object selection on the scene.

      const rootElement = this.threeRootElement;
      const width = rootElement.clientWidth;
      const height = rootElement.clientHeight;

      const offset = this.threeRootElement.getClientRects()[0];

      this.mouse.x = ((event.clientX - offset.left) / width) * 2 - 1;
      this.mouse.y = -((event.clientY - offset.top) / height) * 2 + 1;

      if (
        this.mouse.x === this.mouseDown.x &&
        this.mouse.y === this.mouseDown.y
      ) {
        // update the picking ray with the camera and mouse position
        this.raycaster.setFromCamera(this.mouse, this.camera);

        // calculate objects intersecting the picking ray
        const visibleMeshes = new Array<THREE.Object3D>();

        this.scene.traverseVisible((object: THREE.Object3D) => {
          if (object instanceof THREE.Mesh && object.type === 'Mesh') {
            visibleMeshes.push(object);
          }
        });

        const intersects = this.raycaster.intersectObjects(visibleMeshes);
        let id = 0;
        let helperMesh: THREE.Mesh | undefined;

        for (let i = 0; i < intersects.length; i++) {
          const mesh: THREE.Object3D = intersects[i].object;
          if (mesh instanceof THREE.Mesh && mesh.type === 'Mesh') {
            if (
              mesh.userData.meshType !== 'object' &&
              helperMesh === undefined
            ) {
              helperMesh = mesh;
            } else if (id === 0) {
              id = mesh.id;
            }
            // break;
          }
        }

        const selectedMesh = this.scene.getObjectById(id);

        if (helperMesh) {
          switch (helperMesh?.userData.meshType) {
            case 'rotationAxisStart':
              this.selectRotationAxisStart();
              break;
            case 'rotationAxisEnd':
              this.selectRotationAxisEnd();
              break;
            case 'transformCenter':
              this.selectTransformCenter();
              break;
          }
        } else if (selectedMesh !== undefined) {
          if (selectedMesh.userData.meshType === 'object') {
            this.handleObjectClick(selectedMesh);
            const parentIds = this.getAllParentsOfIds(this.selectedObjectIds);

            const selectEvent = new CustomEvent('selectObject3D', {
              bubbles: true,
              cancelable: true,
              detail: {
                id,
                selectedObjectIds: this.selectedObjectIds,
                parentIds,
              },
            });
            rootElement.dispatchEvent(selectEvent);
          }
        }
      }
    }
  };

  handleObjectClick = (object: THREE.Object3D) => {
    const id = object.id;
    let newSelection = new Array<number>();

    if (this.multiselect) {
      // Copy all ids except new id.
      this.selectedObjectIds.forEach((value) => {
        if (value !== id) {
          newSelection.push(value);
        }
      });

      if (!this.selectedObjectIds.includes(id)) {
        // Include new id if was not included before.
        newSelection.push(id);
      }
    } else {
      newSelection.push(id);
    }
    newSelection = newSelection.sort((a, b) => a - b);
    if (this.editingAction) {
      this.editingAction.nodeIds = this.idsToNodeIds(newSelection);
      this.updateActionFromUi(
        this.currentStepId,
        this.currentSubstepId,
        this.editingAction,
        this.currentActionId,
        'selection'
      );
      this.sendActionChangedEvent();
    } else {
      // Global mode.
      this.updateSelectionGroup(newSelection);
    }
  };

  sendPointerModeEvent = (mode: string) => {
    const selectEvent = new CustomEvent('setPointerMode', {
      bubbles: true,
      cancelable: true,
      detail: {
        mode,
      },
    });
    this.threeRootElement?.dispatchEvent(selectEvent);
  };

  setPointerMode = (mode: PointerModes) => {
    this.pointerMode = mode;

    if (this.transformControls === undefined) {
      return;
    }

    if (this.selectedObjectIds.length === 0) {
      return;
    }

    const object = this.scene?.getObjectById(this.selectedObjectIds[0]);
    if (object === undefined) {
      return;
    }

    this.transformControls.detach();
    this.scene?.remove(this.transformControls);

    if (mode === PointerModes.move || mode === PointerModes.rotate) {
      switch (this.selectedObjectType) {
        case SelectedObjectType.SelectionGroup:
          // Check if groupType already exists. Then use it. Otherwise create new.
          let group = new THREE.Group();
          if (
            (object.parent?.userData.groupType !== undefined &&
              object.parent?.userData.groupType === GroupType.Wrapper) ||
            object.parent?.userData.groupType === GroupType.Selection
          ) {
            group = object.parent as THREE.Group;
          }
          this.transformControls?.attach(group);
          this.transformControls?.setMode(
            mode === PointerModes.move ? 'translate' : 'rotate'
          );
          break;
        case SelectedObjectType.RotationAxisStart:
          this.transformControls.attach(this.rotationAxis.children[0]);
          this.transformControls?.setMode('translate');
          break;
        case SelectedObjectType.RotationAxisEnd:
          this.transformControls.attach(this.rotationAxis.children[1]);
          this.transformControls?.setMode('translate');
          break;
        case SelectedObjectType.TransformCenter:
          this.transformControls.attach(this.transformCenter);
          this.transformControls?.setMode('translate');
          break;
      }

      this.scene?.add(this.transformControls);
    }
  };

  restoreOriginalPosition = () => {
    if (!this.scene) {
      return;
    }
    this.returnSelectedObjectsToInitialWrappers();
    this.selectedObjectIds = [];

    const objectStates = this.collectInitialObjectStates();
    setObjectsToStates(this.scene, objectStates);
  };

  calculateObjectsStatesForAnimationObject = (
    animationObject: AnimationObject
  ) => {
    const objectStates = new Array<ObjectState>();
    animationObject.progress =
      (Date.now() - animationObject.startTime) /
      (animationObject.duration * 1000);
    if (animationObject.progress < 0) {
      animationObject.progress = 0;
    }
    if (animationObject.progress > 1) {
      animationObject.progress = 1;
    }
    if (animationObject.progress > 0) {
      // Take parent previous state.
      const parentPreviousState = animationObject.initialObjectStates.find(
        (state) => state.nodeId === animationObject.transform.anchorNodeId
      );
      const parentWorldMatrix = parentPreviousState
        ? parentPreviousState.worldMatrix
        : new THREE.Matrix4().identity();

      // TODO: don't calculate for inactive cases.
      const animationMatrix = calculateTransformMatrix(
        animationObject.transform,
        parentWorldMatrix,
        animationObject.progress
      );
      animationObject.initialObjectStates.forEach((initialState) => {
        // const newWorldMatrix = new THREE.Matrix4().multiplyMatrices(initialState.worldMatrix, animationMatrix);
        const newWorldMatrix = new THREE.Matrix4().multiplyMatrices(
          animationMatrix,
          initialState.worldMatrix
        );

        let newOpacity = 1;
        // Process effects.
        if (animationObject.effects && animationObject.effects.length > 0) {
          animationObject.effects.forEach((effect) => {
            if (effect.type === 'show') {
              newOpacity = animationObject.progress;
            }
            if (effect.type === 'hide') {
              newOpacity = 1 - animationObject.progress;
            }
            if (effect.type === 'flash') {
              newOpacity =
                (Math.cos(animationObject.progress * 3 * 2 * Math.PI) + 1) / 2;
            }
          });
        }

        const currentState: ObjectState = {
          nodeId: initialState.nodeId,
          selected: initialState.selected,
          opacity: newOpacity,
          visible: initialState.visible,
          worldMatrix: newWorldMatrix,
          worldMatrixElements: [],
          color: initialState.color,
        };
        objectStates.push(currentState);
      });
      animationObject.currentObjectStates = objectStates;
    }
  };

  applyAnimationObject = (animationObject: AnimationObject) => {
    animationObject.currentObjectStates.forEach((objectState) => {
      if (this.scene) {
        const object = getObjectByNodeId(this.scene, objectState.nodeId);
        if (object !== undefined) {
          const newWorldMatrix = objectState.worldMatrix.clone();
          setObjectWorldMatrix(object, newWorldMatrix);

          let flash = false;
          if (animationObject.effects) {
            animationObject.effects.forEach((effect) => {
              if (effect.type === 'flash') {
                flash = true;
              }
            });
          }

          if (animationObject.bundleInfo.type !== 'helper') {
            setObjectEffects(object, objectState.opacity, flash);
          }
        }
      }
    });
  };

  performAnimation = () => {
    this.animationObjects.forEach((animationObject) => {
      if (!animationObject.deleted && animationObject.active) {
        this.calculateObjectsStatesForAnimationObject(animationObject);
        this.applyAnimationObject(animationObject);
      }
      if (animationObject.progress === 1 && animationObject.needRepeat === 0) {
        animationObject.deleted = true;
      }
    });

    // Remove deleted elements.
    const deletedObjects = this.animationObjects.filter(
      (ao) => ao.deleted === true
    );
    deletedObjects.forEach((object) => {
      if (object.bundleInfo.type === 'helper') {
        // Remove 3D object for helper animation.
        object.initialObjectStates.forEach((state) => {
          if (this.scene) {
            const item = getObjectByNodeId(this.scene, state.nodeId);
            if (item && item.parent) {
              item.parent.remove(item);
            }
          }
        });
      } else if (
        !this.animationObjects.find(
          (ao) =>
            ao.bundleInfo.id === object.bundleInfo.id &&
            ao.bundleInfo.type === ao.bundleInfo.type &&
            ao.deleted === false
        )
      ) {
        // It was the last object from the bundle.
        const finishEvent = new CustomEvent('animationBundleFinished', {
          bubbles: true,
          cancelable: true,
          detail: { animationBundleInfo: object.bundleInfo },
        });
        this.threeRootElement?.dispatchEvent(finishEvent);
      }
    });

    this.animationObjects = this.animationObjects.filter(
      (ao) => ao.deleted === false
    );
  };

  startSubstepAnimation = (
    stepId: number,
    substepId: number,
    bundleId: number
  ) => {
    if (this.project === undefined || this.showLoading) {
      return;
    }

    this.animationObjects.forEach((animationObject) => {
      animationObject.deleted = true;
    });

    if (stepId === 0 && substepId === 0) {
      // Initial substep. No need animation, just set correct states and go to the next step.
      const indexes = nextSubstepIndexes(this.project, 0, 0);
      if (indexes.stepId >= 0) {
        this.onSubstepSelected(0, 0);
        this.startSubstepAnimation(indexes.stepId, indexes.substepId, bundleId);
        return;
      }
    }

    const prevStates = this.getPreviousStates(stepId, substepId);
    if (!prevStates) {
      return;
    }

    const substep = this.project?.steps[stepId].substeps[substepId];

    const startTime = Date.now();

    if (substep && substep.actions) {
      substep.actions.forEach((action) => {
        const animationObject: AnimationObject = {
          needRepeat: 0,
          startTime,
          initialObjectStates: new Array<ObjectState>(),
          currentObjectStates: new Array<ObjectState>(),
          active: true,
          deleted: false,
          duration: substep.duration,
          progress: 0,
          transform: action.transform,
          effects: action.effects,
          bundleInfo: {
            id: bundleId,
            stepId,
            substepId,
            type: 'substep',
          },
        };
        action.nodeIds.forEach((nodeId) => {
          const state = prevStates.find((state) => state.nodeId === nodeId);
          if (state) {
            const initialState = {
              selected: state.selected,
              nodeId,
              opacity: state.opacity,
              visible: state.visible,
              worldMatrixElements: [],
              worldMatrix: state.worldMatrix.clone(),
              color: state.color,
            };
            animationObject.initialObjectStates.push(initialState);
          }
        });
        this.animationObjects.push(animationObject);
      });
    }
  };

  startEditingActionHelperAnimation = () => {
    if (!this.project || !this.scene || !this.editingAction) {
      return;
    }

    this.animationObjects.forEach((animationObject) => {
      animationObject.deleted = true;
    });

    const substep =
      this.project?.steps[this.currentStepId].substeps[this.currentSubstepId];
    const prevStates = this.getPreviousStates(
      this.currentStepId,
      this.currentSubstepId
    );
    if (!prevStates || !substep) {
      return;
    }

    const startTime = Date.now();
    // const prevState = prevStates.find(state => state.nodeId == selectedNodeId);
    // if (prevState) {
    // actionMatrix = new THREE.Matrix4().multiplyMatrices(selectedObject.matrixWorld, new THREE.Matrix4().getInverse(prevState.worldMatrix));
    // }

    const animationObject: AnimationObject = {
      needRepeat: 0,
      startTime,
      initialObjectStates: new Array<ObjectState>(),
      currentObjectStates: new Array<ObjectState>(),
      effects: new Array<ActionEffect>(),
      active: true,
      deleted: false,
      duration: 1,
      progress: 0,
      transform: this.editingAction.transform,
      bundleInfo: {
        id: startTime,
        stepId: this.currentStepId,
        substepId: this.currentSubstepId,
        type: 'helper',
      },
    };

    const createHelperObject = (source: THREE.Object3D | undefined) => {
      if (!source || (source.type !== 'Group' && source.type !== 'Mesh')) {
        return undefined;
      }
      const helperObject =
        source.type === 'Group' ? new THREE.Group() : new THREE.Mesh();

      helperObject.type = source.type;

      if (helperObject instanceof THREE.Mesh && source instanceof THREE.Mesh) {
        helperObject.geometry = source.geometry;
        helperObject.material = this.helperDefaultMaterial;
        helperObject.userData.originalMaterial = helperObject.material;
        helperObject.userData.originalTransparentMaterial =
          helperObject.material.clone();
        (helperObject as MeshIdent).ID = -(source as MeshIdent).ID;
      } else {
        (helperObject as GroupIdent).ID = -(source as GroupIdent).ID;
      }
      if (source.children.length > 0) {
        source.children.forEach((child) => {
          const helperChild = createHelperObject(child);
          if (helperChild) {
            helperObject.add(helperChild);
          }
        });
      }

      return helperObject;
    };

    this.editingAction.nodeIds.forEach((nodeId) => {
      if (this.scene) {
        const originalObject = getObjectByNodeId(this.scene, nodeId);
        if (originalObject) {
          const helperObject = createHelperObject(originalObject);
          if (helperObject) {
            // originalObject.parent?.add(helperObject);
            this.scene?.add(helperObject);
            const state = prevStates.find((state) => state.nodeId === nodeId);

            if (state) {
              const initialState = {
                selected: state.selected,
                nodeId: -nodeId,
                opacity: state.opacity,
                visible: state.visible,
                worldMatrixElements: [],
                worldMatrix: state.worldMatrix.clone(),
                color: state.color,
              };
              animationObject.initialObjectStates.push(initialState);
            }
          }
        }
      }
    });
    this.animationObjects.push(animationObject);
  };

  onSubstepSelected = (stepId: number, substepId: number) => {
    if (!this.scene || this.showLoading) {
      return;
    }

    let objectStates: ObjectState[] = [];
    if (
      stepId >= 0 &&
      substepId >= 0 &&
      this.project?.steps[stepId].substeps[substepId].objectsState
    ) {
      objectStates =
        this.project?.steps[stepId].substeps[substepId].objectsState;
    } else {
      objectStates = this.collectInitialObjectStates();
    }
    setObjectsToStates(this.scene, objectStates);

    this.sendPointerModeEvent('select');
  };

  getObjectColor = (object: THREE.Object3D) => {
    let color: RGBColor = {
      r: 0,
      g: 0,
      b: 0,
    };

    if (object instanceof THREE.Mesh) {
      const material =
        Array.isArray(object.material) && object.material.length > 0
          ? object.material[0]
          : object.material;

      if (
        material instanceof THREE.MeshStandardMaterial ||
        material instanceof THREE.MeshBasicMaterial ||
        material instanceof THREE.MeshPhongMaterial ||
        material instanceof THREE.MeshLambertMaterial ||
        material instanceof THREE.MeshToonMaterial ||
        material instanceof THREE.MeshMatcapMaterial ||
        material instanceof THREE.MeshPhysicalMaterial
      ) {
        color = {
          r: material.color.r,
          g: material.color.g,
          b: material.color.b,
        };
      }
    }

    return color;
  };

  collectInitialObjectStates = () => {
    const initialStates = new Array<ObjectState>();
    if (this.scene !== undefined) {
      this.scene.traverse((child: THREE.Object3D) => {
        // if (child instanceof THREE.Mesh && child.type === 'Mesh' && (child as MeshIdent).ID !== undefined && child.userData.originalWorldMatrix !== undefined) {
        if (child.userData.originalWorldMatrix !== undefined) {
          const state: ObjectState = {
            nodeId: (child as MeshIdent).ID,
            opacity: 1,
            selected: false,
            worldMatrix: child.userData.originalWorldMatrix,
            visible: true,
            worldMatrixElements: [],
            color: this.getObjectColor(child),
          };
          initialStates.push(state);
        }
      });
    }
    return initialStates;
  };

  getPreviousStates = (stepId: number, substepId: number) => {
    let previousStates: ObjectState[] | undefined;
    if (this.project) {
      if (stepId === 0 && substepId === 0) {
        previousStates = this.collectInitialObjectStates();
      } else {
        const prev = previousSubstep(this.project, stepId, substepId);
        if (prev?.objectsState !== undefined) {
          previousStates = prev.objectsState;
        }
      }
    }
    return previousStates;
  };

  returnSelectedObjectsToInitialWrappers = () => {
    this.selectedObjectIds.forEach((id) => {
      const object = this.scene?.getObjectById(id);

      if (object && object.parent && this.selectionGroup) {
        object.updateMatrixWorld();
        const oldMatrixWorld = object?.matrixWorld;

        this.selectionGroup.remove(object);
        object.userData.wrapper?.add(object);
        setObjectWorldMatrix(object, oldMatrixWorld);
      }
    });
    if (this.selectionGroup && this.selectionGroup.children.length > 0) {
      if (this.selectionGroup.children[0].userData.meshType !== 'center') {
        console.error(
          'Selection group still contains objects absent in selectedObjectIds'
        );
      }
    }
  };

  collectObjectsFromIds = (ids: number[]) => {
    const objects = new Array<THREE.Object3D>();

    ids.forEach((id) => {
      const object = this.scene?.getObjectById(id);
      if (object !== undefined) {
        objects.push(object);
      }
    });

    return objects;
  };

  updateSelectionGroupWithAction = (action: SubstepAction) => {
    const eulerRotation = new Euler(
      (action.transform.rotation.x * Math.PI) / 180,
      (action.transform.rotation.y * Math.PI) / 180,
      (action.transform.rotation.z * Math.PI) / 180
    );
    this.selectionGroup?.setRotationFromEuler(eulerRotation);

    this.selectionGroup?.scale.set(
      action.transform.scale.x,
      action.transform.scale.y,
      action.transform.scale.z
    );

    this.selectionGroup?.position.set(
      //action.transform.center.x + action.transform.centerOffset.x,
      //action.transform.center.y + action.transform.centerOffset.y,
      //action.transform.center.z + action.transform.centerOffset.z
      action.transform.center.x,
      action.transform.center.y,
      action.transform.center.z
    );

    this.selectionGroup?.updateMatrix();
    this.selectionGroup?.updateMatrixWorld();
  };

  updateEditingActionCenterForObjects = (ids: number[]) => {
    const center = this.findCommonWorldBoundingCenterByIds(ids);
    if (this.editingAction) {
      this.editingAction.transform.center = center;
    }
  };

  moveObjectsToSelectionGroup = (newSelectedObjects: THREE.Object3D[]) => {
    // Update object matrices.
    newSelectedObjects.forEach((object) => {
      if (
        this.selectionGroup !== undefined &&
        this.selectionGroup.matrixWorld !== undefined
      ) {
        object.updateMatrixWorld();

        const oldWorldMatrix = object.matrixWorld.clone();
        object.parent?.remove(object);
        this.selectionGroup.add(object);
        setObjectWorldMatrix(object, oldWorldMatrix);
      }
    });
  };

  findCommonWorldBoundingCenterByIds = (ids: number[]) => {
    const objects = new Array<THREE.Object3D>();
    ids.forEach((id, index) => {
      const object = this.scene?.getObjectById(id);
      if (object !== undefined) {
        objects.push(object);
      }
    });
    return this.findCommonWorldBoundingCenterOfObjects(objects);
  };

  findCommonWorldBoundingCenterOfObjects = (objects: THREE.Object3D[]) => {
    const commonWorldCenter = new Vector3();
    objects.forEach((object, index) => {
      if (object !== undefined) {
        const boundingBox = new THREE.Box3().setFromObject(object);
        const center = new Vector3();
        boundingBox.getCenter(center);

        if (index === 0) {
          commonWorldCenter.set(center.x, center.y, center.z);
        } else {
          // TODO: Look for better common center than average point.
          commonWorldCenter.set(
            (commonWorldCenter.x + center.x) / 2,
            (commonWorldCenter.y + center.y) / 2,
            (commonWorldCenter.z + center.z) / 2
          );
        }
      }
    });

    return commonWorldCenter;
  };

  setObjectMaterialById = (id: number, materialType: MaterialType) => {
    const object = this.scene?.getObjectById(id);
    if (!object) {
      return;
    }
    if (object instanceof THREE.Mesh && object.type === 'Mesh') {
      switch (materialType) {
        case MaterialType.Original:
          object.material = object.userData.originalMaterial;
          break;
        case MaterialType.OriginalWireframe:
          object.material = object.userData.originalWireframeMaterial;
          break;
        case MaterialType.HelperDefault:
          object.material = this.helperDefaultMaterial;
          break;
        case MaterialType.SelectedDefault:
          object.material = this.selectedDefaultMaterial;
          break;
        case MaterialType.SelectedWireframe:
          object.material = this.selectedWireframeMaterial;
          break;
      }
    } else if (object.type === 'Group' && object.children.length > 0) {
      object.children.forEach((child) => {
        this.setObjectMaterialById(child.id, materialType);
      });
    }
  };

  initSelectionGroup = () => {
    if (this.selectionGroup) {
      this.selectionGroup.rotation.set(0, 0, 0);
      this.selectionGroup.position.set(0, 0, 0);
      this.selectionGroup.scale.set(1, 1, 1);

      this.selectionGroup.name = 'Selection Group';
      this.selectionGroup.userData.groupType = GroupType.Selection;
      this.selectionGroup.userData.initialWorldMatrix =
        new Matrix4().identity();

      /*const sphere = new THREE.SphereGeometry(50, 32, 32);
            sphere.parameters.radius = 50;
            const object = new THREE.Mesh(sphere, new THREE.MeshBasicMaterial({ color: 0xff0000 }));
            const box = new THREE.BoxHelper(object, 0xffff00);
            this.selectionGroup.add(box);*/
    }
  };

  setupSelectionGroupFromAction = () => {
    if (this.editingAction && this.selectionGroup) {
      const eulerRotation = new Euler(
        (this.editingAction.transform.rotation.x * Math.PI) / 180,
        (this.editingAction.transform.rotation.y * Math.PI) / 180,
        (this.editingAction.transform.rotation.z * Math.PI) / 180
      );
      this.selectionGroup.setRotationFromEuler(eulerRotation);

      this.selectionGroup.scale.set(
        this.editingAction.transform.scale.x,
        this.editingAction.transform.scale.y,
        this.editingAction.transform.scale.z
      );
      this.selectionGroup.position.set(
        this.editingAction.transform.center.x,
        this.editingAction.transform.center.y,
        this.editingAction.transform.center.z
      );

      this.selectionGroup.updateMatrixWorld();
    }
  };

  updateSelectionGroup = (ids: number[]) => {
    if (!this.selectionGroup) {
      return;
    }

    const dict = new Map<number, number>();

    ids.forEach((id) => {
      dict.set(id, 1);
    });
    this.selectedObjectIds.forEach((id) => {
      if (dict.has(id)) {
        dict.set(id, 0);
      } else {
        dict.set(id, -1);
      }
    });

    // We don't need any objects in current selection group to move the group without moving the objects.

    this.returnSelectedObjectsToInitialWrappers();
    this.selectedObjectIds = [];

    // let calculateBoundingCenter = false;

    /*if (this.editingAction && this.editingAction.transform.center && this.editingAction.transform.center.x == 0 && this.editingAction.transform.center.y == 0 && this.editingAction.transform.center.z == 0
            || this.editingAction && !this.editingAction.transform.center
            || !this.editingAction) {
            calculateBoundingCenter = true;
        }*/

    // Update material for newly selected and unselected objects.
    dict.forEach((value, id) => {
      if (value === -1) {
        // Item to remove.
        this.setObjectMaterialById(
          id,
          this.wireframeMode
            ? MaterialType.OriginalWireframe
            : MaterialType.Original
        );

        //     if (calculateBoundingCenter || !startEdit) {
        //         calculateBoundingCenter = true;
        //     }
      }
    });
    dict.forEach((value, id) => {
      if (value === 1) {
        // Item to add.
        this.setObjectMaterialById(
          id,
          this.wireframeMode
            ? MaterialType.SelectedWireframe
            : MaterialType.SelectedDefault
        );
        //     if (calculateBoundingCenter || !startEdit) {
        //         calculateBoundingCenter = true;
        //    }
      }
    });

    // TODO: Start For test recalculation
    /*if (this.editingAction?.transform.rotation.x == 0
            && this.editingAction?.transform.rotation.y == 0
            && this.editingAction?.transform.rotation.z == 0
            && this.editingAction?.transform.scale.x == 1
            && this.editingAction?.transform.scale.y == 1
            && this.editingAction?.transform.scale.z == 1) {
            calculateBoundingCenter = true;
        }
        else {
            calculateBoundingCenter = false;
        }
        */
    // TODO: End For test recalculation

    // Move to selection group selected objects and return back to wrappers all unselected objects.
    const newSelectedObjects = this.collectObjectsFromIds(ids);

    this.setupSelectionGroupFromAction();

    this.moveObjectsToSelectionGroup(newSelectedObjects);

    // Calculate Initial selection state.
    if (this.editingAction) {
      this.selectionGroup.userData.initialWorldMatrix =
        new Matrix4().makeTranslation(
          this.editingAction.transform.center.x +
            //this.editingAction.transform.centerOffset.x -
            this.editingAction.transform.translation.x,
          this.editingAction.transform.center.y +
            //this.editingAction.transform.centerOffset.y -
            this.editingAction.transform.translation.y,
          this.editingAction.transform.center.z +
            //this.editingAction.transform.centerOffset.z -
            this.editingAction.transform.translation.z
        );

      this.highLightObjectCenter(
        this.selectionGroup,
        this.editingAction.transform.center,
        true
      );
    }

    // Update selected objects array.
    this.selectedObjectIds = ids;

    if (this.selectedObjectIds.length === 0) {
      this.initSelectionGroup();
    }
  };

  highLightObjectCenter = (
    object: THREE.Object3D,
    centerPos: Vector3,
    highLight: boolean
  ) => {
    if (!this.scene) {
      return;
    }

    /*let centerId = 0;

    if (!object || (object.type !== 'Mesh' && object.children.length === 0)) {
      return;
    }

    const bbox = new THREE.Box3().setFromObject(object);
    const boxSize = new THREE.Vector3();
    bbox.getSize(boxSize);

    const radius = boxSize.length() / 100;

    this.scene.traverse((child) => {
      if (child.userData.meshType === 'center') {
        centerId = child.id;
      }
    });

    if (centerId === 0) {
      // Create center mesh.
      const sphereMaterial = new MeshStandardMaterial({
        color: flashEffectColor,
      });

      const nullMaterial = new MeshStandardMaterial({
        color: new THREE.Color(0, 1, 0),
      });

      const testMaterial = new MeshStandardMaterial({
        color: new THREE.Color(0, 1, 1),
      });

      //sphereMaterial.depthTest = false;
      const sphereGeometry = new THREE.SphereGeometry(1, 16, 16);

      const centerMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);
      centerId = centerMesh.id;

      this.scene.add(centerMesh);

      centerMesh.userData.originalMaterial = sphereMaterial;
      centerMesh.userData.originalWireframeMaterial = sphereMaterial;
      centerMesh.userData.meshType = 'center';

      nullMaterial.depthTest = false;
      const nullMesh = new THREE.Mesh(sphereGeometry, nullMaterial);

      this.scene.add(nullMesh);

      nullMesh.userData.originalMaterial = nullMaterial;
      nullMesh.userData.originalWireframeMaterial = nullMaterial;
      nullMesh.userData.meshType = 'null';

      const realMeshPos = new Vector3(
        object.children[0].position.x,
        object.children[0].position.y,
        object.children[0].position.z
      );
      object.updateMatrixWorld();
      const globalMeshPos = object.localToWorld(realMeshPos);

      nullMesh.position.set(globalMeshPos.x, globalMeshPos.y, globalMeshPos.z);
      nullMesh.scale.set(radius, radius, radius);

      testMaterial.depthTest = false;
      const testMesh = new THREE.Mesh(sphereGeometry, testMaterial);

      this.scene.add(testMesh);

      testMesh.userData.originalMaterial = testMaterial;
      testMesh.userData.originalWireframeMaterial = testMaterial;
      testMesh.userData.meshType = 'null';

      testMesh.position.set(160, 100, -5);
      testMesh.scale.set(radius, radius, radius);

      const localHZ = object.worldToLocal(new Vector3(160, 100, -5));
      console.log(localHZ);
    }

    const cMesh = this.scene.getObjectById(centerId);
    if (cMesh && cMesh.position && cMesh.scale) {
      cMesh.position.set(centerPos.x, centerPos.y, centerPos.z);
      cMesh.scale.set(radius, radius, radius);
      cMesh.visible = highLight;
    }*/
  };

  nodeIdsToIds = (nodeIds: number[]) => {
    const ids = new Array<number>();
    this.scene?.traverse((object) => {
      if (nodeIds.includes((object as Object3DIdent).ID)) {
        ids.push(object.id);
      }
    });
    return ids;
  };

  idsToNodeIds = (ids: number[]) => {
    const nodeIds = new Array<number>();
    this.scene?.traverse((object) => {
      if (ids.includes(object.id)) {
        nodeIds.push((object as Object3DIdent).ID);
      }
    });
    return nodeIds;
  };

  showTransformControls = (changedParam: changedActionParam) => {
    if (
      this.editingAction &&
      (this.editingAction.actionType === ActionTypes.axisRotation ||
        this.editingAction.actionType === ActionTypes.screwUnscrew)
    ) {
      this.showRotationAxis(this.editingAction);
    } else {
      this.hideRotationAxis();
    }

    if (changedParam === 'setCenter' && this.editingAction) {
      this.showTransformCenter(this.editingAction);
    }

    if (changedParam === 'finishCenter') {
      this.hideTransformCenter();
    }
  };

  editAction = (
    stepId: number,
    substepId: number,
    action: SubstepAction,
    actionId: number
  ) => {
    // Start edit action only.

    // Fill current action params.
    this.editingAction = action;
    this.currentStepId = stepId;
    this.currentSubstepId = substepId;
    this.currentActionId = actionId;
    this.rotationAxisAngle = action.transform.axisRotation;

    const ids = this.nodeIdsToIds(action.nodeIds);
    this.updateSelectionGroup(ids);
    this.showTransformControls('none');
    this.startEditingActionHelperAnimation();

    this.sendPointerModeEvent('move');
  };

  updateActionFromUi = (
    stepId: number,
    substepId: number,
    action: SubstepAction,
    actionId: number,
    changedParam: changedActionParam
  ) => {
    if (!this.scene || !this.project) {
      return;
    }

    // Fill current action params.

    if (changedParam === 'init') {
      action = defaultAction;
    }

    this.editingAction = action;
    this.currentStepId = stepId;
    this.currentSubstepId = substepId;
    this.currentActionId = actionId;
    this.rotationAxisAngle = action.transform.axisRotation;

    const actionIds = this.nodeIdsToIds(action.nodeIds);

    if (changedParam === 'resetCenter' || changedParam === 'init') {
      // To calculate center need to set objects to the beginning of the action.
      const previousSubstep = previousSubstepIndexes(
        this.project,
        stepId,
        substepId
      );
      updateObjectStatesFromSubstep(
        this.scene,
        this.project,
        previousSubstep.stepId,
        previousSubstep.substepId
      );
      const objectStatesBeginning =
        this.project?.steps[previousSubstep.stepId].substeps[
          previousSubstep.substepId
        ].objectsState;
      if (objectStatesBeginning) {
        setObjectsToStates(this.scene, objectStatesBeginning, false);
      }

      this.updateEditingActionCenterForObjects(actionIds);
      this.showTransformControls(changedParam);
    }

    // Apply current editing action with updated center (if needed).
    updateObjectStatesFromSubstep(
      this.scene,
      this.project,
      stepId,
      substepId,
      action,
      this.currentStepId,
      this.currentSubstepId,
      this.currentActionId
    );

    const objectStates =
      this.project?.steps[stepId].substeps[substepId].objectsState;
    if (objectStates) {
      setObjectsToStates(this.scene, objectStates, false);
    }

    this.updateSelectionGroup(actionIds);

    this.startEditingActionHelperAnimation();

    if (changedParam === 'resetCenter' || changedParam === 'init') {
      this.sendActionChangedEvent();
    }
  };

  updateObjectStatesFromSubstep = (stepId: number, substepId: number) => {
    if (this.scene && this.project) {
      updateObjectStatesFromSubstep(
        this.scene,
        this.project,
        stepId,
        substepId,
        null,
        0,
        0,
        0
      );
    }
  };

  finishEditAction = (save: boolean) => {
    if (!this.scene || !this.project || this.showLoading) {
      return;
    }
    this.hideRotationAxis();
    this.editingAction = undefined;
    this.updateSelectionGroup([]);
    updateObjectStatesFromSubstep(
      this.scene,
      this.project,
      this.currentStepId,
      this.currentSubstepId
    );
    this.currentStepId = 0;
    this.currentSubstepId = 0;

    this.sendPointerModeEvent('select');
  };

  updateEditingActionFromScene = () => {
    if (this.editingAction) {
      this.editingAction.nodeIds = this.idsToNodeIds(this.selectedObjectIds);
      if (this.editingAction.actionType === ActionTypes.axisRotation) {
        this.editingAction.transform = this.getActionTransformFromAxis();
      } else {
        if (this.selectionGroup) {
          this.editingAction.transform =
            this.getActionTransformFromObjectPosition();
        }
      }
    }
  };

  onEditActionChanged = () => {
    if (this.editingAction) {
      this.updateEditingActionFromScene();
      this.updateObjectPositionFromEditingAction();
      this.sendActionChangedEvent();
      this.startEditingActionHelperAnimation();
    }
  };

  sendActionChangedEvent = () => {
    const selectEvent = new CustomEvent('actionChanged', {
      bubbles: true,
      cancelable: true,
      detail: {
        action: this.editingAction,
      },
    });
    this.threeRootElement?.dispatchEvent(selectEvent);
  };

  updateObjectPositionFromEditingAction = () => {
    if (this.editingAction) {
      const previousStates = this.getPreviousStates(
        this.currentStepId,
        this.currentSubstepId
      );
      if (previousStates) {
        // Take parent previous state.
        const parentPreviousState = previousStates.find(
          (state) =>
            this.editingAction &&
            state.nodeId === this.editingAction.transform.anchorNodeId
        );
        const parentWorldMatrix = parentPreviousState
          ? parentPreviousState.worldMatrix
          : new THREE.Matrix4().identity();

        const actionMatrix = calculateTransformMatrix(
          this.editingAction.transform,
          parentWorldMatrix,
          1
        );
        previousStates.forEach((state) => {
          if (this.scene) {
            const object = getObjectByNodeId(this.scene, state.nodeId);
            if (object) {
              let newWorldMatrix = state.worldMatrix;
              if (this.selectedObjectIds.includes(object.id)) {
                newWorldMatrix = new THREE.Matrix4().multiplyMatrices(
                  actionMatrix,
                  state.worldMatrix
                );
              }
              setObjectWorldMatrix(object, newWorldMatrix);
            }
          }
        });
      }
    }
  };

  getActionTransformFromAxis = () => {
    const actionTransform: ActionTransform = {
      axisStart: this.rotationAxis.children[0].position,
      axisEnd: this.rotationAxis.children[1].position,
      basis: TransformBasis.world,
      scale: new Vector3(1, 1, 1),
      anchorNodeId: 0,
      axisRotation: this.rotationAxisAngle,
      center: new Vector3(0, 0, 0),
      centerOffset: new Vector3(0, 0, 0),
      //centerLocal: new Vector3(0, 0, 0),
      rotation: new Vector3(0, 0, 0),
      translation: new Vector3(0, 0, 0),
      screwMove: 0,
      screwTurns: 0,
    };

    return actionTransform;
  };

  getActionTransformFromObjectPosition = () => {
    let actionMatrix = new THREE.Matrix4().identity();
    let center = new Vector3(0, 0, 0);

    let centerOffset = new Vector3();
    //if (this.editingAction?.transform.centerOffset) {
    //centerOffset = this.editingAction?.transform.centerOffset;
    //}

    if (this.selectionGroup) {
      // actionMatrix = new THREE.Matrix4().multiplyMatrices(this.selectionGroup.matrixWorld, new THREE.Matrix4().getInverse(this.selectionGroup.userData.initialWorldMatrix));
      actionMatrix = new THREE.Matrix4().multiplyMatrices(
        new THREE.Matrix4().getInverse(
          this.selectionGroup.userData.initialWorldMatrix
        ),
        this.selectionGroup.matrixWorld
      );

      //center = new Vector3().subVectors(
      //  this.selectionGroup.position,
      //  centerOffset
      //);

      if (this.selectedObjectType === SelectedObjectType.TransformCenter) {
        center = this.transformCenter.position;
      }
    }

    let degRotation = new Vector3();

    if (this.selectionGroup?.rotation) {
      degRotation = new Vector3(
        (this.selectionGroup?.rotation.x * 180) / Math.PI,
        (this.selectionGroup?.rotation.y * 180) / Math.PI,
        (this.selectionGroup?.rotation.z * 180) / Math.PI
      );
    }

    const rotation = new THREE.Quaternion();
    const scale = new THREE.Vector3();

    const actionTransform: ActionTransform = {
      axisStart: new Vector3(0, 0, 0),
      axisEnd: new Vector3(0, 100, 0),
      // scale: new Vector3(1, 1, 1),
      scale: new Vector3(
        this.selectionGroup?.scale.x,
        this.selectionGroup?.scale.y,
        this.selectionGroup?.scale.z
      ),
      basis: TransformBasis.world,
      anchorNodeId: 0,
      axisRotation: 0,
      center,
      centerOffset,
      //centerLocal: new Vector3(),
      rotation: new Vector3(),
      translation: new Vector3(),
      screwTurns: 0,
      screwMove: 0
    };

    actionMatrix.decompose(actionTransform.translation, rotation, scale);
    const eulerRotation = new Euler().setFromQuaternion(rotation);
    actionTransform.rotation.x = (eulerRotation.x * 180) / Math.PI;
    actionTransform.rotation.y = (eulerRotation.y * 180) / Math.PI;
    actionTransform.rotation.z = (eulerRotation.z * 180) / Math.PI;

    // const euler = new THREE.Euler().setFromQuaternion(rotation);
    // actionTransform.rotation.set(euler.x * 180 / Math.PI, euler.y * 180 / Math.PI, euler.z * 180 / Math.PI);

    return actionTransform;
  };

  createTransformCenter = () => {
    const sphereMaterial = new THREE.MeshStandardMaterial({
      roughness: 0,
      metalness: 0.1,
      fog: true,
      skinning: false,
      wireframe: false,
      color: '#9FFF00',
      transparent: true,
      opacity: 0.5,
      depthTest: true,
      depthFunc: THREE.NeverDepth,
      depthWrite: true,
    });

    const sphereGeometry = new THREE.SphereGeometry(1, 32, 32);
    const center = new Vector3(0, 0, 0);
    const sphereCenter = new THREE.Mesh(sphereGeometry, sphereMaterial);

    sphereCenter.userData.originalMaterial = sphereMaterial;
    sphereCenter.userData.originalWireframeMaterial = sphereMaterial;
    sphereCenter.userData.meshType = 'transformCenter';

    this.transformCenter.add(sphereCenter);
    sphereCenter.position.set(center.x, center.y, center.z);

    this.transformCenter.position.set(0, 0, 0);
    this.transformCenter.userData.groupType = GroupType.Center;
    this.transformCenter.visible = false;

    this.scene?.add(this.transformCenter);
  };

  showTransformCenter = (action: SubstepAction) => {
    this.transformCenter.position.set(
      action.transform.center.x,
      action.transform.center.y,
      action.transform.center.z
    );
    this.transformCenter.visible = true;
  };

  selectTransformCenter = () => {
    this.selectedObjectType = SelectedObjectType.TransformCenter;
    this.setPointerMode(PointerModes.move);
  };

  hideTransformCenter = () => {
    if (this.transformControls) {
      this.transformControls.detach();
    }
    this.selectedObjectType = SelectedObjectType.None;
    this.transformCenter.visible = false;
  };

  createRotationAxis = () => {
    const sphereMaterial = new THREE.MeshStandardMaterial({
      roughness: 0,
      metalness: 0.1,
      fog: true,
      skinning: false,
      wireframe: false,
      color: '#FF9F00',
      transparent: true,
      opacity: 0.5,
      depthTest: true,
      depthFunc: THREE.NeverDepth,
      depthWrite: true,
    });
    //const sphereMaterial = new LineMaterial({
    // color: 0x40dd00,
    // linewidth: 12,
    //resolution: new THREE.Vector2(1024, 768),
    //});

    //sphereMaterial.depthTest = false;
    const sphereGeometry = new THREE.SphereGeometry(1, 32, 32);
    const axisStart = new Vector3(0, 0, 0);
    const axisEnd = new Vector3(0, 100, 0);

    const sphereStart = new THREE.Mesh(sphereGeometry, sphereMaterial);

    sphereStart.userData.originalMaterial = sphereMaterial;
    sphereStart.userData.originalWireframeMaterial = sphereMaterial;
    sphereStart.userData.meshType = 'rotationAxisStart';

    const sphereEnd = new THREE.Mesh(sphereGeometry, sphereMaterial);
    sphereEnd.userData.originalMaterial = sphereMaterial;
    sphereEnd.userData.originalWireframeMaterial = sphereMaterial;
    sphereEnd.userData.meshType = 'rotationAxisEnd';

    this.rotationAxis.add(sphereStart);
    sphereStart.position.set(axisStart.x, axisStart.y, axisStart.z);

    this.rotationAxis.add(sphereEnd);
    sphereEnd.position.set(axisEnd.x, axisEnd.y, axisEnd.z);

    this.updateRotationAxisLine();

    this.rotationAxis.position.set(0, 0, 0);
    this.rotationAxis.userData.groupType = GroupType.Rotaton;
    this.rotationAxis.visible = false;

    this.scene?.add(this.rotationAxis);
  };

  selectRotationAxisStart = () => {
    this.selectedObjectType = SelectedObjectType.RotationAxisStart;
    this.setPointerMode(PointerModes.move);
  };

  selectRotationAxisEnd = () => {
    this.selectedObjectType = SelectedObjectType.RotationAxisEnd;
    this.setPointerMode(PointerModes.move);
  };

  showRotationAxis = (action: SubstepAction) => {
    this.rotationAxis.children[0].position.set(
      action.transform.axisStart.x,
      action.transform.axisStart.y,
      action.transform.axisStart.z
    );
    this.rotationAxis.children[1].position.set(
      action.transform.axisEnd.x,
      action.transform.axisEnd.y,
      action.transform.axisEnd.z
    );

    // Line
    this.updateRotationAxisLine();
    this.rotationAxis.updateMatrix();
    this.rotationAxis.updateMatrixWorld();
    this.rotationAxis.visible = true;
  };

  updateRotationAxisLine = () => {
    const axisStart = this.rotationAxis.children[0].position;
    const axisEnd = this.rotationAxis.children[1].position;

    const dist = axisStart.distanceTo(axisEnd);
    const axisStart1 = new Vector3(
      ((dist - this.helperSphereRadius) * axisStart.x +
        this.helperSphereRadius * axisEnd.x) /
        dist,
      ((dist - this.helperSphereRadius) * axisStart.y +
        this.helperSphereRadius * axisEnd.y) /
        dist,
      ((dist - this.helperSphereRadius) * axisStart.z +
        this.helperSphereRadius * axisEnd.z) /
        dist
    );
    const axisEnd1 = new Vector3(
      ((dist - this.helperSphereRadius) * axisEnd.x +
        this.helperSphereRadius * axisStart.x) /
        dist,
      ((dist - this.helperSphereRadius) * axisEnd.y +
        this.helperSphereRadius * axisStart.y) /
        dist,
      ((dist - this.helperSphereRadius) * axisEnd.z +
        this.helperSphereRadius * axisStart.z) /
        dist
    );

    const offset = new THREE.Vector3().subVectors(axisEnd1, axisStart1);

    if (this.rotationAxis.children.length > 2) {
      (this.rotationAxis.children[2] as Wireframe).geometry.dispose();
      this.rotationAxis.remove(this.rotationAxis.children[2]);
    }

    const lineMaterial = new LineMaterial({
      color: 0x60ff00,
      linewidth: 1,
      resolution: new THREE.Vector2(1024, 768),
    });
    lineMaterial.depthTest = false;

    const points = [];
    points.push(new THREE.Vector3(0, 0, 0));
    points.push(offset);

    const lineGeometry = new THREE.BufferGeometry().setFromPoints(points);
    const wireGeometry = new WireframeGeometry2(lineGeometry);
    const wireframeLine = new Wireframe(wireGeometry, lineMaterial);
    wireframeLine.computeLineDistances();
    wireframeLine.scale.set(1, 1, 1);
    this.rotationAxis.add(wireframeLine);
    wireframeLine.position.set(axisStart1.x, axisStart1.y, axisStart1.z);
  };

  hideRotationAxis = () => {
    if (this.transformControls) {
      this.transformControls.detach();
    }
    this.selectedObjectType = SelectedObjectType.None;
    this.rotationAxis.visible = false;
  };

  // Materials
  getProjectSelectedArMaterials = () => {
    const materials = getSelectedArMaterials(
      this.selectedObjectIds,
      this.scene as THREE.Object3D
    );
    return materials;
  };

  getProjectArMaterials = () => {
    const materials = getMaterialsFromObject3D(
      this.scene as THREE.Object3D,
      true
    );

    if (this.project && this.project.materials) {
      this.project.materials.forEach((value: ArMaterial, key) => {
        if (value) {
          materials[value.uuid] = value;
        }
      });
    }

    return materials;
  };

  startAnimationLoop = () => {
    if (!this.renderer || !this.scene || !this.camera || !this.orbitControls) {
      return;
    }

    if (this.loadingTextMesh) {
      if (
        this.showLoading === false &&
        this.scene.children.includes(this.loadingTextMesh)
      ) {
        this.loadingTextMesh.visible = false;
        this.scene.remove(this.loadingTextMesh);
      } else {
        this.loadingTextMesh.scale.x += 0.001;
        this.loadingTextMesh.scale.y += 0.001;
        this.loadingTextMesh.scale.z += 0.001;
        this.loadingTextMesh.rotateX(0.01);
        this.loadingTextMesh.translateX(-0.025);
      }
    }

    // Substeps animation.
    if (this.animationObjects.length > 0) {
      this.performAnimation();
    }

    function distance(v1: THREE.Vector3, v2: THREE.Vector3) {
      const dx = v1.x - v2.x;
      const dy = v1.y - v2.y;
      const dz = v1.z - v2.z;

      return Math.sqrt(dx * dx + dy * dy + dz * dz);
    }

    // Calc helper meshes.
    /*  if (this.rotationAxis && this.rotationAxis.children[0]) {
      const axisStartWorldPosition = new THREE.Vector3();
      this.rotationAxis.children[0].getWorldPosition(axisStartWorldPosition);

      const dist = distance(this.camera.position, axisStartWorldPosition);
      const vFOV = (this.camera.fov * Math.PI) / 180;
      const height = 2 * Math.tan(vFOV / 2) * dist;
      this.rotationAxis.children[0].scale.set(
        height / 100,
        height / 100,
        height / 100
      );
    }
    if (this.rotationAxis && this.rotationAxis.children[1]) {
      const axisEndWorldPosition = new THREE.Vector3();
      this.rotationAxis.children[1].getWorldPosition(axisEndWorldPosition);

      const dist = distance(this.camera.position, axisEndWorldPosition);
      const vFOV = (this.camera.fov * Math.PI) / 180;
      const height = 2 * Math.tan(vFOV / 2) * dist;
      this.rotationAxis.children[1].scale.set(
        height / 100,
        height / 100,
        height / 100
      );
    }*/

    this.orbitControls.update();
    this.renderer.render(this.scene, this.camera);
    this.requestID = window.requestAnimationFrame(this.startAnimationLoop);
  };

  materialEditorMaterialSelected = (arMaterial: ArMaterial) => {
    // Set arMaterial to all selected object on scene and project.
    if (!this.project) return;
    const newProject: ArScenarioProject | undefined = { ...this.project };
    if (this.selectedObjectIds) {
      const nodeIds = this.idsToNodeIds(this.selectedObjectIds);
      nodeIds.forEach((nodeId) => {
        if (newProject && newProject.materials) {
          newProject.materials.set(nodeId, arMaterial);
        }
      });

      this.setMaterialToSceneObjects(arMaterial, this.selectedObjectIds);
    }
    return newProject;
  };

  setMaterialToSceneObjects = (arMaterial: ArMaterial, ids: number[]) => {
    ids.forEach((id) => {
      if (this.scene) {
        const object = this.scene.getObjectById(id);
        if (object instanceof THREE.Mesh) {
          setArMaterialToObject(arMaterial, object);
        } else {
          object?.traverse((subObject) => {
            setArMaterialToObject(arMaterial, subObject);
          });
        }
      }
    });
  };

  updateGlobalMetadata = (metadata: MetadataSet) => {
    Object.entries(metadata).forEach((entry) => {
      const [key, value] = entry;
      if (this.project && this.project.globalMetadata) {
        this.project.globalMetadata[key] = value;
      }
    });
  };

  updateNodeMetadata = (metadata: MetadataSet, nodeId: number) => {
    Object.entries(metadata).forEach((entry) => {
      const [key, value] = entry;
      if (this.project && this.project.metadata) {
        if (!this.project.metadata[nodeId]) {
          this.project.metadata[nodeId] = {};
        }
        if (this.scene) {
          const object = getObjectByNodeId(this.scene, nodeId);
          if (object)
            this.applyNodeMetadata(object, { [key]: value } as MetadataSet);
        }
        this.project.metadata[nodeId][key] = value;
      }
    });
  };

  applyGlobalMetadataOnScene = () => {
    return;
  };

  applyNodesMetadataOnScene = () => {
    if (!this.scene || !this.project?.metadata) return;
    Object.entries(this.project.metadata).forEach((value) => {
      const object = getObjectByNodeId(this.scene!, Number(value[0]));
      if (object) {
        this.applyNodeMetadata(object, value[1] as MetadataSet);
      }
    });
  };

  applyNodeMetadata = (object: Object3D, metadata: MetadataSet) => {
    if (metadata.visible) {
      object.visible = ['true', null, undefined].includes(metadata.visible)
        ? true
        : false;
    }
  };

  setObjectVisibility = (id: number, visible: boolean) => {
    if (this.scene) {
      const object = this.scene.getObjectById(id);
      if (object) {
        object.visible = visible;
      }
    }
  };

  applyProjectMaterialsOnScene = () => {
    if (this.project?.materials) {
      this.project.materials.forEach((value: ArMaterial, key: number) => {
        if (this.scene) {
          const object = getObjectByNodeId(this.scene, key);
          if (object) {
            setArMaterialToObject(value, object);
          }
        }
      });
    }
  };

  rescaleHelpers = () => {
    const rotationAxisRelSize = 0.01;

    if (this.scene && this.scene.children) {
      let maxSize = 0;
      this.scene.children.forEach((object) => {
        if (object && object.userData.groupType === GroupType.Group) {
          const size = objectMaxSize(object);
          if (maxSize < size) {
            maxSize = size;
          }
        }
      });

      this.helperSphereRadius = maxSize * rotationAxisRelSize;

      // Rotation axis.
      this.rotationAxis.children[0].scale.set(
        this.helperSphereRadius,
        this.helperSphereRadius,
        this.helperSphereRadius
      );
      this.rotationAxis.children[1].scale.set(
        this.helperSphereRadius,
        this.helperSphereRadius,
        this.helperSphereRadius
      );
      this.transformCenter.scale.set(
        this.helperSphereRadius,
        this.helperSphereRadius,
        this.helperSphereRadius
      );
    }
  };

  setToolToDnd = (tool?: Tool) => (this.draggedTool = tool);

  onCanvasDragOver = (e: React.DragEvent<HTMLDivElement>) => {};
}
