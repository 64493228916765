import React, { Component } from 'react';
import clone from 'clone';
import { Vector3 } from 'three';
import deepEqual from 'deep-equal';

import { changedActionParam, IActiveAction } from '../../buzzcommon';
import { ActionTypes } from '../../types/default-objects';

import styles from './styles.module.scss';
import { MoveIcon } from '../../images/icons';
import { StylizedInput, StylizedFab, StylizedButton } from '../stylized';

enum TransformTypes {
  translation = 'translation',
  rotation = 'rotation',
  scale = 'scale',
  center = 'center',
  centerOffset = 'centerOffset',
  axisStart = 'axisStart',
  axisEnd = 'axisEnd',
}

enum Axis {
  x = 'x',
  y = 'y',
  z = 'z',
}

interface IAxis {
  x: string;
  y: string;
  z: string;
}

interface InputState {
  center: IAxis;
  centerOffset: IAxis;
  translation: IAxis;
  rotation: IAxis;
  scale: IAxis;
  axisStart: IAxis;
  axisEnd: IAxis;
}

const defaultAxis = {
  x: '',
  y: '',
  z: '',
};

const initialInputsValue: InputState = {
  center: { ...defaultAxis },
  centerOffset: { ...defaultAxis },
  translation: { ...defaultAxis },
  rotation: { ...defaultAxis },
  scale: { ...defaultAxis },
  axisStart: { ...defaultAxis },
  axisEnd: { ...defaultAxis },
};

interface IProps {
  actionType: string;
  activeAction: IActiveAction | undefined;
  onActiveActionChange: (
    action: IActiveAction,
    changedParam: changedActionParam
  ) => void;
  onSetInitialPosition: () => void;
  onChangeCenter: (onCenterChange: 'moveCenter' | 'resetCenter') => void;
  onMoveAxis: (changeParam: 'moveAxisStart' | 'moveAxisEnd') => void;
}

interface IState {
  axisRotation: string;
  inputsState: InputState;
}

export class AdvanceInputsForAction extends Component<IProps, IState> {
  state: IState = {
    axisRotation: '',
    inputsState: initialInputsValue,
  };

  constructor(props: IProps) {
    super(props);

    this.changeRotationAndState(false);
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (deepEqual(prevProps, this.props)) return;

    this.changeRotationAndState(
      this.state.axisRotation.endsWith('.') || this.state.axisRotation === '-'
    );
  }

  changeRotationAndState = (isRotationFromState: boolean) => {
    const { activeAction } = this.props;

    if (!activeAction) return;

    const axisRotation = isRotationFromState
      ? this.state.axisRotation
      : activeAction.transform.axisRotation.toString();

    const inputsState: InputState = { ...initialInputsValue };
    Object.keys(activeAction.transform).forEach((key, index) => {
      if (
        activeAction &&
        typeof activeAction.transform[key as TransformTypes] !== 'number'
      ) {
        const val = activeAction.transform[key as TransformTypes];
        if (val) {
          Object.keys(val).forEach((key2, index2) => {
            if (
              activeAction &&
              typeof val[key2 as Axis] === 'number' &&
              !inputsState[key as TransformTypes][key2 as Axis].endsWith('.') &&
              inputsState[key as TransformTypes][key2 as Axis] !== '-'
            ) {
              inputsState[key as TransformTypes][key2 as Axis] =
                val[key2 as Axis].toString();
            }
          });
        } else {
          Object.keys(inputsState[key as TransformTypes]).forEach(
            (key2, index2) => {
              if (
                !inputsState[key as TransformTypes][key2 as Axis].endsWith(
                  '.'
                ) &&
                inputsState[key as TransformTypes][key2 as Axis] !== '-'
              ) {
                inputsState[key as TransformTypes][key2 as Axis] =
                  initialInputsValue[key as TransformTypes][key2 as Axis];
              }
            }
          );
        }
      }
    });

    this.setState({ axisRotation, inputsState });
  };

  formNumber = (value: string) => {
    if (!value.length || value === '-') return 0;

    if (!/^-?[0-9]*(\.[0-9]{0,2})?$/.test(value)) return null;

    const newNumber = Number.parseFloat(value);
    if (newNumber || newNumber === 0) return newNumber;

    return null;
  };

  getInputValueAndNumber = (oldValue: string, newValue: string) => {
    let inputValue = newValue.replace(',', '.');
    const enteredCharacter = inputValue.charAt(inputValue.length - 1);

    if (oldValue === '0') {
      inputValue =
        enteredCharacter === '-'
          ? '-'
          : Number.parseFloat(inputValue).toString();
    }

    const newNumber = this.formNumber(inputValue);

    return { inputValue, newNumber };
  };

  handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    param: TransformTypes,
    axis: Axis
  ) => {
    if (!this.props.activeAction) return;

    const { inputValue, newNumber } = this.getInputValueAndNumber(
      this.state.inputsState[param][axis],
      e.currentTarget.value
    );

    if (
      (inputValue !== '-' && !+inputValue && +inputValue !== 0) ||
      (newNumber !== 0 && !newNumber)
    ) {
      return;
    }

    this.setState((prevState: IState) => {
      const inputsState = clone<InputState>(prevState.inputsState);
      inputsState[param][axis] = inputValue.length ? inputValue : '0';
      return {
        axisRotation: prevState.axisRotation,
        inputsState,
      };
    });

    const action = this.props.activeAction;
    if (!action.transform[param]) {
      action.transform[param] = new Vector3();
    }

    switch (axis) {
      case Axis.x:
        action.transform[param].x = newNumber;
        break;
      case Axis.y:
        action.transform[param].y = newNumber;
        break;
      case Axis.z:
        action.transform[param].z = newNumber;
        break;
      default:
        return;
    }

    this.props.onActiveActionChange(action, 'transform');
  };

  handleAxisRotationChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (!this.props.activeAction) return;

    const { inputValue, newNumber } = this.getInputValueAndNumber(
      this.state.axisRotation,
      e.currentTarget.value
    );

    if (
      (inputValue !== '-' && !+inputValue && +inputValue !== 0) ||
      (newNumber !== 0 && !newNumber)
    ) {
      return;
    }
    this.setState({ axisRotation: inputValue.length ? inputValue : '0' });
    const action = this.props.activeAction;
    action.transform.axisRotation = newNumber;
    this.props.onActiveActionChange(action, 'transform');
  };

  renderAxisGroup = (
    title: string,
    group: IAxis,
    type?: TransformTypes,
    element?: JSX.Element
  ) => {
    return (
      <div className={styles.inputs}>
        <p className={styles.inputsTitle}>{title}</p>
        <div className={styles.inputsGroup}>
          <StylizedInput
            className={styles.inputField}
            onChange={
              type ? (e) => this.handleChange(e, type, Axis.x) : undefined
            }
            value={group.x}
          />

          <StylizedInput
            className={styles.inputField}
            onChange={
              type ? (e) => this.handleChange(e, type, Axis.y) : undefined
            }
            value={group.y}
          />

          <StylizedInput
            className={styles.inputField}
            onChange={
              type ? (e) => this.handleChange(e, type, Axis.z) : undefined
            }
            value={group.z}
          />

          {element && <div className={styles.additionalElement}>{element}</div>}
        </div>
      </div>
    );
  };

  renderTransformContent = () => {
    const { inputsState } = this.state;

    const centerMoveButton = (
      <StylizedFab
        iconClassName={styles.inputButton}
        tooltipTitle={'Move center'}
        onClick={() => this.props.onChangeCenter('moveCenter')}
        isFixating={true}
      >
        <MoveIcon />
      </StylizedFab>
    );

    return (
      <>
        {this.renderAxisGroup(
          'Translation:',
          inputsState.translation,
          TransformTypes.translation
        )}

        {this.renderAxisGroup(
          'Rotation (Euler angles):',
          inputsState.rotation,
          TransformTypes.rotation
        )}

        {this.renderAxisGroup(
          'Center:',
          inputsState.center,
          TransformTypes.center,
          centerMoveButton
        )}

        {this.renderAxisGroup(
          'Scale:',
          inputsState.scale,
          TransformTypes.scale
        )}
      </>
    );
  };

  renderScrewAxisRotationContent = () => {
    const { inputsState, axisRotation } = this.state;

    const moveStartButton = (
      <StylizedFab
        iconClassName={styles.inputButton}
        tooltipTitle={'Move axis start'}
        onClick={() => this.props.onMoveAxis('moveAxisStart')}
      >
        <MoveIcon />
      </StylizedFab>
    );

    const moveEndButton = (
      <StylizedFab
        iconClassName={styles.inputButton}
        tooltipTitle={'Move axis end'}
        onClick={() => this.props.onMoveAxis('moveAxisEnd')}
      >
        <MoveIcon />
      </StylizedFab>
    );

    return (
      <>
        {this.renderAxisGroup(
          'Rotation axis start:',
          inputsState.axisStart,
          TransformTypes.axisStart,
          moveStartButton
        )}

        {this.renderAxisGroup(
          'Rotation axis end:',
          inputsState.axisEnd,
          TransformTypes.axisEnd,
          moveEndButton
        )}

        <div className={styles.inputs}>
          <div className={styles.inputsTitle}>Angle:</div>
          <div className={styles.inputsGroup}>
            <StylizedInput
              className={styles.inputField}
              onChange={this.handleAxisRotationChange}
              value={axisRotation}
            />
          </div>
        </div>
      </>
    );
  };

  renderAxisRotation = () => {
    const { inputsState, axisRotation } = this.state;

    return (
      <>
        {this.renderAxisGroup(
          'Rotation axis start:',
          inputsState.axisStart,
          TransformTypes.axisStart
        )}

        {this.renderAxisGroup(
          'Rotation axis end:',
          inputsState.axisEnd,
          TransformTypes.axisEnd
        )}

        <div className={styles.inputs}>
          <div className={styles.inputsTitle}>Angle:</div>
          <div className={styles.inputsGroup}>
            <StylizedInput
              className={styles.inputField}
              onChange={this.handleAxisRotationChange}
              value={axisRotation}
            />
          </div>
        </div>
      </>
    );
  };

  renderContent = (type: string) => {
    const { inputsState, axisRotation } = this.state;
    switch (type) {
      case ActionTypes.transform:
        return this.renderTransformContent();
      case ActionTypes.screwUnscrew:
      case ActionTypes.axisRotation:
        return this.renderScrewAxisRotationContent();
      default:
        return <p className={styles.inputsTitle}>no parameters</p>;
    }
  };

  render() {
    return (
      <div className={styles.advance}>
        <div className={styles.allInputs}>
          {this.renderContent(this.props.actionType)}
        </div>

        <div className={styles.buttons}>
          {this.props.actionType === 'transform' && (
            <StylizedButton
              className={styles.button}
              colorType="secondary"
              onClick={() => this.props.onChangeCenter('resetCenter')}
            >
              Reset Center
            </StylizedButton>
          )}

          <StylizedButton
            className={styles.button}
            colorType="secondary"
            onClick={this.props.onSetInitialPosition}
          >
            Initial Position
          </StylizedButton>
        </div>
      </div>
    );
  }
}
