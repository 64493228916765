import { MetadataFields } from '../buzzcommon';

export const globalMetadata: MetadataFields = {
  project_name: {
    uiName: 'Project name',
    internalName: 'project_name',
    required: true,
  },
  param_name5: {
    uiName: 'Fifth parameter',
    internalName: 'param_name5',
    required: false,
    options: ['1', '2', '3', '4', '5', '6', '7'],
  },
};
