import { MenuItem, Select, SelectProps } from '@material-ui/core';
import classnames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

const StylizedSelect = (props: SelectProps & { options: string[] }) => {
  return (
    <Select
      className={classnames(styles.select, props.className)}
      value={props.value ?? ''}
      onChange={props.onChange}
      disableUnderline={true}
      classes={{
        root: styles.effectSelect,
        select: styles.selectItem,
        icon: styles.selectIcon,
      }}
      MenuProps={{ classes: { paper: styles.menu } }}
    >
      {!props.required && (
        <MenuItem value="" className={styles.menuItem}>
          None
        </MenuItem>
      )}

      {props.options.map((element, index) => {
        return (
          <MenuItem key={index} value={element} className={styles.menuItem}>
            {element}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default StylizedSelect;
