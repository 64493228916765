import { Backdrop, Fade, Modal, ModalProps } from '@material-ui/core';
import classnames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

const StylizedModal = (props: ModalProps & { title?: string }) => {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      className={styles.wrapper}
      closeAfterTransition={true}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <div
          className={classnames(styles.modalBody, props.className, {
            [styles.contentWithTitle]: !!props.title,
          })}
        >
          {props.title && <p className={styles.title}>{props.title}</p>}

          {props.children}
        </div>
      </Fade>
    </Modal>
  );
};

export default StylizedModal;
