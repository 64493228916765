import { v4 as uuidv4 } from 'uuid';
import {
  ArProjectStep,
  ArProjectSubstep,
  ArScenarioProject,
  IActiveAction,
  ObjectState,
  SubstepIndexes,
} from '../../../../buzzcommon';

const defaultSubStep: ArProjectSubstep = {
  uuid: '',
  actions: [],
  name: 'sub step 1',
  duration: 1,
  description: 'Substep description',
  objectsState: new Array<ObjectState>(),
};

export const previousSubstepIndexes = (
  project: ArScenarioProject,
  stepId: number,
  substepId: number
): SubstepIndexes => {
  if (project.steps[stepId] && project.steps[stepId].substeps[substepId - 1]) {
    return {
      stepId,
      substepId: substepId - 1,
    };
  }

  if (
    project.steps[stepId - 1] &&
    project.steps[stepId - 1].substeps[
      project.steps[stepId - 1].substeps.length - 1
    ]
  ) {
    return {
      stepId: stepId - 1,
      substepId: project.steps[stepId - 1].substeps.length - 1,
    };
  }

  return {
    stepId: -1,
    substepId: -1,
  };
};

export const previousSubstep = (
  project: ArScenarioProject,
  stepId: number,
  substepId: number
): ArProjectSubstep | null => {
  const indexes = previousSubstepIndexes(project, stepId, substepId);
  if (
    indexes.stepId >= 0 &&
    indexes.substepId >= 0 &&
    project.steps[indexes.stepId].substeps[indexes.substepId]
  ) {
    return project.steps[indexes.stepId].substeps[indexes.substepId];
  }

  return null;
};

export const nextSubstepIndexes = (
  project: ArScenarioProject,
  stepId: number,
  substepId: number
): SubstepIndexes => {
  if (project.steps[stepId] && project.steps[stepId].substeps[substepId + 1]) {
    return {
      stepId,
      substepId: substepId + 1,
    };
  }

  if (project.steps[stepId + 1] && project.steps[stepId + 1].substeps[0]) {
    return {
      stepId: stepId + 1,
      substepId: 0,
    };
  }

  return {
    stepId: -1,
    substepId: -1,
  };
};

export const nextSubstep = (
  project: ArScenarioProject,
  stepId: number,
  substepId: number
): ArProjectSubstep | null => {
  const indexes = nextSubstepIndexes(project, stepId, substepId);
  if (
    indexes.stepId >= 0 &&
    indexes.substepId >= 0 &&
    project.steps[indexes.stepId].substeps[indexes.substepId]
  ) {
    return project.steps[indexes.stepId].substeps[indexes.substepId];
  }

  return null;
};

const removeAtPosition = <T>(array: T[], index: number) => {
  const copy = [...array];
  copy.splice(index, 1);
  return copy;
};

export const createStep = (project: ArScenarioProject, name: string) => {
  const selectedStep: ArProjectStep = {
    uuid: uuidv4(),
    name,
    description: '',
    substeps: [{ ...defaultSubStep }],
  };

  project.steps = [...project.steps, selectedStep];

  return { project, selectedStep };
};

export const removeStep = (project: ArScenarioProject, stepId: number) => {
  if (project.steps[stepId]) {
    project.steps = removeAtPosition(project.steps, stepId);
  }

  return {
    project,
    selectedStep: undefined,
    selectedSubstep: undefined,
  };
};

export const createSubstep = (
  project: ArScenarioProject,
  substepName: string,
  stepId: number,
  substepId?: number
) => {
  const selectedSubstep: ArProjectSubstep = {
    ...defaultSubStep,
    uuid: uuidv4(),
    actions: [],
    name: substepName,
  };

  const selectedStep = { ...project.steps[stepId] };
  if (selectedStep) {
    selectedStep.substeps = [...selectedStep.substeps];
    if (substepId || substepId === 0) {
      selectedStep.substeps.splice(substepId, 0, selectedSubstep);
    } else {
      selectedStep.substeps.push(selectedSubstep);
    }
  }

  project.steps[stepId] = selectedStep;

  return { project, selectedStep, selectedSubstep };
};

export const removeSubStep = (
  project: ArScenarioProject,
  stepId: number,
  substepId: number
) => {
  const selectedStep = { ...project.steps[stepId] };

  if (selectedStep.substeps[substepId]) {
    project.steps = [...project.steps];
    project.steps[stepId] = {
      ...selectedStep,
      substeps: removeAtPosition(selectedStep.substeps, substepId),
    };
  }

  return { project, selectedStep };
};

export const removeAction = (
  project: ArScenarioProject,
  stepId: number,
  substepId: number,
  actionId: number
) => {
  const selectedStep = { ...project.steps[stepId] };
  const selectedSubstep = { ...selectedStep.substeps[substepId] };

  project.steps[stepId].substeps[substepId].actions = removeAtPosition(
    selectedSubstep.actions,
    actionId
  );

  return {
    project,
    selectedStep,
    selectedSubstep: stepId !== 0 ? selectedSubstep : undefined,
  };
};

export const saveAction = (
  project: ArScenarioProject,
  action: IActiveAction,
  stepId: number,
  substepId: number
) => {
  if (!action) return { project };

  const substep = { ...project.steps[stepId].substeps[substepId] };

  if (substep.actions[action.id]) {
    project.steps[stepId].substeps[substepId].actions[action.id] = {
      ...action,
    };
  } else if (substep) {
    project.steps[stepId].substeps[substepId].actions.push(action);
  }

  return { project };
};
