import React from 'react';

import { CircularProgress } from '@material-ui/core';

import styles from './styles.module.scss';

const AbsoluteLoader = () => {

  return (
    <div className={styles.wrapper}>
        <CircularProgress classes={{circle: styles.circle}} />
    </div>
  );
};

export default AbsoluteLoader;
