import React from 'react';
import { StylizedModal, StylizedButton } from '../../stylized';
import styles from './styles.module.scss';

interface IProps {
  open: boolean;
  title?: string;
  message: React.ReactNode;
  onClose: () => void;
  onSubmit: () => void;
}

const ConfirmationModal = (props: IProps) => {
  const { open, title, message, onClose, onSubmit } = props;

  return (
    <StylizedModal
      open={open}
      onClose={onClose}
      className={styles.modalContent}
      title={title}
    >
      <div className={styles.modalBody}>
        <p className={styles.description}>{message}</p>

        <div className={styles.buttons}>
          <StylizedButton
            colorType="default"
            onClick={onSubmit}
            className={styles.button}
          >
            Ok
          </StylizedButton>

          <StylizedButton
            colorType="secondary"
            onClick={onClose}
            className={styles.button}
          >
            Close
          </StylizedButton>
        </div>
      </div>
    </StylizedModal>
  );
};

export default ConfirmationModal;
