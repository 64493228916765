import { MetadataFields } from '../buzzcommon';

export const groupMetadata: MetadataFields = {
  visible: {
    uiName: 'Visible in 3D mode',
    internalName: 'visible',
    required: false,
    options: ['true', 'false'],
  },
  visibleAR: {
    uiName: 'Visible in AR mode',
    internalName: 'visibleAR',
    required: false,
    options: ['true', 'false'],
  },
  occlusion: {
    uiName:
      'This node is an invisible object to hide objects behind it (occlusion)',
    internalName: 'occlusion',
    required: false,
    options: ['true', 'false'],
  },
};
