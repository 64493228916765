import {
  ArMaterial,
  ArModel,
  ArScenarioProject,
  ArScenarioProjectToSave,
} from '../light-editor/src/buzzcommon';
import { api, apiConfig, BACKEND_URL } from './configure';
import { handleResponse } from './galleryRequests';
import {
  ArModelResponse,
  ArScenarioProjectResponse,
  CommonMaterialsResponse,
} from './types';

export const getCommonMaterials = async (): Promise<ArMaterial[]> => {
  const response = await api.get<CommonMaterialsResponse>(
    '/api/materials/common'
  );

  if (response.data.errors) throw new Error(response.data.errors[0]);

  return response.data.data;
};

export const getOAProject = async (): Promise<ArScenarioProjectResponse> => {
  const url = `/api/scenarios/${apiConfig.scenarioId}/projects/${apiConfig.projectId}?ticket=${apiConfig.token}`;
  const response = await api.get<ArScenarioProjectResponse>(url);

  return response.data;
};

export const formGetModelUrl = (uniqueId: string): string => {
  return `${BACKEND_URL}/api/scenarios/${apiConfig.scenarioId}/modelfiles/${uniqueId}?ticket=${apiConfig.token}`;
};

export const updateProject = async (
  project: ArScenarioProjectToSave | ArScenarioProject,
  isEditFinished: boolean
): Promise<ArScenarioProject> => {
  const url = `/api/scenarios/${apiConfig.scenarioId}/projects/${apiConfig.projectId}?ticket=${apiConfig.token}`;
  const response = await api.patch<ArScenarioProjectResponse>(url, {
    isEditFinished,
    arProject: project,
  });

  return response.data.data;
};

export const addNewModel = async (modelFile: File): Promise<ArModel> => {
  const url = `/api/scenarios/${apiConfig.scenarioId}/projects/${apiConfig.projectId}/addmodel?ticket=${apiConfig.token}`;

  const formData = new FormData();
  formData.append('modelFile', modelFile);

  return handleResponse<ArModelResponse['data']>(
    (await api.post<ArModelResponse>(url, formData)).data
  );
};

export const removeModel = async (modelId: number): Promise<void> => {
  const url = `/api/scenarios/${apiConfig.scenarioId}/projects/${apiConfig.projectId}/models/${modelId}?ticket=${apiConfig.token}`;
  const response = await api.delete(url);

  return response.data.data;
};

export const postScenarioFile = async (file: File) => {
  const url = `/api/scenarios/${apiConfig.scenarioId}/files/media?ticket=${apiConfig.token}`;

  const formData = new FormData();
  formData.append('scenarioFile', file);

  const response = await api.post(url, formData);
  return response.data;
};

export const formScenarioFileUrl = (fileName: string): string => {
  return `${BACKEND_URL}/viewer/document/?id=${apiConfig.scenarioId}&inline=true&image=${fileName}&ticket=${apiConfig.token}`;
};
