import React from 'react';
import LightEditor, {
  LightEditor as LightEditorClass,
} from './light-editor/src/editor';
import './index.css';
import { configureAxios } from './api/configure';
import { destroyCurrentWindow, handleOnAppClose } from './services/electron';
import {
  ArMaterial,
  ArScenarioProject,
  ArScenarioProjectToSave,
  CategorizedTools,
  CommonMaterials,
  ExtendedTool,
} from './light-editor/src/buzzcommon';
import {
  updateProject,
  getCommonMaterials,
  getOAProject,
  addNewModel,
  formGetModelUrl,
  formScenarioFileUrl,
  postScenarioFile,
  removeModel,
} from './api/backendApi';
import {
  addGalleryItemToProject,
  addModelToGallery,
  createGalleryItem,
  deleteGalleryItem,
  getGallery,
  getGalleryItemFbx,
  getGalleryItemFbxUrl,
  getGalleryItemThumb,
  updateGalleryItem,
  uploadGalleryItemFbx,
  uploadGalleryItemThumb,
} from './api/galleryRequests';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';
import { SaveModal } from './light-editor/src/components/modals';

type State = {
  isModelsFetched: boolean;
  isCloseModalOpen: boolean;
  isProjectChanged: boolean;
  fetchDataError?: string;
  isAxiosNotConfigured?: boolean;
  project?: ArScenarioProject;
  commonMaterials?: CommonMaterials;
  categorizedTools?: CategorizedTools;
  projectName: string;
};

type Props = {};

class App extends React.Component<Props, State> {
  lightEditorRef = React.createRef<LightEditorClass>();
  private cleanup: (() => void) | undefined = undefined;

  state: State = {
    isModelsFetched: false,
    isCloseModalOpen: false,
    isProjectChanged: false,
    projectName: '',
  };

  constructor(props: Props) {
    super(props);

    try {
      const cleanupAxios = configureAxios();
      this.cleanup = () => cleanupAxios?.();
    } catch (error) {
      console.log('An API is not configured: ', error);

      this.setState({
        isAxiosNotConfigured: true,
      });
    }
  }

  componentDidMount() {
    this.fetchData();

    handleOnAppClose(() => {
      if (!this.lightEditorRef.current) return;

      if (!this.state.isCloseModalOpen && this.state.isProjectChanged) {
        this.setState({ isCloseModalOpen: true });
      } else {
        destroyCurrentWindow();
      }
    });
  }

  componentWillUnmount() {
    this.cleanup?.();
  }

  onAppClose = async (save: boolean) => {
    if (save && this.lightEditorRef.current) {
      await updateProject(this.lightEditorRef.current.state.project, true);
    }

    this.setState({ isCloseModalOpen: false });
    destroyCurrentWindow();
  };

  fetchData = async () => {
    try {
      await this.fetchCommonMaterials();
    } catch (e) {
      console.log('🚀 ~ file: App.tsx ~ line 102 ~ App ~ fetchData= ~ e', e);
    }

    try {
      const tools = await getGallery();
      const categorizedTools: CategorizedTools = {};

      tools.forEach((tool) => {
        const extendedTool: ExtendedTool = { ...tool, thumbUrl: '' };

        if (categorizedTools[tool.categoryName]) {
          categorizedTools[tool.categoryName].push(extendedTool);
        } else {
          categorizedTools[tool.categoryName] = [extendedTool];
        }
      });

      this.setState({ categorizedTools });
    } catch (error) {
      console.log('file: App.tsx ~ line 36 ~ App ~ fetchData= ~ error', error);
    }

    try {
      await this.getProjectRequest();
    } catch (error) {
      this.setState({
        fetchDataError: `Cannot load project. ${error.message}`,
      });
      // TODO: handle fetch project issues
      return;
    }
  };

  fetchCommonMaterials = async () => {
    const response = await getCommonMaterials();

    if (!response || !response.length) return;
    const commonMaterials: CommonMaterials = { Main: {} };
    response.forEach((material) => {
      const uuid = material.uuid;

      if (material.category) {
        commonMaterials[material.category][uuid] = material;
      } else if (
        !material.category ||
        !material.category.length ||
        material.category === 'null'
      ) {
        material.category = 'Main';
        commonMaterials.Main[uuid] = material;
      }
    });

    this.setState({ commonMaterials });
  };

  getProjectRequest = async () => {
    const response = await getOAProject();
    const project = response.data;

    if (!project.globalMetadata) project.globalMetadata = {};
    if (!project.metadata) project.metadata = {};

    const arMaterials = new Map<number, ArMaterial>();

    if (project.materials) {
      Object.entries(project.materials).forEach((entry) => {
        const [key, arMaterial] = entry;
        const index = parseInt(key, 10);
        if (index && arMaterial) {
          arMaterials.set(index, arMaterial);
        }
      });
    }

    project.uuid = project.uuid ?? uuidv4();

    project.steps.forEach((step, stepIndex) => {
      if (!step.uuid) project.steps[stepIndex].uuid = uuidv4();

      step.substeps.forEach((substep, substepIndex) => {
        if (!substep.uuid)
          project.steps[stepIndex].substeps[substepIndex].uuid = uuidv4();
      });
    });

    project.materials = arMaterials;

    console.log(project);

    this.setState({ project });
  };

  getTitle = () => {
    if (!this.state.projectName) {
      return 'Buzz.Ar';
    } else {
      return `${this.state.isProjectChanged ? '*' : ''} ${
        this.state.projectName
      } - Buzz.Ar`;
    }
  };

  getLoader = (<div className="">Loading Data...</div>);
  getErrorMessage = (message: string) => <div className="error">{message}</div>;

  renderElement = () => {
    if (this.state.isAxiosNotConfigured) {
      return this.getErrorMessage('An API is not configured');
    }

    if (this.state.project && this.state.categorizedTools) {
      return (
        <LightEditor
          editorRef={this.lightEditorRef}
          project={this.state.project}
          isProjectChanged={this.state.isProjectChanged}
          setIsProjectChanged={(isProjectChanged: boolean) =>
            this.setState({ isProjectChanged })
          }
          getProjectRequest={this.getProjectRequest}
          saveProjectRequest={(project: ArScenarioProject, isEditFinished: boolean) => {
            const jsonMaterials = Object.fromEntries(project.materials);
            const projToSave: ArScenarioProjectToSave = {
              ...project,
              materials: jsonMaterials,
            };
            return updateProject(projToSave, isEditFinished);
          }}
          getFbxUrl={formGetModelUrl}
          addNewModelRequest={addNewModel}
          commonMaterials={this.state.commonMaterials}
          categorizedTools={this.state.categorizedTools}
          removeModelRequest={removeModel}
          postScenarioFile={postScenarioFile}
          formScenarioFileUrl={formScenarioFileUrl}
          getGalleryItemThumb={getGalleryItemThumb}
          getGalleryItemFbx={getGalleryItemFbx}
          createGalleryItem={createGalleryItem}
          updateGalleryItem={updateGalleryItem}
          uploadGalleryItemFbx={uploadGalleryItemFbx}
          uploadGalleryItemThumb={uploadGalleryItemThumb}
          addGalleryItemToProject={addGalleryItemToProject}
          getGalleryItemFbxUrl={getGalleryItemFbxUrl}
          addModelToGallery={addModelToGallery}
          deleteGalleryItem={deleteGalleryItem}
          updateProjectTitle={(projectName: string) =>
            this.setState({ projectName })
          }
        />
      );
    }

    if (this.state.fetchDataError) {
      return this.getErrorMessage(this.state.fetchDataError);
    }

    return this.getLoader;
  };

  render() {
    return (
      <>
        <Helmet>
          <title>{this.getTitle()}</title>
        </Helmet>

        <div className="wrapper">
          {this.renderElement()}
          <SaveModal
            open={this.state.isCloseModalOpen}
            onSaveProject={this.onAppClose}
            onClose={() => this.setState({ isCloseModalOpen: false })}
          />
        </div>
      </>
    );
  }
}

export default App;
