import Quill from 'quill';

const BaseImageFormat = Quill.import('formats/image');
const ImageFormatAttributesList = ['alt', 'height', 'width', 'style'];

class ImageFormat extends BaseImageFormat {
  static formats(domNode: Element) {
    return ImageFormatAttributesList.reduce(
      (formats: { [key: string]: string }, attribute: string) => {
        if (domNode.hasAttribute(attribute)) {
          formats[attribute] = domNode.getAttribute(attribute)!;
        }
        return formats;
      },
      {}
    );
  }
  format = (name: string, value: string) => {
    if (ImageFormatAttributesList.indexOf(name) <= -1) {
      this.format(name, value);
      return;
    }

    if (value) {
      this.domNode.setAttribute(name, value);
    } else {
      this.domNode.removeAttribute(name);
    }
  };
}

export default ImageFormat;
