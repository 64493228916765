import React, { useState } from 'react';
import classnames from 'classnames';

import { CategorizedTools, ExtendedTool, Tool } from '../../buzzcommon';

import StylizedScrollbars from '../stylized/scrollbars';
import { ToolsIcon } from '../../images/icons';
import styles from './styles.module.scss';
import { ToolInformationModal } from '../modals';

interface Props {
  isToolsOpen: boolean;
  categorizedTools: CategorizedTools;
  setIsToolsOpen: (isToolsOpen: boolean) => void;
  addToolToProject: (uniqueId: string) => Promise<void>;
  deleteTool: (tool: ExtendedTool) => Promise<void>;
  changeTool: (oldTool: ExtendedTool, newTool: ExtendedTool) => Promise<Tool>;
  setToolToDnd: (tool?: Tool) => void;
}

const Tools = (props: Props) => {
  const {
    categorizedTools,
    isToolsOpen,
    setIsToolsOpen,
    addToolToProject,
    deleteTool,
    changeTool,
    setToolToDnd,
  } = props;

  const [selectedTool, setSelectedTool] = useState<ExtendedTool>();

  const onToolDragOver = (e: React.DragEvent<HTMLDivElement>, tool: Tool) => {
    const crt = document.createElement('div');
    e.dataTransfer.setDragImage(crt, 0, 0);

    setToolToDnd(tool);
  };

  const renderTools = () => {
    return Object.entries(categorizedTools).map(([category, tools]) => {
      return (
        <div className={styles.category} key={category}>
          <p className={styles.categoryTitle}>{category}</p>
          <div className={styles.elements}>{toolsToJsx(tools)}</div>
        </div>
      );
    });
  };

  const toolsToJsx = (tools: ExtendedTool[]) =>
    tools.map((tool) => {
      return (
        <div
          className={classnames(styles.element)}
          key={tool.uniqueId}
          onClick={() => setSelectedTool(tool)}
          draggable={true}
          onDragStart={(e) => onToolDragOver(e, tool)}
          onDragEnd={(e) => setToolToDnd(undefined)}
        >
          {tool.thumbUrl ? (
            <img
              src={tool.thumbUrl}
              className={styles.image}
              draggable={false}
            />
          ) : (
            <div className={styles.noThumb}>
              <p>?</p>
            </div>
          )}
          <p className={styles.toolTitle}>{tool.name}</p>
        </div>
      );
    });

  return (
    <>
      <div
        className={classnames(styles.body, { [styles.open]: isToolsOpen })}
        onClick={!isToolsOpen ? () => setIsToolsOpen(true) : undefined}
      >
        <div
          className={styles.main}
          onClick={() => setIsToolsOpen(!isToolsOpen)}
        >
          <ToolsIcon className={styles.icon} />
          <p className={styles.title}>Gallery</p>
        </div>

        <div
          className={classnames(styles.materialTab, {
            [styles.open]: isToolsOpen,
          })}
        >
          <div className={styles.elementsWrapper}>
            <StylizedScrollbars disabledHorizontal={true} withScrollGradients={true}>
              <div className={styles.categories}>{renderTools()}</div>
            </StylizedScrollbars>
          </div>
        </div>
      </div>

      {!!selectedTool && (
        <ToolInformationModal
          open={!!selectedTool}
          onClose={() => setSelectedTool(undefined)}
          tool={selectedTool}
          addToolToProject={addToolToProject}
          onToolDelete={deleteTool}
          onToolChange={changeTool}
        />
      )}
    </>
  );
};

export default Tools;
