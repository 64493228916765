import Quill from 'quill';
import BlotFormatter from 'quill-blot-formatter';
import ImageFormat from './ImageFormat';

Quill.register(ImageFormat, true);
Quill.register('modules/blotFormatter', BlotFormatter);

export class HtmlEditorConfig {
  editorRef: any;
  postScenarioFile: ((file: File) => any) | undefined;
  formScenarioFileUrl: ((fileName: string) => string) | undefined;

  fileHandler = (type: 'video' | 'image') => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', `${type}/*`);
    input.click();

    input.onchange = async () => {
      if (
        !input.files ||
        !input.files[0] ||
        !this.editorRef.current ||
        !this.postScenarioFile ||
        !this.formScenarioFileUrl
      )
        return;

      const editor = this.editorRef.current.getEditor();

      const data = await this.postScenarioFile(input.files[0]);
      const range = editor.getSelection(true);

      editor.insertEmbed(
        range.index,
        type,
        this.formScenarioFileUrl(data.link),
        'user'
      );
      editor.setSelection(range.index + 1);
    };
  };

  modules = {
    blotFormatter: {},
    toolbar: {
      container: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ color: [] }, { background: [] }],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' },
          { align: [] },
        ],
        ['link', 'image', 'video'],
        ['clean'],
      ],
      handlers: {
        image: () => this.fileHandler('image'),
        video: () => this.fileHandler('video'),
      },
    },
    clipboard: { matchVisual: false },
  };

  formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'size',
    'color',
    'background',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
    'video',
    'align',
    'alt',
    'height',
    'width',
    'style',
  ];
}
