// tslint:disable: max-classes-per-file
import { ActionEffect, ActionTransform, ArMaterial, ArProjectSubstep, ArScenarioProject, ObjectState, RGBColor, SubstepAction, SubstepIndexes } from "./types";
import * as THREE from "three";
import { Mesh, Object3D } from "three";

const defaultSnapshotCanvasWidth = 128;
const defaultSnapshotCanvasHeight = 128;

const defaultMaterialSnapshotCanvasWidth = 128;
const defaultMaterialSnapshotCanvasHeight = 128;

export const flashEffectColor = new THREE.Color(1, 1, 0);

export class Object3DIdent extends THREE.Object3D {
  ID: number = 0;
}

export class GroupIdent extends THREE.Group {
  ID: number = 0;
}

export class MeshIdent extends THREE.Mesh {
  ID: number = 0;
}

// Object states calculations
export const calculateSubstepObjectStates = (
  project: ArScenarioProject,
  stepId: number,
  substepId: number,
  previousObjectStates: ObjectState[],
  progress: number,
  replaceAction: SubstepAction | null,
  currentStepId: number,
  currentSubstepId: number,
  currentActionId: number
) => {
  const transforms = new Map<number, THREE.Matrix4>();
  const nodeEffects = new Map<number, ActionEffect[]>();

  // Calculate action matrices per each object.
  if (project.steps[stepId]?.substeps[substepId]) {
    project.steps[stepId].substeps[substepId].actions.forEach((action, idx) => {
      // Put currently editing action.
      if (
        replaceAction &&
        stepId === currentStepId &&
        substepId === currentSubstepId &&
        idx === currentActionId
      ) {
        action = replaceAction;
      }
      // Take anchor previous state.
      const anchorPreviousState = previousObjectStates.find(
        (state) => state.nodeId === action.transform.anchorNodeId
      );
      const anchorWorldMatrix = anchorPreviousState
        ? anchorPreviousState.worldMatrix
        : new THREE.Matrix4().identity();

      // Calculate action matrix.
      const actionMatrix = calculateTransformMatrix(
        action.transform,
        anchorWorldMatrix,
        progress
      );

      action.nodeIds.forEach((nodeId) => {
        transforms.set(nodeId, actionMatrix);
        nodeEffects.set(nodeId, action.effects);
      });
    });
  }
  const objectStates = new Array<ObjectState>();
  let stateAdded = false;

  previousObjectStates.forEach((previousState) => {
    stateAdded = false;
    if (transforms.has(previousState.nodeId)) {
      const transformMatrix = transforms.get(previousState.nodeId);
      if (transformMatrix !== undefined) {
        // const newWorldMatrix = new THREE.Matrix4().multiplyMatrices(previousState.worldMatrix, transformMatrix);
        const newWorldMatrix = new THREE.Matrix4().multiplyMatrices(
          transformMatrix,
          previousState.worldMatrix
        );

        let newOpacity = previousState.opacity;

        // Process effects.
        if (nodeEffects.has(previousState.nodeId)) {
          const effects = nodeEffects.get(previousState.nodeId);
          if (effects && effects.length > 0) {
            effects.forEach((effect) => {
              if (effect.type === "show") {
                newOpacity = 1;
              }
              if (effect.type === "hide") {
                newOpacity = 0;
              }
            });
          }
        }

        const newState: ObjectState = {
          worldMatrix: newWorldMatrix,
          selected: previousState.selected,
          nodeId: previousState.nodeId,
          opacity: newOpacity,
          visible: previousState.visible,
          worldMatrixElements: [],
          color: previousState.color,
        };

        objectStates.push(newState);
        stateAdded = true;
      }
    }
    if (!stateAdded) {
      // Transformed state was not added for the object, add previous state for the object.
      objectStates.push(previousState);
    }
  });

  return objectStates;
};

export const calculateTransformMatrix = (
  transform: ActionTransform,
  anchorWorldMatrix: THREE.Matrix4,
  progress: number
) => {
  // Rotation around an arbitrary axis in space.
  const div = Math.sqrt(
    (transform.axisEnd.x - transform.axisStart.x) ** 2 +
      (transform.axisEnd.y - transform.axisStart.y) ** 2 +
      (transform.axisEnd.z - transform.axisStart.z) ** 2
  );

  const cx = (transform.axisEnd.x - transform.axisStart.x) / div;
  const cy = (transform.axisEnd.y - transform.axisStart.y) / div;
  const cz = (transform.axisEnd.z - transform.axisStart.z) / div;
  const d = Math.sqrt(cy * cy + cz * cz);

  const tMatrix = new THREE.Matrix4().makeTranslation(
    transform.axisStart.x,
    transform.axisStart.y,
    transform.axisStart.z
  );
  let rxMatrix = new THREE.Matrix4();
  let ryMatrix = new THREE.Matrix4();

  if (d > 0) {
    rxMatrix = new THREE.Matrix4().set(
      // Transposed representation
      1,
      0,
      0,
      0,
      0,
      cz / d,
      cy / d,
      0,
      0,
      -cy / d,
      cz / d,
      0,
      0,
      0,
      0,
      1
    );

    ryMatrix = new THREE.Matrix4().set(
      // Transposed representation
      d,
      0,
      -cx,
      0,
      0,
      1,
      0,
      0,
      cx,
      0,
      d,
      0,
      0,
      0,
      0,
      1
    );
  }

  const rotationRad = (transform.axisRotation * Math.PI) / 180;
  const rfiMatrix = new THREE.Matrix4().set(
    // Transposed representation
    Math.cos(rotationRad * progress),
    -Math.sin(rotationRad * progress),
    0,
    0,
    Math.sin(rotationRad * progress),
    Math.cos(rotationRad * progress),
    0,
    0,
    0,
    0,
    1,
    0,
    0,
    0,
    0,
    1
  );
  const mMatrix = new THREE.Matrix4().multiplyMatrices(
    new THREE.Matrix4().multiplyMatrices(tMatrix, rxMatrix), // T * Rx
    ryMatrix
  ); // T * Rx * Ry
  const mMatrixInv = new THREE.Matrix4().getInverse(mMatrix);

  const axisRotationMatrix = new THREE.Matrix4().multiplyMatrices(
    new THREE.Matrix4().multiplyMatrices(mMatrix, rfiMatrix),
    mMatrixInv
  );

  // Calculate translation.
  const transMatrix = new THREE.Matrix4().set(
    1,
    0,
    0,
    transform.translation.x * progress,
    0,
    1,
    0,
    transform.translation.y * progress,
    0,
    0,
    1,
    transform.translation.z * progress,
    0,
    0,
    0,
    1
  );

  // Calculate rotation.
  const rotationMatrix = new THREE.Matrix4().makeRotationFromEuler(
    new THREE.Euler(
      (transform.rotation.x / 180) * Math.PI * progress,
      (transform.rotation.y / 180) * Math.PI * progress,
      (transform.rotation.z / 180) * Math.PI * progress
    )
  );

  // Calculate scaling.
  const scaleMatrix = new THREE.Matrix4().makeScale(
    (transform.scale.x - 1) * progress + 1,
    (transform.scale.y - 1) * progress + 1,
    (transform.scale.z - 1) * progress + 1
  );

  if (!transform.center) {
    transform.center = new THREE.Vector3(0, 0, 0);
  }

  if (!transform.centerOffset) {
    transform.centerOffset = new THREE.Vector3(0, 0, 0);
  }

  // Calculate Centering and Basis
  const initialCenter = new THREE.Vector3(
    transform.center.x + transform.centerOffset.x - transform.translation.x,
    transform.center.y + transform.centerOffset.y - transform.translation.y,
    transform.center.z + transform.centerOffset.z - transform.translation.z
  );

  const centerTranslationMatrixInv = new THREE.Matrix4().makeTranslation(
    -initialCenter.x,
    -initialCenter.y,
    -initialCenter.z
  );
  const centerTranslationMatrix = new THREE.Matrix4().makeTranslation(
    initialCenter.x,
    initialCenter.y,
    initialCenter.z
  );

  const transformMatrix = new THREE.Matrix4().multiplyMatrices(
    transMatrix,
    new THREE.Matrix4().multiplyMatrices(
      centerTranslationMatrix,
      new THREE.Matrix4().multiplyMatrices(
        rotationMatrix,
        new THREE.Matrix4().multiplyMatrices(
          scaleMatrix,
          centerTranslationMatrixInv
        )
      )
    )
  );

  // if (transform.basis === TransformBasis.local) {
  // transformMatrix = new THREE.Matrix4().multiplyMatrices(anchorWorldMatrix, transformMatrix);
  // }

  // Calculate transform matrix.
  const fullTransformMatrix = new THREE.Matrix4().multiplyMatrices(
    transformMatrix,
    axisRotationMatrix
  );

  return fullTransformMatrix;
};

export const updateObjectStatesFromSubstep = (
  scene: THREE.Scene,
  project: ArScenarioProject,
  stepId: number,
  substepId: number,
  replaceAction: SubstepAction | null = null,
  currentStepId: number = 0,
  currentSubstepId: number = 0,
  currentActionId: number = 0
) => {
  if (project === undefined) {
    return;
  }
  const previousStates = getPreviousStates(scene, project, stepId, substepId);

  if (previousStates !== undefined) {
    const currentStates = calculateSubstepObjectStates(
      project,
      stepId,
      substepId,
      previousStates,
      1,
      replaceAction,
      currentStepId,
      currentSubstepId,
      currentActionId
    );
    project.steps[stepId].substeps[substepId].objectsState = currentStates;
  }
  const next = nextSubstepIndexes(project, stepId, substepId);
  if (next !== undefined && next.stepId >= 0 && next.substepId >= 0) {
    updateObjectStatesFromSubstep(
      scene,
      project,
      next.stepId,
      next.substepId,
      replaceAction,
      currentStepId,
      currentSubstepId,
      currentActionId
    );
  }
};

export const getPreviousStates = (
  scene: THREE.Scene,
  project: ArScenarioProject,
  stepId: number,
  substepId: number
) => {
  let previousStates: ObjectState[] | undefined;
  if (project) {
    if (stepId === 0 && substepId === 0) {
      previousStates = collectInitialObjectStates(scene);
    } else {
      const prev = previousSubstep(project, stepId, substepId);
      if (prev?.objectsState !== undefined) {
        previousStates = prev.objectsState;
      }
    }
  }
  return previousStates;
};

export const previousSubstepIndexes = (
  project: ArScenarioProject,
  stepId: number,
  substepId: number
): SubstepIndexes => {
  if (project.steps[stepId] && project.steps[stepId].substeps[substepId - 1]) {
    return {
      stepId,
      substepId: substepId - 1,
    };
  } else if (
    project.steps[stepId - 1] &&
    project.steps[stepId - 1].substeps[
      project.steps[stepId - 1].substeps.length - 1
    ]
  ) {
    return {
      stepId: stepId - 1,
      substepId: project.steps[stepId - 1].substeps.length - 1,
    };
  } else {
    return {
      stepId: -1,
      substepId: -1,
    };
  }
};

export const previousSubstep = (
  project: ArScenarioProject,
  stepId: number,
  substepId: number
): ArProjectSubstep | null => {
  const indexes = previousSubstepIndexes(project, stepId, substepId);
  if (
    indexes.stepId >= 0 &&
    indexes.substepId >= 0 &&
    project.steps[indexes.stepId].substeps[indexes.substepId]
  ) {
    return project.steps[indexes.stepId].substeps[indexes.substepId];
  } else return null;
};

export const nextSubstepIndexes = (
  project: ArScenarioProject,
  stepId: number,
  substepId: number
): SubstepIndexes => {
  if (project.steps[stepId] && project.steps[stepId].substeps[substepId + 1]) {
    return {
      stepId,
      substepId: substepId + 1,
    };
  } else if (
    project.steps[stepId + 1] &&
    project.steps[stepId + 1].substeps[0]
  ) {
    return {
      stepId: stepId + 1,
      substepId: 0,
    };
  } else {
    return {
      stepId: -1,
      substepId: -1,
    };
  }
};

export const nextSubstep = (
  project: ArScenarioProject,
  stepId: number,
  substepId: number
): ArProjectSubstep | null => {
  const indexes = nextSubstepIndexes(project, stepId, substepId);
  if (
    indexes.stepId >= 0 &&
    indexes.substepId >= 0 &&
    project.steps[indexes.stepId].substeps[indexes.substepId]
  ) {
    return project.steps[indexes.stepId].substeps[indexes.substepId];
  } else return null;
};

export const collectInitialObjectStates = (scene: THREE.Scene) => {
  const initialStates = new Array<ObjectState>();

  scene.traverse((child: THREE.Object3D) => {
    // if (child instanceof THREE.Mesh && child.type === 'Mesh' && (child as MeshIdent).ID !== undefined && child.userData.originalWorldMatrix !== undefined) {
    if (child.userData.originalWorldMatrix !== undefined) {
      const state: ObjectState = {
        nodeId: (child as MeshIdent).ID,
        opacity: 1,
        selected: false,
        worldMatrix: child.userData.originalWorldMatrix,
        visible: true,
        worldMatrixElements: [],
        color: getObjectColor(child),
      };

      initialStates.push(state);
    }
  });

  return initialStates;
};

// Utils
export const ToIndexedGeometry = (
  sourceGeometry: THREE.BufferGeometry,
  precision: number
) => {
  function floor(array: ArrayLike<number>, offset: number) {
    if (array instanceof Float32Array) {
      return Math.floor(array[offset] * prec);
    } else {
      return array[offset];
    }
  }

  function setItem(
    attribute: THREE.BufferAttribute,
    index: number,
    value: any
  ) {
    const r = index % 3;
    const idx = (index / attribute.itemSize) >> 0;
    switch (r) {
      case 0:
        attribute.setX(idx, value);
        break;
      case 1:
        attribute.setY(idx, value);
        break;
      case 2:
        attribute.setZ(idx, value);
        break;
      case 3:
        attribute.setW(idx, value);
        break;
    }
  }

  function createAttribute(
    srcAttribute: THREE.BufferAttribute | THREE.InterleavedBufferAttribute
  ) {
    const dstAttribute = new THREE.BufferAttribute(
      new Float32Array(length * srcAttribute.itemSize),
      srcAttribute.itemSize
    );

    const dstArray = dstAttribute.array;
    const srcArray = srcAttribute.array;

    switch (srcAttribute.itemSize) {
      case 1:
        for (let i = 0, l = list.length; i < l; i++) {
          setItem(dstAttribute, i, srcArray[list[i]]);
        }

        break;
      case 2:
        for (let i = 0, l = list.length; i < l; i++) {
          const index = list[i] * 2;

          const offset = i * 2;

          setItem(dstAttribute, offset, srcArray[index]);

          setItem(dstAttribute, offset + 1, srcArray[index + 1]);
        }

        break;
      case 3:
        for (let i = 0, l = list.length; i < l; i++) {
          const index = list[i] * 3;

          const offset = i * 3;

          // dst_array[offset] = src_array[index];
          setItem(dstAttribute, offset, srcArray[index]);

          // dst_array[offset + 1] = src_array[index + 1];
          setItem(dstAttribute, offset + 1, srcArray[index + 1]);

          // dst_array[offset + 2] = src_array[index + 2];
          setItem(dstAttribute, offset + 2, srcArray[index + 2]);
        }

        break;
      case 4:
        for (let i = 0, l = list.length; i < l; i++) {
          const index = list[i] * 4;

          const offset = i * 4;

          // dst_array[offset] = src_array[index];
          setItem(dstAttribute, offset, srcArray[index]);

          // dst_array[offset + 1] = src_array[index + 1];
          setItem(dstAttribute, offset + 1, srcArray[index + 1]);
          // dst_array[offset + 2] = src_array[index + 2];
          setItem(dstAttribute, offset + 2, srcArray[index + 2]);
          // dst_array[offset + 3] = src_array[index + 3];
          setItem(dstAttribute, offset + 3, srcArray[index + 3]);
        }

        break;
    }

    return dstAttribute;
  }

  function hashAttribute(
    attribute: THREE.BufferAttribute | THREE.InterleavedBufferAttribute,
    offset: number
  ) {
    const array = attribute.array;

    switch (attribute.itemSize) {
      case 1:
        return floor(array, offset);

      case 2:
        return floor(array, offset) + "_" + floor(array, offset + 1);

      case 3:
        return (
          floor(array, offset) +
          "_" +
          floor(array, offset + 1) +
          "_" +
          floor(array, offset + 2)
        );

      case 4:
        return (
          floor(array, offset) +
          "_" +
          floor(array, offset + 1) +
          "_" +
          floor(array, offset + 2) +
          "_" +
          floor(array, offset + 3)
        );
    }
  }

  function store(index: number, n: number) {
    let id = "";

    for (let i = 0, l = attributesKeys.length; i < l; i++) {
      const key = attributesKeys[i];
      const attribute = _src.attributes[key];

      const offset = attribute.itemSize * index * 3 + n * attribute.itemSize;

      id += hashAttribute(attribute, offset) + "_";
    }

    for (let i = 0, l = morphKeys.length; i < l; i++) {
      const key = morphKeys[i];
      const attribute: any = _src.morphAttributes[key];

      const offset = attribute.itemSize * index * 3 + n * attribute.itemSize;

      id += hashAttribute(attribute, offset) + "_";
    }

    if (vertices[id] === undefined) {
      vertices[id] = list.length;

      list.push(index * 3 + n);
    }

    return vertices[id];
  }

  function storeFast(x: any, y: any, z: any, v: any) {
    const id =
      Math.floor(x * prec) +
      "_" +
      Math.floor(y * prec) +
      "_" +
      Math.floor(z * prec);

    if (vertices[id] === undefined) {
      vertices[id] = list.length;

      list.push(v);
    }

    return vertices[id];
  }

  function indexBufferGeometry(src: THREE.BufferGeometry, fullIndex: boolean) {
    _src = src;
    const dst = new THREE.BufferGeometry();

    attributesKeys = Object.keys(src.attributes);
    morphKeys = Object.keys(src.morphAttributes);

    const position = src.attributes.position.array;
    const faceCount = position.length / 3 / 3;

    const typedArray = faceCount * 3 > 65536 ? Uint32Array : Uint16Array;
    const indexArray = new typedArray(faceCount * 3);

    // Full index only connects vertices where all attributes are equal

    if (fullIndex) {
      for (let i = 0, l = faceCount; i < l; i++) {
        // I is face id.
        indexArray[i * 3] = store(i, 0);
        indexArray[i * 3 + 1] = store(i, 1);
        indexArray[i * 3 + 2] = store(i, 2);
      }
    } else {
      for (let i = 0, l = faceCount; i < l; i++) {
        const offset = i * 9;

        indexArray[i * 3] = storeFast(
          position[offset],
          position[offset + 1],
          position[offset + 2],
          i * 3
        );
        indexArray[i * 3 + 1] = storeFast(
          position[offset + 3],
          position[offset + 4],
          position[offset + 5],
          i * 3 + 1
        );
        indexArray[i * 3 + 2] = storeFast(
          position[offset + 6],
          position[offset + 7],
          position[offset + 8],
          i * 3 + 2
        );
      }
    }

    // Index

    dst.index = new THREE.BufferAttribute(indexArray, 1);

    length = list.length;

    // Attributes
    dst.attributes = {};

    for (let i = 0, l = attributesKeys.length; i < l; i++) {
      const key = attributesKeys[i];

      dst.attributes[key] = createAttribute(src.attributes[key]);
    }

    // Morph Attributes

    for (let i = 0, l = morphKeys.length; i < l; i++) {
      const key = morphKeys[i];

      // dst.morphAttributes[key] = createAttribute(src.morphAttributes[key]);
      // TODO: Solve the issue with morph attributes
    }

    if (src.boundingSphere) {
      dst.boundingSphere = src.boundingSphere.clone();
    } else {
      dst.boundingSphere = new THREE.Sphere();
      dst.computeBoundingSphere();
    }

    if (src.boundingBox) {
      dst.boundingBox = src.boundingBox.clone();
    } else {
      dst.boundingBox = new THREE.Box3();
      dst.computeBoundingBox();
    }

    // Groups

    const groups = src.groups;

    for (let i = 0, l = groups.length; i < l; i++) {
      const group = groups[i];

      dst.addGroup(group.start, group.count, group.materialIndex);
    }

    // Release data

    vertices = {};
    list = [];

    _src = null as any;
    attributesKeys = [];
    morphKeys = [];

    return dst;
  }

  // Start method.

  let list: number[] = [];
  let vertices: {
    [id: string]: number;
  } = {};

  let _src: THREE.BufferGeometry;
  let attributesKeys: string[];
  let morphKeys: string[];

  let prec = 0;
  let precHalf = 0;
  let length = 0;

  precision = precision || 6;

  prec = Math.pow(10, precision);
  precHalf = Math.pow(10, Math.floor(precision / 2));

  const dstGeometry = indexBufferGeometry(sourceGeometry, true);

  return dstGeometry;
};

export const splitByMaterial = (mesh: THREE.Mesh) => {
  if (!Array.isArray(mesh.material)) {
    return mesh;
  }

  const geometry =
    mesh.geometry instanceof THREE.BufferGeometry
      ? new THREE.Geometry().fromBufferGeometry(mesh.geometry)
      : mesh.geometry;
  const materials = mesh.material;

  // let geo: THREE.Geometry;
  let vMap: any = {};
  // let iMat: number;
  const geoMap: Record<string, THREE.Geometry> = {};

  const group = new THREE.Group();
  group.name = mesh.name;
  (group as GroupIdent).ID = (mesh as MeshIdent).ID;
  group.position.setX(mesh.position.x);
  group.position.setY(mesh.position.y);
  group.position.setZ(mesh.position.z);
  group.rotation.setFromQuaternion(mesh.quaternion);
  group.scale.setX(mesh.scale.x);
  group.scale.setY(mesh.scale.y);
  group.scale.setZ(mesh.scale.z);
  group.userData.originalWorldMatrix = mesh.userData.originalWorldMatrix;

  const addSubmesh = function (index: string, geo: THREE.Geometry) {
    const mat = (materials as any)[index];
    mat.side = THREE.DoubleSide;
    const submesh = new THREE.Mesh(geo, mat);
    submesh.name = group.name + "(" + index + ")";
    (submesh as MeshIdent).ID =
      (group as GroupIdent).ID * 100 + parseInt(index, 10);
    group.add(submesh);
    return submesh as MeshIdent;
  };

  geometry.faces.forEach((face) => {
    if (geoMap[face.materialIndex] === undefined) {
      geoMap[face.materialIndex] = new THREE.Geometry();
    }

    vMap = {};
    const f: any = face.clone();

    ["a", "b", "c"].forEach((p) => {
      const iv = (face as any)[p];
      if (!vMap.hasOwnProperty(iv))
        vMap[iv] =
          geoMap[face.materialIndex].vertices.push(geometry.vertices[iv]) - 1;
      f[p] = vMap[iv];
    });

    geoMap[face.materialIndex].faces.push(f);
  });

  group.userData.submeshes = [];

  for (const geoMapIdx in geoMap) {
    if (geoMapIdx) {
      const submesh = addSubmesh(geoMapIdx, geoMap[geoMapIdx]);
      (group.userData.submeshes as MeshIdent[]).push(submesh);
    }
  }

  return group;
};

export const getObjectColor = (object: THREE.Object3D) => {
  let color: RGBColor = {
    r: 0,
    g: 0,
    b: 0,
  };

  if (object instanceof THREE.Mesh) {
    const material =
      Array.isArray(object.material) && object.material.length > 0
        ? object.material[0]
        : object.material;

    if (
      material instanceof THREE.MeshStandardMaterial ||
      material instanceof THREE.MeshBasicMaterial ||
      material instanceof THREE.MeshPhongMaterial ||
      material instanceof THREE.MeshLambertMaterial ||
      material instanceof THREE.MeshToonMaterial ||
      material instanceof THREE.MeshMatcapMaterial ||
      material instanceof THREE.MeshPhysicalMaterial
    ) {
      color = {
        r: material.color.r,
        g: material.color.g,
        b: material.color.b,
      };
    }
  }

  return color;
};

export const getObjectOriginalOpacity = (object: THREE.Object3D) => {
  let opacity: number = 0;

  if (object instanceof THREE.Mesh) {
    const material =
      Array.isArray(object.material) && object.material.length > 0
        ? object.material
        : [object.material];

    material.forEach((item) => {
      if (
        item instanceof THREE.MeshStandardMaterial ||
        item instanceof THREE.MeshBasicMaterial ||
        item instanceof THREE.MeshPhongMaterial ||
        item instanceof THREE.MeshLambertMaterial ||
        item instanceof THREE.MeshToonMaterial ||
        item instanceof THREE.MeshMatcapMaterial ||
        item instanceof THREE.MeshPhysicalMaterial
      ) {
        opacity += item.opacity;
      }
    });
    opacity = opacity / material.length;
  }

  return opacity;
};

export const getObjectByNodeId = (
  parent: THREE.Object3D,
  nodeId: number
): THREE.Object3D | undefined => {
  if ((parent as MeshIdent).ID === nodeId) return parent;
  for (let i = 0, l = parent.children.length; i < l; i++) {
    const child = parent.children[i];
    const object: THREE.Object3D | undefined = getObjectByNodeId(child, nodeId);
    if (object !== undefined) {
      return object;
    }
  }

  return undefined;
};

export const createMaterialPreview = (
  arMaterial: ArMaterial
): string | undefined => {
  const htmlDiv = document.createElement('div');
  htmlDiv.style.width = '128px';
  htmlDiv.style.height = '128px';

  const geometry = new THREE.SphereGeometry(220, 32, 32);

  const material = new THREE.MeshPhysicalMaterial({
    color: new THREE.Color(
      arMaterial.color.r,
      arMaterial.color.g,
      arMaterial.color.b
    ),
    opacity: arMaterial.opacity,
    transparent: arMaterial.transparent,
  });

  if (arMaterial.roughness) {
    material.roughness = arMaterial.roughness;
  }
  if (arMaterial.emissiveIntensity) {
    material.emissiveIntensity = arMaterial.emissiveIntensity;
  }
  if (arMaterial.metalness) {
    material.metalness = arMaterial.metalness;
  }

  if (arMaterial.emissiveColor) {
    material.emissive = new THREE.Color(
      arMaterial.emissiveColor.r,
      arMaterial.emissiveColor.g,
      arMaterial.emissiveColor.b
    );
  }

  const mesh = new THREE.Mesh(geometry, material);

  const imgData = snapshotObject(
    htmlDiv,
    mesh,
    defaultMaterialSnapshotCanvasWidth,
    defaultMaterialSnapshotCanvasWidth
  );

  return imgData;
};

export const normalizeObjectSize = (object: THREE.Object3D) => {
  const bbox = new THREE.Box3().setFromObject(object);
  const boxSize = new THREE.Vector3();
  bbox.getSize(boxSize);

  // Normalize to 100x100x100 box.
  const maxSize = Math.max(boxSize.x, boxSize.y, boxSize.z);
  const scaleFactor = 100 / maxSize;

  const normalizedObject = cloneObject(object);

  normalizedObject.scale.set(
    normalizedObject.scale.x * scaleFactor,
    normalizedObject.scale.y * scaleFactor,
    normalizedObject.scale.z * scaleFactor
  );
  return normalizedObject;
};

export const cloneObject = (object: THREE.Object3D) => {
  const userDatas = new Map<number, Map<string, any>>();
  object.traverse((obj) => {
    const userData = new Map<string, any>();
    Object.keys(obj.userData).forEach((key) => {
      userData.set(key, obj.userData[key]);
      obj.userData[key] = null;
    });

    userDatas.set(obj.id, userData);
  });

  const newObject = object.clone();

  object.traverse((obj) => {
    const data = userDatas.get(obj.id);
    if (data) {
      Object.keys(obj.userData).forEach((key) => {
        obj.userData[key] = data.get(key);
      });
    }
  });

  return newObject;
};

const cleanMaterial = (material: THREE.Material) => {
  material.dispose();

  // dispose textures
  //for (const key of Object.keys(material)) {
  //const value = material[key];
  //if (value && typeof value === 'object' && 'minFilter' in value) {
  //  console.log('dispose texture!');
  //  value.dispose();
  // }
  //}
};

export const urlToFile = async (
  url: string,
  filename: string,
  mimeType: string
) => {
  const res = await fetch(url);
  const buf = await res.arrayBuffer();
  return new File([buf], filename, { type: mimeType });
};

export const snapshotObject = (
  htmlDivRef: HTMLDivElement,
  object: THREE.Object3D,
  canvasWidth: number,
  canvasHeigh: number
) => {
  let camera;
  let scene;
  let renderer;

  const normalizedObject = normalizeObjectSize(object);

  renderer = new THREE.WebGLRenderer({
    preserveDrawingBuffer: true,
    antialias: true,
  });

  if (canvasWidth === 0) {
    canvasWidth = defaultSnapshotCanvasWidth;
  }

  if (canvasHeigh === 0) {
    canvasHeigh = defaultSnapshotCanvasHeight;
  }

  renderer.setSize(canvasWidth, canvasHeigh);
  htmlDivRef.appendChild(renderer.domElement);

  camera = new THREE.PerspectiveCamera(70, 1, 1, 1000);
  camera.position.z = 85;

  const lights1 = new THREE.PointLight(0xffffff, 1, 0);
  const lights2 = new THREE.AmbientLight(0xffffff, 1);

  lights1.position.set(900, 500, 850);
  lights2.position.set(-400, 800, 1850);

  scene = new THREE.Scene();

  scene.add(lights1);
  scene.add(lights2);

  scene.add(normalizedObject);

  renderer.render(scene, camera);

  let imgData = "";

  try {
    imgData = renderer.domElement.toDataURL("image/jpeg");
  } catch (e) {
    console.log(e);
  }

  renderer.dispose();

  scene.traverse((object: Object3D) => {
    if (object instanceof Mesh) {
      object.geometry.dispose();

      if (object.material instanceof THREE.Material) {
        cleanMaterial(object.material);
      } else {
        // an array of materials
        for (const material of object.material) cleanMaterial(material);
      }
    }
  });

  return imgData;
};

export const setObjectWorldMatrix = (
  object: THREE.Object3D,
  worldMatrix: THREE.Matrix4
) => {
  if (object.parent) {
    // object.matrix.copy(new THREE.Matrix4().multiplyMatrices(new THREE.Matrix4().getInverse(object.parent.matrixWorld), worldMatrix));
    const newLocalMatrix = new THREE.Matrix4().multiplyMatrices(
      new THREE.Matrix4().getInverse(object.parent.matrixWorld),
      worldMatrix
    );

    const newTranslation = new THREE.Vector3();
    const newRotation = new THREE.Quaternion();
    const newScale = new THREE.Vector3();

    // const newEuler = new THREE.Euler().setFromRotationMatrix(newLocalMatrix);

    newLocalMatrix.decompose(newTranslation, newRotation, newScale);

    object.position.set(newTranslation.x, newTranslation.y, newTranslation.z);
    // object.setRotationFromEuler(newEuler);
    // object.setRotationFromMatrix(newLocalMatrix);
    object.setRotationFromQuaternion(newRotation);
    object.scale.set(newScale.x, newScale.y, newScale.z);

    object.updateMatrix();
    object.updateMatrixWorld();
  }
};

export const setObjectEffects = (
  object: THREE.Object3D,
  opacity: number,
  flash: boolean
) => {
  if (object) {
    object.traverse((item) => {
      if (item && item instanceof THREE.Mesh) {
        if (opacity < 1) {
          item.material = item.userData
            .originalTransparentMaterial as THREE.MeshStandardMaterial;
        } else {
          item.material = item.userData
            .originalMaterial as THREE.MeshStandardMaterial;
        }

        if (item.material) {
          let origOpacity = 1;
          if (Array.isArray(item.material)) {
            item.material.forEach((submaterial, idx) => {
              if (
                item.userData.originalMaterial &&
                Array.isArray(item.userData.originalMaterial) &&
                item.userData.originalMaterial[idx]
              ) {
                origOpacity = item.userData.originalMaterial[idx].opacity;
                submaterial.color = item.userData.originalMaterial[idx].color;
              }
              submaterial.opacity = opacity * origOpacity;

              if (flash && opacity < 1) {
                (submaterial as THREE.MeshStandardMaterial).color =
                  flashEffectColor;
              }
            });
          } else {
            if (item.userData.originalMaterial) {
              origOpacity = item.userData.originalMaterial.opacity;
              (item.material as THREE.MeshStandardMaterial).color =
                item.userData.originalMaterial.color;
            }
            item.material.opacity = opacity;

            if (flash && opacity < 1) {
              (item.material as THREE.MeshStandardMaterial).color =
                flashEffectColor;
            }
          }
        }
      }
    });
  }
};

export const setObjectsToStates = (
  scene: THREE.Scene,
  objectStates: ObjectState[],
  applyEffects: boolean = true
) => {
  if (objectStates) {
    objectStates.forEach((state) => {
      const object = getObjectByNodeId(scene, state.nodeId);
      if (object) {
        setObjectWorldMatrix(object, state.worldMatrix);
        if (applyEffects) {
          setObjectEffects(object, state.opacity, false);
        }
      }
    });
  }
};

export const disposeObject = (object: Object3D | THREE.Mesh) => {
  if (object) {
    if (object.parent) {
      object.parent.remove(object);
      if (object instanceof THREE.Mesh) {
        object.geometry.dispose();
        if (object.material instanceof THREE.Material) {
          object.material.dispose();
        } else {
          while (object.material) {
            let mat = object.material.pop();
            if (mat) {
              mat.dispose();
            }
          }
        }
      }
    }
  }
};

export const modelIdToNodeId = (modelId: number): number => {
  return modelId + 10000000;
};

export const nodeIdToModelId = (nodeId: number): number => {
  return nodeId - 10000000;
};

export const indexTrim = (str: string, ch: string) => {
  let start = 0,
    end = str.length;

  while (start < end && str[start] === ch) ++start;

  while (end > start && str[end - 1] === ch) --end;

  return start > 0 || end < str.length ? str.substring(start, end) : str;
};

export const objectMaxSize = (object: Object3D) => {
  const boundingBox = new THREE.Box3();
  boundingBox.setFromObject(object);
  const size3d = boundingBox.getSize(new THREE.Vector3());
  const maxSize = Math.max(size3d.x, size3d.y, size3d.z);
  return maxSize;
};
