import React, { Component, MouseEvent } from 'react';
import { Collapse, MenuItem, Menu, InputBase } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { useSpring, animated } from 'react-spring/web.cjs';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import classnames from 'classnames';

import {
  CrossedOutEyeIcon,
  ExpandIcon,
  EyeIcon,
  MinusSquare,
  PlusSquare,
} from '../../images/icons';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import styles from './styles.module.scss';
import { SceneElementType } from '../../types/default-objects';
import { nodeIdToModelId } from '../../buzzcommon/utils/BuzzArUtils';
import {
  MetadataSet,
  MetadataSets,
  SceneElement,
  Tool,
} from '../../buzzcommon';
import AddIcon from '@material-ui/icons/Add';
import {
  AddToGalleryModal,
  ConfirmationModal,
  NodeMetadataModal,
} from '../modals';
import { StylizedScrollbars, StylizedFab } from '../stylized';
import AbsoluteLoader from '../stylized/AbsoluteLoader/AbsoluteLoader';

const TransitionComponent = (props: TransitionProps) => {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
};

const StyledTreeItem = (props: TreeItemProps) => (
  <TreeItem
    {...props}
    TransitionComponent={TransitionComponent}
    classes={{
      content: styles.content,
      iconContainer: styles.iconContainer,
      group: styles.group,
      label: styles.label,
      selected: styles.selected,
    }}
  />
);

interface Props {
  anchorNode?: number;
  scene: SceneElement;
  className?: string;
  metadata: MetadataSets;
  projectName: string;
  isProjectChanged: boolean;
  isEditorLoading: boolean;
  onSelect: (nodeIds: number[]) => void;
  splitMeshes: (nodeId: number) => void;
  onMetadataModalOpen: (type?: SceneElementType, nodeId?: number) => void;
  removeModelRequest: (modelId: number) => Promise<void>;
  setObjectVisibility: (element: SceneElement, visible: boolean) => void;
  setNodeName: (element: SceneElement, name: string) => void;
  addModelToGallery: (nodeId: number, tool: Tool) => Promise<Tool>;
  getSnapshotByNodeId: (nodeId: number) => string | undefined;
  uploadGalleryItemThumb: (toolId: string, file: File) => Promise<Tool>;
  onChangeMetadataSet: (nodeId: number, metadataSet: MetadataSet) => void;
  onUploadModalOpen: () => void;
  renameProject: (name: string) => void;
}

type State = {
  expanded: string[];
  selected: string[];
  isOpen: boolean;
  isConfirmationModalOpen: boolean;
  contextMenu?: {
    type: SceneElementType;
    anchorEl: EventTarget & Element;
  };
  isExpanded: boolean;
  isAddToGalleryModalOpen: boolean;
  isNodeMetadataModalOpen: boolean;
  isNodeRenameMode: boolean;
  nameInput: string;
  isProjectRenameMode: boolean;
  projectName: string;
  isModelDeletingModalOpen: boolean;
  isLoading: boolean;
};

class ObjectExplorer extends Component<Props, State> {
  state: State = {
    expanded: [],
    selected: [],
    isOpen: true,
    isConfirmationModalOpen: false,
    isAddToGalleryModalOpen: false,
    isNodeMetadataModalOpen: false,
    isExpanded: false,
    isNodeRenameMode: false,
    isProjectRenameMode: false,
    nameInput: '',
    projectName: this.props.projectName,
    isModelDeletingModalOpen: false,
    isLoading: false,
  };

  selectedObject?: SceneElement;
  selectedNodeType?: SceneElementType;

  defaultExpanded: string[] = [];

  menuItems: { [name: string]: { title: string; action: () => void } } = {
    deleteModel: {
      title: 'Delete',
      action: () => {
        this.setState({
          contextMenu: undefined,
          isModelDeletingModalOpen: true,
        });
      },
    },
    add: {
      title: 'Add to Gallery',
      action: () =>
        this.setState({
          contextMenu: undefined,
          isAddToGalleryModalOpen: true,
        }),
    },
    copy: {
      title: 'Copy style',
      action: () => this.closeMenuAfterExecution(() => true),
    },
    split: {
      title: 'Split meshes by materials',
      action: () =>
        this.setState({
          isConfirmationModalOpen: true,
          contextMenu: undefined,
        }),
    },
    changeMetadata: {
      title: 'Change metadata',
      action: () => {
        if (
          this.selectedObject &&
          !this.props.metadata[this.selectedObject.nodeId]
        ) {
          const defaultNodeMetadata: MetadataSet = {
            visible: 'true',
            visibleAR: 'true',
            occlusion: 'false',
          };

          this.props.onChangeMetadataSet(
            this.selectedObject.nodeId,
            defaultNodeMetadata
          );
        }

        this.setState({
          contextMenu: undefined,
          isNodeMetadataModalOpen: true,
        });
      },
    },
    renameNode: {
      title: 'Rename',
      action: () => {
        if (!this.selectedObject) return;

        this.setState({
          isNodeRenameMode: true,
          contextMenu: undefined,
          nameInput: this.selectedObject.name,
        });
      },
    },
  };

  menuGroups: { [groupName: string]: { title: string; action: () => void }[] } =
    {
      model: [
        this.menuItems.add,
        this.menuItems.split,
        this.menuItems.deleteModel,
        this.menuItems.changeMetadata,
        this.menuItems.renameNode,
      ],
      mesh: [
        this.menuItems.copy,
        this.menuItems.changeMetadata,
        this.menuItems.renameNode,
      ],
    };

  componentDidMount() {
    window.addEventListener('selectObject3D', this.onObjectSelected, false);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.projectName !== prevProps.projectName) {
      this.setState({ projectName: this.props.projectName });
    }
  }

  onAddingModalClose = () => {
    this.selectedObject = undefined;
    this.selectedNodeType = undefined;

    this.setState({ isAddToGalleryModalOpen: false });
  };

  componentWillUnmount() {
    window.removeEventListener('selectObject3D', this.onObjectSelected, false);
  }

  onObjectSelected = (event: Event) => {
    const detail = (event as CustomEvent).detail;
    this.setState({
      expanded: detail.parentIds?.map((p: any) => p.toString()),
      selected: detail.selectedObjectIds.map((soId: number) => soId.toString()),
    });
  };

  handleSplitMeshes = () => {
    if (!this.selectedObject) return false;
    this.setState({ isLoading: true, isConfirmationModalOpen: false });
    // this.props.splitMeshes(this.selectedId);
    this.setState({ isLoading: false });
    return true;
  };

  onModelDelete = () => {
    if (!this.selectedObject) return;

    this.setState({ isLoading: true, isModelDeletingModalOpen: false });

    this.props.removeModelRequest(nodeIdToModelId(this.selectedObject.nodeId));

    this.selectedObject = undefined;
    this.selectedNodeType = undefined;
    this.setState({ isLoading: false });
  };

  handleOnContextMenu = (
    e: MouseEvent,
    element: SceneElement,
    type: SceneElementType
  ) => {
    e.preventDefault();
    e.stopPropagation();

    if (!type) return;

    this.selectedObject = element;
    this.selectedNodeType = type;
    this.setState({ contextMenu: { anchorEl: e.currentTarget, type } });
  };

  handleOnChangeVisibility = (
    e: MouseEvent,
    element: SceneElement,
    visible: boolean
  ) => {
    e.preventDefault();
    e.stopPropagation();

    this.props.setObjectVisibility(element, visible);
  };

  getElementType = (childLevel: number, elementType: string) => {
    if (childLevel === 0) return SceneElementType.collection;

    if (childLevel === 1 && elementType === 'Group')
      return SceneElementType.model;

    return SceneElementType.mesh;
  };

  handleSelect = (event: MouseEvent<HTMLParagraphElement>, nodeId: string) => {
    event.stopPropagation();

    const isCtrlPressed = event.ctrlKey;
    let selected: string[];
    const ids = new Array<number>();

    if (isCtrlPressed) {
      selected = this.state.selected;

      if (this.state.selected.includes(nodeId)) {
        selected = selected.filter((id: string) => id !== nodeId);
      } else {
        selected.push(nodeId);
      }
    } else {
      selected = [nodeId];
    }

    selected.forEach((id: string) => ids.push(parseInt(id, 10)));
    this.props.onSelect(ids);
    this.setState({ selected });
  };

  closeMenuAfterExecution = (actionToConfirm: () => boolean) => {
    if (!actionToConfirm()) return;

    this.onContextMenuClose();
  };

  onContextMenuClose = () => {
    this.selectedObject = undefined;
    this.setState({ contextMenu: undefined });
  };

  onChangeNodeName = (element: SceneElement, name: string) => {
    this.props.setNodeName(element, name);
    this.setState({ isNodeRenameMode: false, nameInput: '' });
  };

  onNodeNameKeyPressed = (
    e: React.KeyboardEvent<HTMLDivElement>,
    element: SceneElement,
    name: string
  ) => {
    if (e.key === 'Enter') {
      this.onChangeNodeName(element, name);
    }
  };

  renameProject = () => {
    this.setState({ isProjectRenameMode: false });
    this.props.renameProject(this.state.projectName);
  };

  getContextMenuItems = (type: SceneElementType) => {
    const items = this.menuGroups[type];

    if (!items) return;

    return items.map((item, index) => {
      return (
        <MenuItem
          onClick={item.action}
          classes={{ root: styles.menuItem }}
          key={index}
        >
          {item.title}
        </MenuItem>
      );
    });
  };

  renderContextMenu = () => {
    if (!this.state.contextMenu) return;

    const { anchorEl, type } = this.state.contextMenu;

    const menuItems = this.getContextMenuItems(type);
    if (!menuItems) return;

    return (
      <Menu
        open={!!this.state.contextMenu}
        onClose={this.onContextMenuClose}
        anchorEl={anchorEl}
        classes={{ paper: styles.menu }}
      >
        {menuItems}
      </Menu>
    );
  };

  renderItemLabel = (element: SceneElement, type: SceneElementType) => {
    const nodeMetadata = this.props.metadata[element.nodeId];
    const isVisible =
      !nodeMetadata || !nodeMetadata.visible || nodeMetadata.visible === 'true';
    const nodeName =
      nodeMetadata && nodeMetadata.name ? nodeMetadata.name : element.name;

    return (
      <>
        {this.state.isNodeRenameMode &&
        this.selectedObject?.id === element.id ? (
          <InputBase
            classes={{ input: styles.nodeNameInput }}
            defaultValue={nodeName}
            autoFocus={true}
            onBlur={() => this.onChangeNodeName(element, this.state.nameInput)}
            onKeyPress={(e) =>
              this.onNodeNameKeyPressed(e, element, this.state.nameInput)
            }
            value={this.state.nameInput}
            onChange={(e) => this.setState({ nameInput: e.target.value })}
          />
        ) : (
          <p
            onContextMenu={(e) => this.handleOnContextMenu(e, element, type)}
            onClick={
              type !== SceneElementType.collection
                ? (e) => this.handleSelect(e, element.id.toString())
                : undefined
            }
            className={classnames({ [styles.isInvisible]: !isVisible })}
          >
            {nodeName}
          </p>
        )}

        {type !== SceneElementType.collection && (
          <div className={styles.icons}>
            {isVisible ? (
              <CrossedOutEyeIcon
                className={styles.icon}
                onClick={(e) =>
                  this.handleOnChangeVisibility(e, element, false)
                }
              />
            ) : (
              <EyeIcon
                className={styles.icon}
                onClick={(e) => this.handleOnChangeVisibility(e, element, true)}
              />
            )}
            <MoreHorizIcon
              className={styles.icon}
              onClick={(e) => this.handleOnContextMenu(e, element, type)}
            />
          </div>
        )}
      </>
    );
  };

  renderChildren(element: SceneElement, childLevel: number) {
    if (!['Group', 'Scene', 'Mesh'].includes(element.type) || !element.name)
      return undefined;

    const oeNodeId = element.id.toString();
    const elementType = this.getElementType(childLevel, element.type);

    // Scan only groups and scene. Don't touch meshes, because they must have own wrappers.
    if (element.children.length) {
      if (childLevel < 2) this.defaultExpanded.push(oeNodeId);

      if (childLevel === 0) {
        return element?.children.map((item, index) => (
          <div key={index}>{this.renderChildren(item, childLevel + 1)}</div>
        ));
      }

      return (
        <StyledTreeItem
          className={styles.notMeshes}
          nodeId={oeNodeId}
          label={this.renderItemLabel(element, elementType)}
          key={oeNodeId}
          onContextMenu={(e) =>
            this.handleOnContextMenu(e, element, elementType)
          }
          classes={{
            content: styles.content,
            label: styles.label,
            iconContainer: styles.iconContainer,
            selected: styles.selected,
          }}
        >
          {element?.children.map((item) =>
            this.renderChildren(item, childLevel + 1)
          )}
        </StyledTreeItem>
      );
    }

    return (
      <div>
        <StyledTreeItem
          className={classnames(styles.meshes, {
            [styles.selected]: this.state.selected.includes(oeNodeId),
          })}
          nodeId={oeNodeId}
          label={this.renderItemLabel(element, elementType)}
          key={oeNodeId}
          onContextMenu={(e) =>
            this.handleOnContextMenu(e, element, elementType)
          }
          classes={{
            content: styles.content,
            label: styles.label,
            iconContainer: styles.iconContainer,
            selected: styles.selected,
          }}
        />
      </div>
    );
  }

  renderObjectTree = () => {
    return (
      <div
        className={classnames(
          styles.TreeViewWrap,
          {
            [styles.open]: this.state.isOpen,
          },
          this.props.className
        )}
      >
        <StylizedScrollbars disabledHorizontal={true}>
          <div className={styles.treeView}>
            <TreeView
              defaultCollapseIcon={<MinusSquare />}
              defaultEndIcon={<MinusSquare />}
              defaultExpandIcon={<PlusSquare />}
              multiSelect={true}
              onNodeToggle={(e, nodeIds: string[]) =>
                this.setState({ expanded: nodeIds })
              }
              expanded={
                this.state.expanded.length
                  ? this.state.expanded
                  : this.defaultExpanded
              }
              selected={this.state.selected}
            >
              {this.renderChildren(this.props.scene, 0)}
            </TreeView>
          </div>
        </StylizedScrollbars>

        <StylizedFab
          onClick={this.props.onUploadModalOpen}
          size="small"
          className={styles.addIcon}
          tooltipTitle="Add model"
        >
          <AddIcon />
        </StylizedFab>

        {this.state.isConfirmationModalOpen && (
          <ConfirmationModal
            open={this.state.isConfirmationModalOpen}
            message="It will split the model into single material meshes. This action cannot be undone"
            onClose={() => this.setState({ isConfirmationModalOpen: false })}
            onSubmit={this.handleSplitMeshes}
          />
        )}

        {this.state.isModelDeletingModalOpen && (
          <ConfirmationModal
            open={this.state.isModelDeletingModalOpen}
            message="It will delete the model. This action cannot be undone"
            onClose={() => this.setState({ isModelDeletingModalOpen: false })}
            onSubmit={this.onModelDelete}
          />
        )}
        {this.renderContextMenu()}
      </div>
    );
  };

  render() {
    return (
      <>
        <div
          className={classnames(styles.objectExplorer, {
            [styles.isExpanded]: this.state.isExpanded,
          })}
        >
          {this.state.isLoading ||
            (this.props.isEditorLoading && <AbsoluteLoader />)}
          <div className={styles.explorerHeader}>
            <ExpandIcon
              onClick={() =>
                this.setState({ isExpanded: !this.state.isExpanded })
              }
            />
            {this.state.isProjectRenameMode ? (
              <InputBase
                className={styles.projectInput}
                value={this.state.projectName}
                autoFocus={true}
                onChange={(e) => this.setState({ projectName: e.target.value })}
                onBlur={(e) => this.renameProject()}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') this.renameProject();
                }}
              />
            ) : (
              <p
                onDoubleClick={
                  this.state.isExpanded
                    ? (e) => this.setState({ isProjectRenameMode: true })
                    : undefined
                }
              >
                {`${this.props.isProjectChanged ? '*' : ''} ${
                  this.state.projectName
                }`}
              </p>
            )}
          </div>

          {this.state.isExpanded && this.renderObjectTree()}
        </div>

        {this.state.isAddToGalleryModalOpen && this.selectedObject && (
          <AddToGalleryModal
            object={this.selectedObject}
            open={this.state.isAddToGalleryModalOpen}
            onClose={this.onAddingModalClose}
            addModelToGallery={this.props.addModelToGallery}
            uploadGalleryItemThumb={this.props.uploadGalleryItemThumb}
            getSnapshotByNodeId={this.props.getSnapshotByNodeId}
          />
        )}

        {this.state.isNodeMetadataModalOpen &&
          this.selectedObject &&
          this.props.metadata[this.selectedObject.nodeId] && (
            <NodeMetadataModal
              open={this.state.isNodeMetadataModalOpen}
              onClose={() => this.setState({ isNodeMetadataModalOpen: false })}
              node={this.selectedObject}
              inputMetadata={this.props.metadata[this.selectedObject.nodeId]}
              onChangeMetadataSet={this.props.onChangeMetadataSet}
            />
          )}
      </>
    );
  }
}

export default ObjectExplorer;
