import React, { Component, createRef } from 'react';
import clone from 'clone';
import * as THREE from 'three';
import deepEqual from 'deep-equal';
import numeral from 'numeral';
import { createStyles, Theme, withStyles } from '@material-ui/core';
import { Button, WithStyles } from '@material-ui/core';
import classnames from 'classnames';

import {
  createStep,
  createSubstep,
  removeAction,
  removeStep,
  removeSubStep,
  saveAction,
} from '../services/editor-engine/utils/project-utils';
import { EditorEngine } from '../services/editor-engine/EditorEngine';
import Materials from '../components/materials';
import {
  defaultAction,
  PointerModes,
  SceneElementType,
} from '../types/default-objects';
import EditorToolbar from '../components/editor-toolbar';
import ScenarioSteps from '../components/scenario-steps';
import ObjectExplorer from '../components/object-explorer';
import ActionPage from '../components/action-page';
import StepPage from '../components/step-page';
import SubstepPage from '../components/substep-page';
import {
  createMaterialPreview,
  getObjectByNodeId,
  nodeIdToModelId,
  snapshotObject,
  urlToFile,
} from '../buzzcommon/utils/BuzzArUtils';
import {
  AnimationBundleInfo,
  ArMaterial,
  ArMaterials,
  ArModel,
  ArProjectStep,
  ArProjectSubstep,
  ArScenarioProject,
  CategorizedTools,
  changedActionParam,
  CommonMaterials,
  ExtendedTool,
  IActiveAction,
  MetadataSet,
  SceneElement,
  SubstepAction,
  Tool,
} from '../buzzcommon';
import Tools from '../components/tools';

import styles from './styles.module.scss';
import cursorPlus from '../images/cursor-plus.svg';
import cursorMinus from '../images/cursor-minus.svg';
import PauseIcon from '@material-ui/icons/Pause';
import { PlayIcon, SaveIcon } from '../images/icons';
import { UploadModal, MetadataModal } from '../components/modals';
import { isCloud } from '../../../api/configure';

numeral.defaultFormat('0,000');

type CursorMode = 'normal' | 'plus' | 'minus';

const defaultGallerySnapshotCanvasWidth = 1024;
const defaultGallerySnapshotCanvasHeight = 1024;

const editorClasses = (theme: Theme) =>
  createStyles({
    cursorNormal: {
      cursor: `auto`,
    },
    cursorPlus: {
      cursor: `url(${cursorPlus}), auto`,
    },
    cursorMinus: {
      cursor: `url(${cursorMinus}), auto`,
    },
  });

interface IProps extends WithStyles<typeof editorClasses> {
  project: ArScenarioProject;
  categorizedTools: CategorizedTools;
  commonMaterials?: CommonMaterials;
  isProjectChanged: boolean;
  setIsProjectChanged: (isChanged: boolean) => void;
  getFbxUrl: (uniqueId: string) => string;
  saveProjectRequest: (
    project: ArScenarioProject,
    isEditFinished: boolean
  ) => Promise<ArScenarioProject>;
  addNewModelRequest: (modelFile: File) => Promise<any>;
  getProjectRequest: () => Promise<void>;
  postScenarioFile: (file: File) => Promise<any>;
  formScenarioFileUrl: (fileName: string) => string;
  editorRef: React.Ref<LightEditor>;
  removeModelRequest: (modelId: number) => Promise<void>;
  getGalleryItemThumb: (toolId: string) => Promise<File>;
  getGalleryItemFbx: (toolId: string) => Promise<File>;
  createGalleryItem: (tool: Tool) => Promise<Tool>;
  updateGalleryItem: (tool: Tool) => Promise<Tool>;
  uploadGalleryItemFbx: (toolId: string, file: File) => Promise<Tool>;
  uploadGalleryItemThumb: (toolId: string, file: File) => Promise<Tool>;
  addGalleryItemToProject: (toolId: string) => Promise<ArModel>;
  getGalleryItemFbxUrl: (toolId: string) => string;
  addModelToGallery: (modelId: number, tool: Tool) => Promise<Tool>;
  deleteGalleryItem: (toolId: string) => Promise<void>;
  updateProjectTitle: (name: string) => void;
}

type IState = {
  project: ArScenarioProject;
  scene?: THREE.Scene;
  cursorMode: CursorMode;
  cursorClass?: string;
  selectedStep?: ArProjectStep;
  selectedSubstep?: ArProjectSubstep;
  activeAction?: IActiveAction;
  isAnimationPlaying: boolean;
  isAdvancedMode: boolean;
  isLoading: boolean;
  isEditingFinished: boolean; //for unload events, to check if request was sent
  isUploadModalOpen: boolean;
  isStepInfoOpen: boolean;
  isNewAction: boolean;
  selectedObjects: number[];
  isToolsOpen: boolean;
  isMaterialEditMode: boolean;
  isProjectMaterialsLoading: boolean;
  metadataModal?: {
    elementType: 'global' | 'model' | 'mesh';
    metadata: MetadataSet;
    nodeId?: number;
  };
  categorizedTools: CategorizedTools;
  draggedTool?: Tool;
  projectMaterials: ArMaterials;
};

class LightEditor extends Component<IProps, IState> {
  private editorEngine: EditorEngine;
  private editorRef = createRef<HTMLDivElement>();
  private thumbnailSceneRef = createRef<HTMLDivElement>();
  private toolThumbSceneRef = createRef<HTMLDivElement>();
  private projectMaterials: ArMaterials = {};

  state: IState = {
    isLoading: true,
    isAdvancedMode: false,
    isAnimationPlaying: false,
    cursorMode: 'normal',
    cursorClass: this.props.classes?.cursorNormal,
    project: this.props.project,
    isUploadModalOpen: false,
    isStepInfoOpen: false,
    isNewAction: false,
    isEditingFinished: false,
    selectedObjects: [],
    isToolsOpen: false,
    isMaterialEditMode: false,
    isProjectMaterialsLoading: true,
    categorizedTools: this.props.categorizedTools,
    projectMaterials: {},
  };

  constructor(props: Readonly<IProps>) {
    super(props);
    this.editorEngine = new EditorEngine();

    if (typeof props.editorRef === 'function') {
      props.editorRef(this);
    } else if (props.editorRef) {
      (props.editorRef as React.MutableRefObject<LightEditor>).current = this;
    }
  }

  onUnload = (event: BeforeUnloadEvent) => {
    if (!this.state.isEditingFinished) {
      this.props.saveProjectRequest(this.state.project, true);
      this.setState({ isEditingFinished: true });
    }
    event.returnValue = 'Are you sure you want to exit?';
  };

  onCtrlDown = () => {
    if (this.state.cursorMode === 'normal') {
      this.editorEngine.setMultiselect(true);
      this.setState({
        cursorClass: this.props.classes?.cursorPlus,
        cursorMode: 'plus',
      });
    }
  };

  onCtrlUp = () => {
    if (this.state.cursorMode !== 'normal') {
      this.editorEngine.setMultiselect(false);
      this.setState({
        cursorClass: this.props.classes?.cursorNormal,
        cursorMode: 'normal',
      });
    }
  };

  componentDidMount() {
    this.sceneSetup();
    this.visualizeProject();
    const onCtrlDown = this.onCtrlDown;
    const onCtrlUp = this.onCtrlUp;

    if (isCloud) {
      window.addEventListener('beforeunload', this.onUnload);
      window.addEventListener('onunload', this.onUnload);
    }

    this.editorEngine?.threeRootElement?.addEventListener(
      'keydown',
      (event) => {
        if (event.keyCode === 17) {
          event.preventDefault();
          onCtrlDown();
        }
      },
      false
    );
    this.editorEngine?.threeRootElement?.addEventListener(
      'keyup',
      (event) => {
        if (event.keyCode === 17) {
          event.preventDefault();
          onCtrlUp();
        }
      },
      false
    );

    window.addEventListener('selectObject3D', this.onSelectObject3D, false);

    window.addEventListener(
      'resize',
      this.editorEngine.resizeCanvasToDisplaySize
    );
    window.addEventListener('actionChanged', this.onEngineActionChanged, false);
    window.addEventListener(
      'animationBundleFinished',
      this.onSubStepAnimationFinished,
      false
    );

    if (!this.state.project.globalMetadata.project_name) {
      this.renameProject('Project');
    } else {
      this.props.updateProjectTitle(
        this.state.project.globalMetadata.project_name
      );
    }
  }

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (this.props.project !== prevProps.project) {
      this.setState({
        project: this.props.project,
        projectMaterials: this.editorEngine.getProjectArMaterials(),
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      this.editorEngine.resizeCanvasToDisplaySize
    );
    window.removeEventListener(
      'actionChanged',
      this.onEngineActionChanged,
      false
    );
    window.removeEventListener(
      'animationBundleFinished',
      this.onSubStepAnimationFinished,
      false
    );

    if (isCloud) {
      window.removeEventListener('beforeunload', this.onUnload);
      window.removeEventListener('onunload', this.onUnload);
    }

    window.removeEventListener('selectObject3D', this.onSelectObject3D, false);
  }

  getIndexes = () => {
    if (!this.state.selectedStep)
      return { stepIndex: undefined, substepIndex: undefined };

    const stepIndex = this.state.project.steps.findIndex(
      (step) =>
        this.state.selectedStep && step.uuid === this.state.selectedStep.uuid
    );

    if (stepIndex === 0) return { stepIndex: 0, substepIndex: 0 };

    if (!this.state.selectedSubstep)
      return { stepIndex, substepIndex: undefined };

    const substepIndex = this.state.selectedStep.substeps.findIndex(
      (substep) =>
        this.state.selectedSubstep &&
        substep.uuid === this.state.selectedSubstep.uuid
    );

    return {
      stepIndex,
      substepIndex: substepIndex === -1 ? undefined : substepIndex,
    };
  };

  onSelectObject3D = (event: Event) => {
    const detail: {
      id: number;
      selectedObjectIds: number[];
      parentIds: number[];
    } = (event as CustomEvent).detail;

    this.onObjectsSelected(detail.selectedObjectIds);
  };

  updateThumbnailInTools = async () => {
    const categorizedTools = { ...this.state.categorizedTools };

    await Promise.all(
      Object.entries(categorizedTools).map(
        async ([category, toolsInCategory]) => {
          const tools = [...toolsInCategory];

          tools.forEach(async (tool, index) => {
            let thumbUrl: string = '';

            try {
              if (tool.thumb) {
                const file = await this.props.getGalleryItemThumb(
                  tool.uniqueId
                );
                thumbUrl = URL.createObjectURL(file);
              } else {
                const snapshotUrl = await this.getSnapshotByToolId(
                  tool.uniqueId
                );

                if (!snapshotUrl) {
                  throw Error('Unable to create a snapshot from fbx file');
                }

                await this.uploadToolThumb(tool.uniqueId, thumbUrl);
                thumbUrl = snapshotUrl;
              }
            } catch (e) {
              // TODO: handle error
              console.log(e);
            }

            tools[index] = { ...tool, thumbUrl };
          });

          categorizedTools[category] = tools;
        }
      )
    );

    this.setState({ categorizedTools });
  };

  addThumbnailToProjectMaterials = (materialsKeys: string[]) => {
    let iteration = 0;
    const amount = 3;

    materialsKeys.forEach((key) => {
      if (iteration >= amount || !this.projectMaterials[key]) return;
      if (!this.projectMaterials[key].thumbnail) {
        this.projectMaterials[key].thumbnail = createMaterialPreview(
          this.projectMaterials[key]
        );
      }
      iteration += 1;
    });

    if (!materialsKeys || !materialsKeys.length) {
      this.setState({
        isProjectMaterialsLoading: false,
        projectMaterials: this.projectMaterials,
      });

      this.projectMaterials = {};
    } else {
      materialsKeys.splice(0, amount);
      setTimeout(() => this.addThumbnailToProjectMaterials(materialsKeys));
    }
  };

  addThumbnailInProjectMaterials = async () => {
    this.setState({ isProjectMaterialsLoading: true });
    const projMaterialsInitial = Object.keys(this.state.projectMaterials);

    this.projectMaterials = { ...this.state.projectMaterials };
    this.addThumbnailToProjectMaterials(projMaterialsInitial);
  };

  addGalleryItemToProject = async (toolId: string) => {
    try {
      const model = await this.props.addGalleryItemToProject(toolId);

      const project = { ...this.state.project };
      project.arModels = [...project.arModels, model];

      const scene = await this.editorEngine.loadModels(
        this.props.getFbxUrl,
        [model],
        this.onModelsLoaded
      );

      this.props.setIsProjectChanged(true);
      this.setState({ project, scene });
    } catch (e) {
      // TODO: handle error
      console.log(e);
    }
  };

  onDropTool = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const tool = this.state.draggedTool;
    if (!tool) return;

    this.addGalleryItemToProject(tool.uniqueId);

    this.editorEngine.setToolToDnd(undefined);
  };

  getSnapshotByNodeId = (nodeId: number) => {
    if (!this.toolThumbSceneRef.current || !this.state.scene) return;
    const object3d = getObjectByNodeId(this.state.scene, nodeId);

    if (!object3d) return;
    return snapshotObject(
      this.toolThumbSceneRef.current,
      object3d,
      defaultGallerySnapshotCanvasWidth,
      defaultGallerySnapshotCanvasHeight
    );
  };

  getSnapshotByToolId = async (toolId: string) => {
    if (!this.toolThumbSceneRef.current) return;

    const filePath = this.props.getGalleryItemFbxUrl(toolId);
    const object3d = await this.editorEngine.fbxToObject3d(filePath);

    return snapshotObject(
      this.toolThumbSceneRef.current,
      object3d,
      defaultGallerySnapshotCanvasWidth,
      defaultGallerySnapshotCanvasHeight
    );
  };

  sceneSetup = () => {
    const scene = this.editorEngine.sceneSetup();
    this.setState({ scene });
    this.editorEngine.startAnimationLoop();
  };

  visualizeProject = async () => {
    const scene = await this.editorEngine.loadModels(
      this.props.getFbxUrl,
      this.state.project.arModels,
      this.onModelsLoaded
    );
    this.setState({ scene });
  };

  onModelsLoaded = (scene: THREE.Scene, fbxModels: THREE.Object3D[]) => {
    fbxModels.forEach((fbxModel: THREE.Object3D) => {
      // TODO: export fbxModel;
    });

    this.editorEngine.applyGlobalMetadataOnScene();
    this.editorEngine.applyNodesMetadataOnScene();
    this.editorEngine.applyProjectMaterialsOnScene();
    this.updateThumbnailInTools();

    this.setState({
      scene,
      isLoading: false,
      projectMaterials: this.editorEngine.getProjectArMaterials(),
    });
  };

  // possible feature. saved for later
  // onModelExport = async () => {
  //   this.setState({ isLoading: true });
  //   try {
  //     await Promise.all(
  //       this.state.project.arModels.map(async (arModel: ArModel) => {
  //         const fbxObject = this.editorEngine.exportModel(arModel.id);
  //         if (fbxObject) {
  //           await this.props.saveModelRequest(fbxObject);
  //         }
  //       })
  //     );
  //     this.setState({ isLoading: false });
  //   } catch (error) {
  //     this.setState({ isLoading: false });
  //   }
  // };

  onProjectSave = async () => {
    this.setState({ isLoading: true });

    let isSuccessful = false;
    try {
      await this.props.saveProjectRequest(this.state.project, false);
      isSuccessful = true;
    } catch (ex) {
      console.log(ex);
    }

    this.props.setIsProjectChanged(
      this.props.isProjectChanged && !isSuccessful
    );
    this.setState({ isLoading: false });

    return isSuccessful;
  };

  onModelAdd = async (modelFile: File) => {
    if (!this.state.scene) return;

    this.setState({ isLoading: true, isUploadModalOpen: false });
    let responseModel: ArModel;
    const project = { ...this.state.project };
    let scene: THREE.Scene | undefined = { ...this.state.scene } as THREE.Scene;
    let isProjectChanged = this.props.isProjectChanged;

    try {
      responseModel = await this.props.addNewModelRequest(modelFile);

      project.arModels = [...project.arModels, responseModel];

      scene = await this.editorEngine.loadModels(
        this.props.getFbxUrl,
        [responseModel],
        this.onModelsLoaded
      );

      isProjectChanged = true;
      return responseModel;
    } finally {
      this.props.setIsProjectChanged(isProjectChanged);

      this.setState({
        isLoading: false,
        project,
        scene,
      });
    }
  };

  onModelRemove = async (modelId: number) => {
    await this.props.removeModelRequest(modelId);
    const scene = this.editorEngine.deleteModelFromScene(modelId);

    this.setState({ scene, projectMaterials: {} });
  };

  onScenarioFileAdd = async (file: File) => {
    this.setState({ isLoading: true });
    let response: Promise<any>;

    try {
      response = await this.props.postScenarioFile(file);

      return response;
    } finally {
      this.setState({ isLoading: false });
    }
  };

  onStepCreate = (name: string) => {
    this.setState((prevState) => {
      this.props.setIsProjectChanged(true);

      return {
        ...createStep({ ...prevState.project }, name),
        isStepInfoOpen: true,
      };
    });

    this.editorEngine.updateObjectStatesFromSubstep(0, 0);
  };

  changeSelectedStep = (name: string, description: string) => {
    const { stepIndex } = this.getIndexes();

    if (!this.state.selectedStep || stepIndex === undefined) return;

    const selectedStep = { ...this.state.selectedStep, name, description };
    const project = { ...this.state.project };

    project.steps[stepIndex] = selectedStep;

    this.props.setIsProjectChanged(true);
    this.setState({ project, selectedStep });
  };

  createSubStep = (subStepName: string, stepId: number, subStepId?: number) => {
    this.setState((prevState) => {
      this.props.setIsProjectChanged(true);

      return {
        ...createSubstep(
          { ...prevState.project },
          subStepName,
          stepId,
          subStepId
        ),
        isStepInfoOpen: true,
      };
    });

    this.editorEngine.updateObjectStatesFromSubstep(0, 0);
  };

  deleteSubStep = (substepIndex: number) => {
    const { stepIndex } = this.getIndexes();

    if (!this.state.selectedStep || stepIndex === undefined) return;

    const { project, selectedStep } = removeSubStep(
      { ...this.state.project },
      stepIndex,
      substepIndex
    );

    this.props.setIsProjectChanged(true);
    this.setState({
      project,
      selectedStep,
      selectedSubstep: undefined,
    });

    this.editorEngine.updateObjectStatesFromSubstep(0, 0);
  };

  deleteStep = (id: number) => {
    const { project, selectedStep, selectedSubstep } = removeStep(
      { ...this.state.project },
      id
    );

    this.props.setIsProjectChanged(true);
    this.setState({
      project,
      selectedStep,
      selectedSubstep,
      isStepInfoOpen: false,
    });

    this.editorEngine.updateObjectStatesFromSubstep(0, 0);
  };

  setSubsteps = (stepIndex: number, substeps: ArProjectSubstep[]) => {
    const project = { ...this.state.project };
    project.steps[stepIndex].substeps = [...substeps];
    this.setState({ project });
  };

  onSubStepSelected = (
    selectedStepIndex: number,
    selectedSubstepIndex?: number
  ) => {
    const selectedStep = { ...this.state.project.steps[selectedStepIndex] };
    let selectedSubstep: ArProjectSubstep | undefined;

    if (selectedSubstepIndex !== undefined) {
      selectedSubstep = { ...selectedStep.substeps[selectedSubstepIndex] };
    }

    this.setState({
      selectedStep,
      selectedSubstep,
      activeAction: undefined,
      isAdvancedMode: false,
      isStepInfoOpen: true,
      isMaterialEditMode: false,
    });
    this.editorEngine.finishEditAction(false);

    if (selectedStepIndex != null && selectedSubstepIndex != null) {
      this.editorEngine.onSubstepSelected(
        selectedStepIndex,
        selectedSubstepIndex
      );
    }
  };

  reorderSubSteps = (
    stepId: number,
    newSubStepIndex: number | undefined,
    newSubSteps: ArProjectSubstep[]
  ) => {
    if (newSubStepIndex === undefined) return;

    this.props.setIsProjectChanged(true);
    this.setState((prevState) => {
      const project = { ...prevState.project };
      project.steps[stepId].substeps = [...newSubSteps];

      return {
        project,
        selectedStep: { ...project.steps[stepId] },
        selectedSubstep: { ...newSubSteps[newSubStepIndex] },
        activeAction: undefined,
        isAdvancedMode: false,
      };
    });
    this.editorEngine.updateObjectStatesFromSubstep(0, 0);
    this.editorEngine.onSubstepSelected(stepId, newSubStepIndex);
  };

  onSaveAction = () => {
    if (!this.state.activeAction) return;

    if (this.hasActionChanges()) {
      this.setState((prevState) => {
        const { project, activeAction } = prevState;
        const { stepIndex, substepIndex } = this.getIndexes();

        if (
          !activeAction ||
          stepIndex === undefined ||
          substepIndex === undefined
        )
          return { ...prevState };

        this.props.setIsProjectChanged(true);
        return {
          ...saveAction({ ...project }, activeAction, stepIndex, substepIndex),
        };
      });
    }

    this.editorEngine.finishEditAction(true);
    this.setState({ activeAction: undefined, isAdvancedMode: false });
  };

  onObjectsSelected = (nodeIds: number[]) => {
    this.setState((prevState) => {
      let activeAction = prevState.activeAction;

      if (prevState.activeAction)
        activeAction = {
          ...prevState.activeAction,
          nodeIds: this.editorEngine.idsToNodeIds(nodeIds),
        };

      return { activeAction, selectedObjects: [...nodeIds] };
    });

    this.editorEngine.updateSelectionGroup(nodeIds);
  };

  hasActionChanges = (): boolean => {
    const { activeAction, selectedSubstep, project } = this.state;
    const { stepIndex, substepIndex } = this.getIndexes();

    if (
      !activeAction ||
      !selectedSubstep ||
      stepIndex === undefined ||
      substepIndex === undefined
    )
      return false;

    if (!selectedSubstep.actions[activeAction.id]) return true;

    const expectedAction = {
      ...project.steps[stepIndex].substeps[substepIndex].actions[
        activeAction.id
      ],
      id: activeAction.id,
    };
    return !deepEqual(activeAction, expectedAction);
  };

  createAction = () => {
    const { stepIndex, substepIndex } = this.getIndexes();

    if (
      !this.state.selectedStep ||
      stepIndex === undefined ||
      substepIndex === undefined
    )
      return;

    let selectedSubstep: ArProjectSubstep | undefined;

    if (this.state.selectedSubstep) {
      selectedSubstep = { ...this.state.selectedSubstep };
    } else if (stepIndex === 0) {
      selectedSubstep = { ...this.state.selectedStep.substeps[0] };
    } else {
      return;
    }

    const newActionId =
      selectedSubstep && selectedSubstep.actions
        ? selectedSubstep.actions.length
        : 0;
    const activeAction: IActiveAction = {
      id: newActionId,
      ...defaultAction,
    };

    this.setState({ selectedSubstep, activeAction, isNewAction: true });

    this.editorEngine.editAction(
      stepIndex,
      substepIndex,
      clone<SubstepAction>(activeAction),
      newActionId
    );
  };

  deleteAction = (actionIndex: number) => {
    if (actionIndex === this.state.activeAction?.id) {
      this.editorEngine.finishEditAction(false);
    }

    this.setState((prevState) => {
      const { stepIndex, substepIndex } = this.getIndexes();

      if (
        !prevState.selectedStep ||
        stepIndex === undefined ||
        substepIndex === undefined
      )
        return { ...prevState };

      this.props.setIsProjectChanged(true);

      return {
        isAdvancedMode: false,
        activeAction: undefined,
        ...removeAction(
          { ...prevState.project },
          stepIndex,
          substepIndex,
          actionIndex
        ),
      };
    });
  };

  selectAction = (id: number) => {
    const { stepIndex, substepIndex } = this.getIndexes();

    if (
      !this.state.selectedStep ||
      !this.state.selectedSubstep ||
      stepIndex === undefined ||
      substepIndex === undefined
    )
      return;

    const activeAction: IActiveAction = {
      ...this.state.selectedSubstep.actions[id],
      id,
    };

    this.setState({ activeAction, isNewAction: false });
    this.editorEngine.editAction(
      stepIndex,
      substepIndex,
      clone<SubstepAction>(activeAction),
      id
    );

    this.editorEngine.updateActionFromUi(
      stepIndex,
      substepIndex,
      clone<SubstepAction>(activeAction),
      id,
      'none'
    );
  };

  editSubStep = () => {
    this.setState((prevState) => {
      const project = { ...prevState.project };
      const { stepIndex, substepIndex } = this.getIndexes();

      if (
        !prevState.selectedSubstep ||
        stepIndex === undefined ||
        substepIndex === undefined
      )
        return { project };

      if (project.steps[stepIndex]) {
        project.steps[stepIndex].substeps[substepIndex] = {
          ...project.steps[stepIndex].substeps[substepIndex],
          name: prevState.selectedSubstep.name,
          duration: prevState.selectedSubstep.duration,
          description: prevState.selectedSubstep.description,
        };
      }

      this.props.setIsProjectChanged(true);
      return { project };
    });
  };

  onUpdateActionFromUi = (changeParam: changedActionParam) => {
    const { stepIndex, substepIndex } = this.getIndexes();

    if (
      !this.state.activeAction ||
      stepIndex === undefined ||
      substepIndex === undefined
    )
      return;

    this.editorEngine.updateActionFromUi(
      stepIndex,
      substepIndex,
      clone<SubstepAction>(this.state.activeAction),
      this.state.activeAction?.id,
      changeParam
    );
  };

  onChangeCenter = (changeParam: 'moveCenter' | 'resetCenter') =>
    this.onUpdateActionFromUi(changeParam);

  onResetAxis = (changeParam: 'resetAxisX' | 'resetAxisY' | 'resetAxisZ') =>
    this.onUpdateActionFromUi(changeParam);

  onMoveAxis = (changeParam: 'moveAxisStart' | 'moveAxisEnd') =>
    this.onUpdateActionFromUi(changeParam);

  onEngineActionChanged = (event: Event) => {
    const detail = (event as CustomEvent).detail;
    this.setState((prevState: IState) => {
      if (!prevState.activeAction) return { activeAction: undefined };

      const activeAction = clone<IActiveAction>({
        id: prevState.activeAction.id,
        ...detail.action,
      });
      return { activeAction };
    });
  };

  onActionChange = (actionParam: 'actionType' | 'effects', value: string) => {
    this.setState((prevState: IState) => {
      const { stepIndex, substepIndex } = this.getIndexes();

      if (
        !prevState.activeAction ||
        stepIndex === undefined ||
        substepIndex === undefined
      )
        return { activeAction: undefined };

      const activeAction = { ...prevState.activeAction };

      switch (actionParam) {
        case 'actionType': {
          activeAction.actionType = value;
          break;
        }
        case 'effects': {
          if (activeAction.effects.length) {
            activeAction.effects[0].type = value;
          } else {
            activeAction.effects[0] = {
              type: value,
              actionParams: new Map<string, string>(),
            };
          }
          break;
        }
        default:
          return { activeAction };
      }

      this.editorEngine.updateActionFromUi(
        stepIndex,
        substepIndex,
        clone<SubstepAction>(activeAction),
        activeAction.id,
        actionParam
      );

      return { activeAction };
    });
  };

  onActionTransformChange = (
    transformParam: 'axisRotation' | 'screwMove' | 'screwTurns',
    changedValue: number
  ) => {
    const { stepIndex, substepIndex } = this.getIndexes();

    if (
      !this.state.activeAction?.transform ||
      stepIndex === undefined ||
      substepIndex === undefined
    )
      return;

    const activeAction = { ...this.state.activeAction };

    const value =
      transformParam === 'axisRotation'
        ? (changedValue - 18) * 10
        : changedValue;

    activeAction.transform[transformParam] = value;

    this.editorEngine.updateActionFromUi(
      stepIndex,
      substepIndex,
      clone<SubstepAction>(activeAction),
      activeAction.id,
      'transform'
    );

    this.setState({ activeAction });
  };

  onActiveActionChange = (
    newActiveAction: IActiveAction,
    changedParam: changedActionParam
  ) => {
    const { stepIndex, substepIndex } = this.getIndexes();

    if (
      !this.state.selectedStep ||
      stepIndex === undefined ||
      substepIndex === undefined
    )
      return;

    let selectedSubstep: ArProjectSubstep;
    if (this.state.selectedSubstep) {
      selectedSubstep = { ...this.state.selectedSubstep };
    } else if (stepIndex === 0) {
      selectedSubstep = { ...this.state.selectedStep.substeps[0] };
    } else {
      return;
    }

    this.setState({
      activeAction: { ...newActiveAction },
      selectedSubstep: stepIndex !== 0 ? selectedSubstep : undefined,
    });

    this.editorEngine.updateActionFromUi(
      stepIndex,
      substepIndex,
      clone<SubstepAction>(newActiveAction),
      newActiveAction.id,
      changedParam
    );
  };

  onCancelActionEditing = () => {
    this.editorEngine.finishEditAction(false);
    this.setState((prevState) => {
      const { stepIndex, substepIndex } = this.getIndexes();
      if (
        !prevState.selectedStep ||
        stepIndex === undefined ||
        substepIndex === undefined
      )
        return { ...prevState };

      return {
        isAdvancedMode: false,
        activeAction: undefined,
        selectedSubstep:
          stepIndex !== 0 ? prevState.selectedSubstep : undefined,
      };
    });
  };

  onPlayAnimation = () => {
    if (this.state.isAnimationPlaying) return;

    this.setState({ isAnimationPlaying: true });

    const { stepIndex, substepIndex } = this.getIndexes();
    if (stepIndex != null && substepIndex != null) {
      this.editorEngine.startSubstepAnimation(
        stepIndex,
        substepIndex,
        Date.now()
      );
    } else {
      if (
        this.state.project?.steps[1] &&
        this.state.project?.steps[1].substeps[0]
      ) {
        const selectedStep = { ...this.state.project.steps[1] };
        const selectedSubstep = { ...selectedStep.substeps[0] };
        this.setState({ selectedStep, selectedSubstep });
      }
      this.editorEngine.startSubstepAnimation(0, 0, Date.now());
    }
  };

  onPauseAnimation = () => {
    this.setState({ isAnimationPlaying: false });
  };

  playNext = (stepId: number, substepId: number) => {
    if (this.state.isAnimationPlaying && this.state.project.steps[stepId]) {
      if (this.state.project.steps[stepId].substeps[substepId + 1]) {
        const selectedStep = { ...this.state.project.steps[stepId] };
        const selectedSubstep = { ...selectedStep.substeps[substepId + 1] };

        this.setState({ selectedSubstep, selectedStep });
        this.editorEngine.startSubstepAnimation(
          stepId,
          substepId + 1,
          Date.now()
        );
        return;
      } else if (
        this.state.project.steps[stepId + 1] &&
        this.state.project.steps[stepId + 1].substeps[0]
      ) {
        const selectedStep = { ...this.state.project.steps[stepId + 1] };
        const selectedSubstep = { ...selectedStep.substeps[0] };

        this.setState({ selectedSubstep, selectedStep });
        this.editorEngine.startSubstepAnimation(stepId + 1, 0, Date.now());
        return;
      }
    }
    this.setState({ isAnimationPlaying: false });
  };

  onSubStepAnimationFinished = (event: Event) => {
    const detail = (event as CustomEvent).detail;
    const animationBundleInfo =
      detail.animationBundleInfo as AnimationBundleInfo;
    this.playNext(animationBundleInfo.stepId, animationBundleInfo.substepId);
  };

  onResize = () => {
    this.editorEngine.resizeCanvasToDisplaySize();
  };

  onCanvasClick = (event: React.MouseEvent) => {
    if (event.ctrlKey) {
      this.onCtrlDown();
    }
    this.editorEngine.onCanvasClick(event);
  };

  onPlayClick = () => {
    if (this.state.isAnimationPlaying) {
      this.onPauseAnimation();
    } else {
      this.onPlayAnimation();
    }
  };

  onSetInitialPosition = () => {
    const { stepIndex, substepIndex } = this.getIndexes();

    if (
      stepIndex === undefined ||
      substepIndex === undefined ||
      !this.state.activeAction
    )
      return;

    this.editorEngine.updateActionFromUi(
      stepIndex,
      substepIndex,
      this.state.activeAction,
      this.state.activeAction.id,
      'init'
    );
  };

  updateSubstepInfo = (name: string, duration: number, description: string) => {
    const { stepIndex, substepIndex } = this.getIndexes();

    if (
      !this.state.selectedSubstep ||
      stepIndex === undefined ||
      substepIndex === undefined
    )
      return;

    const project = { ...this.state.project };
    const updatedSubstep = {
      ...this.state.selectedSubstep,
      name,
      duration,
      description,
    };

    project.steps[stepIndex].substeps[substepIndex] = { ...updatedSubstep };

    this.props.setIsProjectChanged(true);
    this.setState({ project });
  };

  onMetadataModalOpen = (type?: SceneElementType, nodeId?: number) => {
    let elementType: 'global' | 'model' | 'mesh' = 'global';
    let metadata: MetadataSet = this.state.project.globalMetadata;

    if (type && nodeId) {
      elementType = type === SceneElementType.model ? 'model' : 'mesh';
      metadata = this.state.project.metadata[nodeId] ?? {};
    }

    this.setState({
      metadataModal: {
        elementType,
        metadata,
        nodeId,
      },
    });
  };

  renameProject = (name: string) => {
    if (this.state.project.globalMetadata.project_name === name) return;

    this.onChangeMetadata('project_name', name);
    this.props.updateProjectTitle(name);
  };

  onChangeMetadata = (fieldName: string, value: string) => {
    const project = { ...this.state.project };

    let metadata: MetadataSet;

    if (this.state.metadataModal && this.state.metadataModal.nodeId) {
      project.metadata[this.state.metadataModal.nodeId] =
        project.metadata[this.state.metadataModal.nodeId] ?? {};
      metadata = project.metadata[this.state.metadataModal.nodeId];
    } else {
      metadata = project.globalMetadata;
    }

    if (!value) {
      delete metadata[fieldName];
    } else {
      metadata[fieldName] = value;
    }

    if (this.state.metadataModal && this.state.metadataModal.nodeId) {
      this.editorEngine.updateNodeMetadata(
        metadata,
        this.state.metadataModal.nodeId
      );
    } else {
      this.editorEngine.updateGlobalMetadata(metadata);

      if (fieldName === 'project_name') {
        this.props.updateProjectTitle(value);
      }
    }

    this.props.setIsProjectChanged(true);
    this.setState({ project });
  };

  onChangeMetadataSet = (nodeId: number, metadataSet: MetadataSet) => {
    const project = { ...this.state.project };

    project.metadata[nodeId] = {
      ...project.metadata[nodeId],
      ...metadataSet,
    };

    this.editorEngine.updateNodeMetadata(project.metadata[nodeId], nodeId);

    this.props.setIsProjectChanged(true);
    this.setState({ project });
  };

  onSetObjectVisibility = (element: SceneElement, visible: boolean) => {
    this.editorEngine.setObjectVisibility(element.id, visible);
    const project = { ...this.state.project };

    project.metadata[element.nodeId] = {
      ...project.metadata[element.nodeId],
      visible: visible.toString(),
    };

    this.props.setIsProjectChanged(true);
    this.setState({ project });
  };

  onChangeNodeName = (element: SceneElement, name: string) => {
    const project = { ...this.state.project };

    project.metadata[element.nodeId] = {
      ...project.metadata[element.nodeId],
      name,
    };

    this.props.setIsProjectChanged(true);
    this.setState({ project });
  };

  onMaterialEditModeChange = (isMaterialEditMode: boolean) => {
    this.editorEngine.setPointerMode(
      isMaterialEditMode ? PointerModes.disabled : PointerModes.select
    );

    this.setState({
      isMaterialEditMode,
      isToolsOpen: false,
    });
  };

  renderStepInfo = () => {
    const { stepIndex, substepIndex } = this.getIndexes();

    if (
      !this.state.isStepInfoOpen ||
      !this.state.selectedStep ||
      stepIndex === undefined
    )
      return;

    if (this.state.activeAction)
      return (
        <ActionPage
          isNew={this.state.isNewAction}
          action={this.state.activeAction}
          onActiveActionChange={this.onActiveActionChange}
          onChangeCenter={this.onChangeCenter}
          onResetAxis={this.onResetAxis}
          onInitialPositionSet={this.onSetInitialPosition}
          saveAction={this.onSaveAction}
          onClose={() => this.setState({ isStepInfoOpen: false })}
          onActionChange={this.onActionChange}
          onActionTransformChange={this.onActionTransformChange}
          onCancelActionEditing={this.onCancelActionEditing}
          onMoveAxis={this.onMoveAxis}
        />
      );

    if (
      stepIndex !== 0 &&
      this.state.selectedSubstep &&
      substepIndex !== undefined
    )
      return (
        <SubstepPage
          step={this.state.selectedStep}
          substep={this.state.selectedSubstep}
          substepIndex={substepIndex}
          onClose={() => this.setState({ isStepInfoOpen: false })}
          deleteSubstep={this.deleteSubStep}
          createAction={this.createAction}
          deleteAction={this.deleteAction}
          selectAction={this.selectAction}
          updateSubstepInfo={this.updateSubstepInfo}
          onStepSelect={() => this.onSubStepSelected(stepIndex)}
        />
      );

    return (
      <StepPage
        step={this.state.selectedStep}
        stepIndex={stepIndex}
        onClose={() => this.setState({ isStepInfoOpen: false })}
        onSubStepCreate={this.createSubStep}
        onSubstepSelect={this.onSubStepSelected}
        deleteSubstep={this.deleteSubStep}
        deleteStep={this.deleteStep}
        createAction={this.createAction}
        deleteAction={this.deleteAction}
        selectAction={this.selectAction}
        onStepChange={this.changeSelectedStep}
        postScenarioFile={this.onScenarioFileAdd}
        formScenarioFileUrl={this.props.formScenarioFileUrl}
      />
    );
  };

  setMaterialToProject = (arMaterial: ArMaterial) => {
    const project =
      this.editorEngine.materialEditorMaterialSelected(arMaterial);
    if (project) {
      this.props.setIsProjectChanged(true);
      this.setState({ project });
    }
  };

  uploadToolThumb = async (toolId: string, thumbUrl: string) => {
    const file = await urlToFile(thumbUrl, `${toolId}.jpeg`, 'image/jpeg');

    this.props
      .uploadGalleryItemThumb(toolId, file)
      .catch((e) =>
        console.log(
          '🚀 ~ file: index.tsx ~ line 268 ~ LightEditor ~ tools.forEach ~ error',
          e
        )
      );
  };

  onModelToGalleryAdd = async (nodeId: number, tool: Tool) => {
    const modelId = nodeIdToModelId(nodeId);
    const newTool = await this.props.addModelToGallery(modelId, tool);
    const thumbUrl = await this.getSnapshotByToolId(newTool.uniqueId);

    if (!thumbUrl) {
      throw Error('Unable to create a snapshot from fbx file');
    }

    await this.uploadToolThumb(newTool.uniqueId, thumbUrl);

    const extendedTool: ExtendedTool = { ...newTool, thumbUrl };
    const categorizedTools = { ...this.state.categorizedTools };

    if (categorizedTools[tool.categoryName]) {
      categorizedTools[tool.categoryName].push(extendedTool);
    } else {
      categorizedTools[tool.categoryName] = [extendedTool];
    }

    this.setState({ categorizedTools });
    return extendedTool;
  };

  changeTool = async (oldTool: ExtendedTool, newTool: ExtendedTool) => {
    const updatedTool = await this.props.updateGalleryItem(newTool);

    const tool: ExtendedTool = { ...updatedTool, thumbUrl: newTool.thumbUrl };

    let updatedCategory = [
      ...this.state.categorizedTools[updatedTool.categoryName],
    ];

    if (updatedCategory && updatedCategory.length) {
      const index = updatedCategory.indexOf(oldTool);
      updatedCategory[index] = tool;
    } else {
      updatedCategory = [tool];
    }

    const categorizedTools: CategorizedTools = {
      ...this.state.categorizedTools,
      [updatedTool.categoryName]: updatedCategory,
    };

    this.setState({ categorizedTools });
    return updatedTool;
  };

  deleteTool = async (tool: ExtendedTool) => {
    await this.props.deleteGalleryItem(tool.uniqueId);

    const categorizedTools = { ...this.state.categorizedTools };

    if (categorizedTools[tool.categoryName]) {
      if (categorizedTools[tool.categoryName].length === 1) {
        delete categorizedTools[tool.categoryName];
      } else {
        const index = categorizedTools[tool.categoryName].indexOf(tool);
        categorizedTools[tool.categoryName].splice(index, 1);
      }
    }

    this.setState({ categorizedTools });
  };

  render() {
    const { classes } = this.props;
    const { stepIndex, substepIndex } = this.getIndexes();

    if (!classes) return;

    return (
      <div className={styles.lightEditor}>
        <div className={styles.editorWrap} ref={this.editorRef}>
          <div
            className={classnames(styles.canvas3d, this.state.cursorClass)}
            ref={(element: HTMLDivElement) => {
              this.editorEngine.init(element, this.state.project);
            }}
            onClick={this.onCanvasClick}
            onMouseDown={this.editorEngine.onCanvasMouseDown}
            draggable={true}
            onDrop={this.onDropTool}
            onDragOver={(e) => {
              e.preventDefault();
              e.stopPropagation();

              this.editorEngine.onCanvasDragOver(e);
            }}
          />
        </div>

        {this.editorEngine.sceneStructure && (
          <div className={styles.controlPanel}>
            <div className={styles.body}>
              <div className={styles.main}>
                <EditorToolbar
                  activeAction={this.state.activeAction}
                  onWireframeDisable={this.editorEngine.disableWireframeMode}
                  onWireframeEnable={this.editorEngine.enableWireframeMode}
                  onAxesHelperEnable={this.editorEngine.enableAxisHelper}
                  onGridHelperEnable={this.editorEngine.enableGridHelper}
                  onPointerModeChanged={this.editorEngine.setPointerMode}
                  onToggleSkyBox={this.editorEngine.enableSkybox}
                  onMetadataModalOpen={this.onMetadataModalOpen}
                />

                <div className={styles.projectSettings}>
                  <div className={styles.objectExplorer}>
                    <ObjectExplorer
                      className={classnames({
                        [styles.disabled]: this.state.isMaterialEditMode,
                      })}
                      scene={this.editorEngine.sceneStructure}
                      onSelect={this.onObjectsSelected}
                      anchorNode={
                        this.state.activeAction?.transform.anchorNodeId
                      }
                      splitMeshes={this.editorEngine.splitNodeByMaterial}
                      isProjectChanged={this.props.isProjectChanged}
                      projectName={
                        this.state.project.globalMetadata.project_name
                      }
                      metadata={this.state.project.metadata}
                      onMetadataModalOpen={this.onMetadataModalOpen}
                      removeModelRequest={this.onModelRemove}
                      setObjectVisibility={this.onSetObjectVisibility}
                      getSnapshotByNodeId={this.getSnapshotByNodeId}
                      addModelToGallery={this.onModelToGalleryAdd}
                      uploadGalleryItemThumb={this.props.uploadGalleryItemThumb}
                      onChangeMetadataSet={this.onChangeMetadataSet}
                      onUploadModalOpen={() =>
                        this.setState({ isUploadModalOpen: true })
                      }
                      setNodeName={this.onChangeNodeName}
                      renameProject={this.renameProject}
                      isEditorLoading={this.state.isLoading}
                    />
                  </div>

                  <div className={styles.right}>
                    <div className={styles.tabs}>
                      <div
                        className={classnames(styles.materials, {
                          [styles.disabled]: !this.state.selectedObjects.length,
                        })}
                      >
                        <Materials
                          isProjectMaterialsLoading={
                            this.state.isProjectMaterialsLoading
                          }
                          selectedObjects={this.state.selectedObjects}
                          projectMaterials={this.state.projectMaterials}
                          commonMaterials={this.props.commonMaterials}
                          thumbnailSceneRef={this.thumbnailSceneRef}
                          isMaterialEditMode={this.state.isMaterialEditMode}
                          getSelectedMaterials={
                            this.editorEngine.getProjectSelectedArMaterials
                          }
                          onMaterialSelect={this.setMaterialToProject}
                          setIsMaterialEditMode={this.onMaterialEditModeChange}
                          addThumbnailInProjectMaterials={
                            this.addThumbnailInProjectMaterials
                          }
                        />
                      </div>

                      <div className={styles.toolsSelect}>
                        <Tools
                          isToolsOpen={this.state.isToolsOpen}
                          categorizedTools={this.state.categorizedTools}
                          addToolToProject={this.addGalleryItemToProject}
                          setIsToolsOpen={(isToolsOpen: boolean) =>
                            this.setState({
                              isMaterialEditMode: false,
                              isToolsOpen,
                            })
                          }
                          deleteTool={this.deleteTool}
                          changeTool={this.changeTool}
                          setToolToDnd={(tool?: Tool) => {
                            this.setState({ draggedTool: tool });
                            this.editorEngine.setToolToDnd(tool);
                          }}
                        />
                      </div>
                    </div>
                    <div className={styles.bottomButtons}>
                      <Button
                        className={styles.playAnimation}
                        disableElevation={true}
                        onClick={this.onPlayClick}
                      >
                        {this.state.isAnimationPlaying ? (
                          <div
                            className={classnames(
                              styles.buttonContent,
                              styles.pause
                            )}
                          >
                            <PauseIcon className={styles.icon} />
                            <p>Pause animation</p>
                          </div>
                        ) : (
                          <div
                            className={classnames(
                              styles.buttonContent,
                              styles.play
                            )}
                          >
                            <PlayIcon className={styles.icon} />
                            <p>Play animation</p>
                          </div>
                        )}
                      </Button>

                      <Button
                        className={styles.save}
                        disableElevation={true}
                        onClick={this.onProjectSave}
                      >
                        <SaveIcon className={styles.icon} />
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className={classnames(styles.animationInfo, {
                  [styles.open]: this.state.isStepInfoOpen,
                })}
              >
                {this.renderStepInfo()}
              </div>
            </div>

            <div className={styles.scenarioStepsWrap}>
              <ScenarioSteps
                stepId={stepIndex}
                subStepId={substepIndex}
                steps={this.state.project.steps}
                onSelect={this.onSubStepSelected}
                isEditActionState={!!this.state.activeAction}
                isAnimationPlaying={this.state.isAnimationPlaying}
                onStepCreate={this.onStepCreate}
                onSubStepCreate={this.createSubStep}
                reorderSubSteps={this.reorderSubSteps}
                setSubsteps={this.setSubsteps}
              />
            </div>
          </div>
        )}

        {this.state.isUploadModalOpen && (
          <UploadModal
            open={this.state.isUploadModalOpen}
            onModelUpload={this.onModelAdd}
            onClose={() => this.setState({ isUploadModalOpen: false })}
            onProjectSave={this.onProjectSave}
          />
        )}

        {this.state.metadataModal && (
          <MetadataModal
            open={!!this.state.metadataModal}
            onClose={() => this.setState({ metadataModal: undefined })}
            inputMetadata={this.state.metadataModal.metadata}
            metadataType={this.state.metadataModal.elementType}
            onChangeMetadata={this.onChangeMetadata}
          />
        )}
        <div
          className={styles.ThumbnailSceneWrap}
          ref={this.thumbnailSceneRef}
        />

        <div
          className={styles.ToolThumbSceneWrap}
          ref={this.toolThumbSceneRef}
        />
      </div>
    );
  }
}

export { LightEditor };

export default withStyles(editorClasses)(LightEditor);
