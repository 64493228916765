import { InputBase, InputBaseProps } from '@material-ui/core';
import classnames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

const StylizedInput = (props: InputBaseProps & { inputClass?: string }) => {
  const { className, value, ...rest } = props;
  return (
    <InputBase
      {...rest}
      value={value ?? ''}
      classes={{
        root: props.className,
        input: classnames(styles.input, props.inputClass),
      }}
    />
  );
};

export default StylizedInput;
