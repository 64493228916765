import classnames from 'classnames';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { MetadataSet, MetadataFields } from '../../../buzzcommon';
import { globalMetadata, groupMetadata, meshMetadata } from '../../../metadata';

import {
  StylizedSelect,
  StylizedInput,
  StylizedModal,
  StylizedScrollbars,
  StylizedButton,
} from '../../stylized';

import styles from './styles.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  metadataType: 'global' | 'model' | 'mesh';
  inputMetadata: MetadataSet;
  onChangeMetadata: (fieldName: string, value: string) => void;
  nodeId?: string;
}

interface ExtendedMetadataFields {
  [fieldName: string]: ExtendedMetadataField;
}

interface ExtendedMetadataField {
  uiName: string;
  internalName: string;
  required: boolean;
  options?: string[];
  value: string;
}

const MetadataModal = (props: Props) => {
  const { open, onClose, metadataType, inputMetadata, onChangeMetadata } =
    props;

  const [metadata, setMetadata] = useState<ExtendedMetadataFields>();
  const [isRequiredError, setIsRequiredError] = useState(false);

  useEffect(() => {
    let fields: MetadataFields = {};

    switch (metadataType) {
      case 'global': {
        fields = { ...globalMetadata };
        break;
      }
      case 'model': {
        fields = { ...groupMetadata };
        break;
      }
      case 'mesh': {
        fields = { ...meshMetadata };
        break;
      }
    }

    const extendedMetadataSet: ExtendedMetadataFields = {};

    Object.values(fields).forEach((element) => {
      extendedMetadataSet[element.internalName] = {
        ...element,
        value: inputMetadata[element.internalName],
      };
    });

    setMetadata(extendedMetadataSet);
  }, [inputMetadata]);

  const onSelectChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    fieldName: string
  ) => {
    if (!metadata) return;

    onChangeMetadata(fieldName, event.target.value as string);

    const newMetadataSet = {
      ...metadata[fieldName],
      value: event.target.value as string,
    };

    setMetadata({ ...metadata, [fieldName]: newMetadataSet });
  };

  const onInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    fieldName: string
  ) => {
    if (!metadata) return;

    const newMetadataSet = {
      ...metadata[fieldName],
      value: event.target.value as string,
    };

    setMetadata({ ...metadata, [fieldName]: newMetadataSet });
  };

  const handlerClose = () => {
    if (!metadata) return;

    if (Object.values(metadata).some((met) => !met.value && met.required)) {
      setIsRequiredError(true);
      return;
    }

    onClose();
  };

  const renderFields = () => {
    if (!metadata) return;

    return (
      <>
        {Object.values(metadata).map((field, index) => {
          return renderField(field, index);
        })}
      </>
    );
  };

  const renderField = (field: ExtendedMetadataField, index: number) => {
    if (!metadata) return;

    const isError = isRequiredError && field.required && !field.value;

    return (
      <div className={styles.metadataField} key={index}>
        <div className={styles.uiNameWrapper}>
          <p>{field.uiName}</p>
        </div>

        {field.options ? (
          <StylizedSelect
            className={classnames({ [styles.errorInput]: isError })}
            options={field.options}
            required={field.required}
            value={metadata[field.internalName].value}
            onChange={(e) => onSelectChange(e, field.internalName)}
          />
        ) : (
          <StylizedInput
            inputClass={classnames({ [styles.errorInput]: isError })}
            required={field.required}
            value={metadata[field.internalName].value}
            onChange={(e) => onInputChange(e, field.internalName)}
            onBlur={(e) =>
              onChangeMetadata(
                field.internalName,
                metadata[field.internalName].value
              )
            }
          />
        )}
      </div>
    );
  };

  return (
    <StylizedModal
      open={open}
      onClose={handlerClose}
      title={`${metadataType} metadata`}
    >
      <div className={styles.modalBody}>
        <div className={styles.scrollbarsWrapper}>
          <StylizedScrollbars disabledHorizontal={true}>
            <div className={styles.fields}>{renderFields()}</div>
          </StylizedScrollbars>
        </div>

        <StylizedButton
          colorType="secondary"
          className={styles.closeButton}
          onClick={handlerClose}
        >
          Close
        </StylizedButton>
      </div>
    </StylizedModal>
  );
};

export default MetadataModal;
