import React from 'react';
import { Checkbox } from '@material-ui/core';

import styles from './styles.module.scss';
import { SceneElement, MetadataSet } from '../../../buzzcommon';
import { StylizedModal, StylizedSwitch, StylizedButton } from '../../stylized';

interface Props {
  open: boolean;
  node: SceneElement;
  onClose: () => void;
  inputMetadata: MetadataSet;
  onChangeMetadataSet: (nodeId: number, metadataSet: MetadataSet) => void;
}

const NodeMetadataModal = (props: Props) => {
  const { open, node, inputMetadata, onClose, onChangeMetadataSet } = props;

  const onMetadataSetChange = (field: string, value: string) => {
    const metadata: MetadataSet = {
      ...inputMetadata,
      [field]: value,
    };

    onChangeMetadataSet(node.nodeId, metadata);
  };

  return (
    <StylizedModal
      open={open}
      onClose={onClose}
      className={styles.nodeMetadataModal}
      title={`${node.name} metadata`}
    >
      <div className={styles.modalBody}>
        <div className={styles.fields}>
          <div className={styles.input}>
            <Checkbox
              checked={inputMetadata.occlusion === 'true'}
              onChange={(e) =>
                onMetadataSetChange('occlusion', String(e.target.checked))
              }
              classes={{ root: styles.checkbox }}
            />
            <div>
              This node is an invisible object to hide objects behind it
              (occlusion)
            </div>
          </div>

          <div className={styles.switchInputs}>
            <div className={styles.input}>
              <StylizedSwitch
                checked={inputMetadata.visibleAR === 'true'}
                onChange={(e) =>
                  onMetadataSetChange('visibleAR', String(e.target.checked))
                }
              />
              <div>Visible in AR view</div>
            </div>

            <div className={styles.input}>
              <StylizedSwitch
                checked={inputMetadata.visible === 'true'}
                onChange={(e) =>
                  onMetadataSetChange('visible', String(e.target.checked))
                }
              />
              <div>Visible in 3D view</div>
            </div>
          </div>
        </div>

        <StylizedButton
          colorType="secondary"
          className={styles.closeButton}
          onClick={onClose}
        >
          Close
        </StylizedButton>
      </div>
    </StylizedModal>
  );
};

export default NodeMetadataModal;
