import { Button, ButtonGroup, CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import classnames from 'classnames';
import Scrollbars, { positionValues } from 'react-custom-scrollbars';

import styles from './styles.module.scss';
import { PackageIcon } from '../../../images/icons';
import { CommonMaterials, ArMaterials, ArMaterial } from '../../../buzzcommon';
import { StylizedModal, StylizedButton, StylizedScrollbars } from '../../stylized';

interface Props {
  open: boolean;
  isCommonMaterialsLoaded: boolean;
  commonMaterials?: CommonMaterials;
  projectMaterials: ArMaterials;
  onClose: () => void;
  onMaterialSelect: (material: ArMaterial) => void;
}

enum TabName {
  Project = 'Project',
  Common = 'Common',
}

const MaterialEditorModal = (props: Props) => {
  const { open, commonMaterials, projectMaterials, onClose, onMaterialSelect } =
    props;

  const [tabName, setTabName] = useState(TabName.Project);
  const [activeFolder, setActiveFolder] = useState('');

  const renderCommonFolders = () => {
    if (!commonMaterials)
      return <div className={styles.noItemsMessage}>No categories</div>;

    return (
      <div className={styles.scrollbarsWrapper}>
        <StylizedScrollbars
          disabledHorizontal={true}
          withScrollGradients={true}
        >
          <div className={styles.folders}>
            {Object.getOwnPropertyNames(commonMaterials).map(
              (categoryName, index) => (
                <div
                  key={index}
                  className={styles.folder}
                  onClick={() => setActiveFolder(categoryName)}
                >
                  <PackageIcon className={styles.packageIcon} />
                  <p className={styles.title}>{categoryName}</p>
                </div>
              )
            )}
          </div>
        </StylizedScrollbars>
      </div>
    );
  };

  const renderMaterials = (materials?: ArMaterials) => {
    if (!materials)
      return <div className={styles.noItemsMessage}>No materials</div>;

    return (
      <div className={styles.scrollbarsWrapper}>
        <StylizedScrollbars
          disabledHorizontal={true}
          withScrollGradients={true}
        >
          <div className={styles.materials}>
            {Object.values(materials).map((material) => (
              <div
                key={material.uuid}
                className={styles.material}
                onClick={() => onMaterialSelect(material)}
              >
                {material.thumbnail && (
                  <img
                    src={material.thumbnail}
                    className={styles.image}
                    alt="thumbnail"
                  />
                )}
              </div>
            ))}
          </div>
        </StylizedScrollbars>
      </div>
    );
  };

  const renderTabContent = () => {
    if (tabName === TabName.Project) {
      return renderMaterials(projectMaterials);
    } else if (props.isCommonMaterialsLoaded) {
      return commonMaterials && activeFolder
        ? renderMaterials(commonMaterials[activeFolder])
        : renderCommonFolders();
    } else {
      return <CircularProgress />;
    }
  };

  const onCommonMaterialsClick = () => {
    setTabName(TabName.Common);
    setActiveFolder('');
  };

  return (
    <StylizedModal open={open} onClose={onClose} title="Material patterns">
      <div className={styles.body}>
        <ButtonGroup className={styles.buttonGroup}>
          <Button
            onClick={() => setTabName(TabName.Project)}
            className={classnames(styles.tabButton, styles.firstButton, {
              [styles.bold]: tabName === TabName.Project,
            })}
          >
            {TabName.Project}
          </Button>
          <Button
            onClick={onCommonMaterialsClick}
            className={classnames(styles.tabButton, styles.lastButton, {
              [styles.bold]: tabName === TabName.Common,
            })}
          >
            {TabName.Common}
          </Button>
        </ButtonGroup>

        <div className={styles.elements}>{renderTabContent()}</div>

        <StylizedButton
          colorType="secondary"
          className={styles.closeButton}
          onClick={onClose}
        >
          Close
        </StylizedButton>
      </div>
    </StylizedModal>
  );
};

export default MaterialEditorModal;
