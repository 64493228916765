import { Button, ButtonProps, Tooltip } from '@material-ui/core';
import React from 'react';
import styles from './styles.module.scss';

interface StylizedButtonProps extends ButtonProps {
  colorType: 'default' | 'secondary' | 'add-button';
  tooltipTitle?: string;
}

const WithTooltip = (props: {
  children: React.ReactElement;
  tooltipTitle?: string;
}) =>
  props.tooltipTitle ? (
    <Tooltip title={props.tooltipTitle}>{props.children}</Tooltip>
  ) : (
    props.children
  );

const StylizedButton = (props: StylizedButtonProps) => {
  const { children, colorType, tooltipTitle, ...rest } = props;

  let buttonStyle: string;

  switch (colorType) {
    case 'secondary': {
      buttonStyle = styles.secondaryButton;
      break;
    }
    case 'add-button': {
      buttonStyle = styles.addButton;
      break;
    }
    case 'default':
    default: {
      buttonStyle = styles.mainButton;
      break;
    }
  }

  return (
    <WithTooltip tooltipTitle={tooltipTitle}>
      <Button {...rest} classes={{ text: buttonStyle, label: styles.label }}>
        {children}
      </Button>
    </WithTooltip>
  );
};

export default StylizedButton;
