import React from 'react';
import { SvgIconProps, SvgIcon } from '@material-ui/core';

export function MinusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

export function PlusSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

export function CloseSquare(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

export function Anchor(props: SvgIconProps) {
  return (
    <SvgIcon
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 204.851 204.851"
    >
      <g>
        <g>
          <g>
            <path
              d="M139.518,128.595l16.834,16.336c0,0-20.644,29.877-42.725,30.473
                        c0.479,0,0.117-84.092,0.039-104.472c14.694-4.797,25.402-18.182,25.402-34.117c0-20.009-16.697-36.218-37.273-36.218
                        c-20.615,0-37.312,16.209-37.312,36.208c0,15.671,10.376,28.929,24.748,33.961l0.098,104.277
                        c-26.643-1.837-42.061-27.474-42.061-27.474l17.997-17.41L0,120.505l9.887,63.301l17.362-16.795
                        c15.036,12.105,32.017,37.244,72.876,37.244c51.332-1.309,63.184-28.939,76.344-39.804l18.993,18.514l9.389-63.907
                        L139.518,128.595z M82.558,36.208c0-10.298,8.608-18.661,19.218-18.661s19.257,8.363,19.257,18.661
                        c0,10.327-8.647,18.681-19.257,18.681S82.558,46.535,82.558,36.208z"
            />
          </g>
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </SvgIcon>
  );
}

export function TrashBin(props: SvgIconProps) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 15.56, height: 20 }} {...props}>
      <path d="M7.778,21.778A2.229,2.229,0,0,0,10,24h8.889a2.229,2.229,0,0,0,2.222-2.222V8.444H7.778V21.778ZM22.222,5.111H18.333L17.222,4H11.667L10.556,5.111H6.667V7.333H22.222V5.111Z" />
    </SvgIcon>
  );
}

export const Toggle = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 12 6.861" {...props}>
      <path
        d="M8.065,5.817,12.6,10.358a.854.854,0,0,0,1.211,0,.865.865,0,0,0,0-1.215L8.672,4A.856.856,0,0,0,7.49,3.974L2.313,9.14a.858.858,0,0,0,1.211,1.215Z"
        transform="translate(-2.063 -3.749)"
      />
    </SvgIcon>
  );
};

export const MaterialIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" {...props}>
      <path
        d="M20.844,2.589l-7.757,7.757-10.5,10.5a2.3,2.3,0,0,0,.567,1,2.3,2.3,0,0,0,1,.567L22.422,4.156a2.225,2.225,0,0,0-1.578-1.567ZM12.367,2.5,2.5,12.367v3.144L15.511,2.5Zm-7.644,0A2.229,2.229,0,0,0,2.5,4.722V6.944L6.944,2.5H4.722Zm15.556,20A2.238,2.238,0,0,0,22.5,20.278V18.056L18.056,22.5Zm-10.789,0h3.144L22.5,12.633V9.489L9.489,22.5Z"
        transform="translate(-2.5 -2.5)"
      />
    </SvgIcon>
  );
};

export const PackageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 100 80" {...props}>
      <path
        d="M46.727,18.75H15.774c-5.239,0-9.524,4.8-9.524,10.667V88.083c0,5.867,4.285,10.667,9.524,10.667H96.726c5.239,0,9.524-4.8,9.524-10.667V41.417c0-5.867-4.285-10.667-9.524-10.667H56.251Z"
        transform="translate(-6.25 -18.75)"
      />
    </SvgIcon>
  );
};

export const BlackDots = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 12 28" {...props}>
      <g id="dots" transform="translate(-825 -1003)">
        <circle
          id="Эллипс_16"
          data-name="Эллипс 16"
          cx="2"
          cy="2"
          r="2"
          transform="translate(825 1027)"
        />
        <circle
          id="Эллипс_21"
          data-name="Эллипс 21"
          cx="2"
          cy="2"
          r="2"
          transform="translate(833 1027)"
        />
        <circle
          id="Эллипс_17"
          data-name="Эллипс 17"
          cx="2"
          cy="2"
          r="2"
          transform="translate(825 1019)"
        />
        <circle
          id="Эллипс_20"
          data-name="Эллипс 20"
          cx="2"
          cy="2"
          r="2"
          transform="translate(833 1019)"
        />
        <circle
          id="Эллипс_18"
          data-name="Эллипс 18"
          cx="2"
          cy="2"
          r="2"
          transform="translate(825 1011)"
        />
        <circle
          id="Эллипс_23"
          data-name="Эллипс 23"
          cx="2"
          cy="2"
          r="2"
          transform="translate(825 1003)"
        />
        <circle
          id="Эллипс_19"
          data-name="Эллипс 19"
          cx="2"
          cy="2"
          r="2"
          transform="translate(833 1011)"
        />
        <circle
          id="Эллипс_22"
          data-name="Эллипс 22"
          cx="2"
          cy="2"
          r="2"
          transform="translate(833 1003)"
        />
      </g>
    </SvgIcon>
  );
};

export const PlayIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 20 32" {...props}>
      <path d="M16,0,32,20H0Z" transform="translate(20) rotate(90)" />
    </SvgIcon>
  );
};

export const SaveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 30 30" {...props}>
      <g id="text" transform="translate(1.5 1.5)">
        <g id="Icon_feather-save" data-name="Icon feather-save" opacity="0.6">
          <path
            id="Контур_29"
            data-name="Контур 29"
            d="M24,27H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H19.5L27,7.5V24A3,3,0,0,1,24,27Z"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Контур_30"
            data-name="Контур 30"
            d="M15,12V0H0V12"
            transform="translate(6 15)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
          <path
            id="Контур_31"
            data-name="Контур 31"
            d="M0,0V7.5H12"
            transform="translate(6)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="3"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export const ArrowIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 28.455 28.455" {...props}>
      <g
        id="Icon_feather-mouse-pointer"
        data-name="Icon feather-mouse-pointer"
        transform="translate(-3 -3)"
      >
        <path
          id="Контур_3"
          data-name="Контур 3"
          d="M4.5,4.5,15.1,29.955,18.87,18.87,29.955,15.1Z"
          fill="none"
          stroke="rgba(255,255,255,0.6)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          id="Контур_4"
          data-name="Контур 4"
          d="M19.5,19.5l9,9"
          fill="none"
          stroke="rgba(255,255,255,0.6)"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </SvgIcon>
  );
};

export const MoveIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 31.55 31.55" {...props}>
      <path
        id="Icon_ionic-ios-move"
        data-name="Icon ionic-ios-move"
        d="M33.462,17.311l-5.583-5.583a.976.976,0,0,0-1.378,0l-.014.014a.976.976,0,0,0,0,1.378l3.86,3.86-11.377.035.035-11.377,3.86,3.86a.976.976,0,0,0,1.378,0l.014-.014a.976.976,0,0,0,0-1.378L18.675,2.524a.976.976,0,0,0-1.378,0L11.714,8.107a.976.976,0,0,0,0,1.378l.014.014a.976.976,0,0,0,1.378,0l3.86-3.86L17,17.016,5.625,16.98l3.86-3.86a.976.976,0,0,0,0-1.378l-.014-.014a.976.976,0,0,0-1.378,0L2.51,17.311a.976.976,0,0,0,0,1.378l5.583,5.583a.976.976,0,0,0,1.378,0l.014-.014a.976.976,0,0,0,0-1.378l-3.86-3.86L17,18.984l-.035,11.377-3.86-3.86a.976.976,0,0,0-1.378,0l-.014.014a.976.976,0,0,0,0,1.378L17.3,33.476a.976.976,0,0,0,1.378,0l5.583-5.583a.976.976,0,0,0,0-1.378l-.014-.014a.976.976,0,0,0-1.378,0l-3.86,3.86-.013-4.233-.022-7.143,11.377.035-3.86,3.86a.976.976,0,0,0,0,1.378l.014.014a.976.976,0,0,0,1.378,0l5.583-5.583A.964.964,0,0,0,33.462,17.311Z"
        transform="translate(-2.225 -2.239)"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export const ThreeDimensionalIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 35.835 36" {...props}>
      <path
        d="M11.28,32.22A15.73,15.73,0,0,1,2.325,19.5H.075A17.991,17.991,0,0,0,18,36l.99-.045L13.275,30.24Zm1.335-9.78a2.5,2.5,0,0,1-.78-.12,1.6,1.6,0,0,1-.6-.36,1.487,1.487,0,0,1-.39-.555,1.79,1.79,0,0,1-.135-.7H8.76a3.07,3.07,0,0,0,.315,1.425,3.316,3.316,0,0,0,.84,1.035,3.789,3.789,0,0,0,1.23.615,4.525,4.525,0,0,0,1.44.225,5.059,5.059,0,0,0,1.545-.225,3.658,3.658,0,0,0,1.245-.66,2.967,2.967,0,0,0,.825-1.08,3.5,3.5,0,0,0,.3-1.455,3.146,3.146,0,0,0-.1-.84,2.5,2.5,0,0,0-.345-.765,2.7,2.7,0,0,0-.6-.645,3.084,3.084,0,0,0-.915-.465,3.146,3.146,0,0,0,.78-.5,3.1,3.1,0,0,0,.555-.63,2.644,2.644,0,0,0,.33-.69,2.391,2.391,0,0,0,.1-.72,3.626,3.626,0,0,0-.27-1.44,2.67,2.67,0,0,0-.765-1.035,3.086,3.086,0,0,0-1.155-.645,5.467,5.467,0,0,0-1.53-.21,4.431,4.431,0,0,0-1.5.24,3.816,3.816,0,0,0-1.185.675,3.372,3.372,0,0,0-.765,1.005,2.989,2.989,0,0,0-.27,1.275h1.95a1.6,1.6,0,0,1,.135-.675,1.41,1.41,0,0,1,.375-.51,1.778,1.778,0,0,1,.57-.33,2.2,2.2,0,0,1,.72-.12,1.72,1.72,0,0,1,1.335.465,1.812,1.812,0,0,1,.435,1.29,2.288,2.288,0,0,1-.12.735,1.3,1.3,0,0,1-.375.555,1.9,1.9,0,0,1-.615.36,2.524,2.524,0,0,1-.87.135H11.25v1.545H12.4a3.37,3.37,0,0,1,.9.1,1.794,1.794,0,0,1,.675.345,1.767,1.767,0,0,1,.435.6,2.126,2.126,0,0,1,.15.855,1.8,1.8,0,0,1-.525,1.4,1.922,1.922,0,0,1-1.425.5ZM25.44,13.56a5,5,0,0,0-1.71-1.155,5.643,5.643,0,0,0-2.19-.4H18V24h3.45a6.088,6.088,0,0,0,2.265-.405,4.943,4.943,0,0,0,1.74-1.14,5.062,5.062,0,0,0,1.11-1.785,6.862,6.862,0,0,0,.39-2.355v-.6a6.862,6.862,0,0,0-.39-2.355,5.241,5.241,0,0,0-1.125-1.8Zm-.585,4.74A6.553,6.553,0,0,1,24.645,20,3.647,3.647,0,0,1,24,21.27a2.8,2.8,0,0,1-1.065.8,3.855,3.855,0,0,1-1.485.27H20.085V13.68H21.54A2.976,2.976,0,0,1,24,14.715a4.568,4.568,0,0,1,.855,2.985v.6ZM18,0l-.99.045L22.725,5.76l2-1.995a15.706,15.706,0,0,1,8.94,12.72h2.25A17.965,17.965,0,0,0,18,0Z"
        transform="translate(-0.075)"
        fill="rgba(255,255,255,0.8)"
      />
    </SvgIcon>
  );
};

export const CrossedOutEyeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 15 12" {...props}>
      <path
        d="M7.5,9.375a3.362,3.362,0,0,1-3.349-3.12l-2.459-1.9a7.812,7.812,0,0,0-.861,1.3.758.758,0,0,0,0,.684A7.517,7.517,0,0,0,7.5,10.5a7.28,7.28,0,0,0,1.826-.245L8.109,9.314a3.378,3.378,0,0,1-.609.061Zm7.355,1.362-2.591-2a7.764,7.764,0,0,0,1.9-2.392.758.758,0,0,0,0-.684A7.517,7.517,0,0,0,7.5,1.5a7.222,7.222,0,0,0-3.453.884L1.065.079A.375.375,0,0,0,.539.145L.079.737a.375.375,0,0,0,.066.526l13.79,10.658a.375.375,0,0,0,.526-.066l.46-.592a.375.375,0,0,0-.066-.526ZM10.549,7.409,9.628,6.7A2.221,2.221,0,0,0,6.907,3.839a1.117,1.117,0,0,1,.218.661,1.093,1.093,0,0,1-.036.234L5.364,3.4A3.335,3.335,0,0,1,7.5,2.625,3.373,3.373,0,0,1,10.875,6a3.3,3.3,0,0,1-.326,1.409Z"
        transform="translate(0 0)"
      />
    </SvgIcon>
  );
};

export const EyeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 24 16" {...props}>
      <path
        d="M23.855,11.392A13.364,13.364,0,0,0,12,4,13.366,13.366,0,0,0,.145,11.392a1.348,1.348,0,0,0,0,1.216A13.364,13.364,0,0,0,12,20a13.366,13.366,0,0,0,11.855-7.392,1.348,1.348,0,0,0,0-1.216ZM12,18a6,6,0,1,1,6-6A6,6,0,0,1,12,18ZM12,8a3.971,3.971,0,0,0-1.055.158,1.994,1.994,0,0,1-2.788,2.788A3.991,3.991,0,1,0,12,8Z"
        transform="translate(0 -4)"
      />
    </SvgIcon>
  );
};

export const PlusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 24 24" {...props}>
      <g transform="translate(-6 -6)">
        <path
          d="M18,7.5v21"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
        <path
          d="M7.5,18h21"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3"
        />
      </g>
    </SvgIcon>
  );
};

export const ToolsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 24 20" {...props}>
      <g
        id="Сгруппировать_11"
        data-name="Сгруппировать 11"
        transform="translate(-884.563 -870)"
      >
        <g
          id="Прямоугольник_111"
          data-name="Прямоугольник 111"
          transform="translate(884.563 870)"
          strokeWidth="1"
        >
          <rect width="24" height="20" rx="3" stroke="none" />
          <rect x="0.5" y="0.5" width="23" height="19" rx="2.5" fill="none" />
        </g>
        <text
          id="_3D"
          data-name="3D"
          transform="translate(886.563 886)"
          fill="#5b5b5b"
          stroke="rgba(0,0,0,0)"
          strokeWidth="1"
          fontSize="16"
          fontFamily="ArialMT, Arial"
        >
          <tspan x="0" y="0">
            3D
          </tspan>
        </text>
      </g>
    </SvgIcon>
  );
};

export const ExpandIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 612 612" {...props}>
      <g>
        <g>
          <g>
            <path
              d="M243.958,340.177L37.657,546.497L38.25,420.75c0-10.557-8.568-19.125-19.125-19.125S0,410.193,0,420.75v172.125
				c0,5.661,2.333,10.232,6.043,13.368C9.505,609.781,14.325,612,19.68,612h171.57c10.557,0,19.125-8.567,19.125-19.125
				c0-10.557-8.568-19.125-19.125-19.125H64.47l206.531-206.512L243.958,340.177z M605.956,5.757C602.495,2.219,597.676,0,592.34,0
				H420.75c-10.557,0-19.125,8.568-19.125,19.125c0,10.557,8.568,19.125,19.125,19.125h126.761L340.999,244.781l27.042,27.042
				l206.321-206.32L573.75,191.25c0,10.557,8.568,19.125,19.125,19.125S612,201.807,612,191.25V19.125
				C612,13.464,609.667,8.894,605.956,5.757z"
            />
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export const AxisIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon fontSize="inherit" viewBox="0 0 512 512" {...props}>
      <g>
        <g>
          <path
            d="M506.134,241.843c-0.006-0.006-0.011-0.013-0.018-0.019l-104.504-104c-7.829-7.791-20.492-7.762-28.285,0.068
			c-7.792,7.829-7.762,20.492,0.067,28.284L443.558,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h423.557
			l-70.162,69.824c-7.829,7.792-7.859,20.455-0.067,28.284c7.793,7.831,20.457,7.858,28.285,0.068l104.504-104
			c0.006-0.006,0.011-0.013,0.018-0.019C513.968,262.339,513.943,249.635,506.134,241.843z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
