import React, { Component, createRef } from 'react';
import { Button } from '@material-ui/core';
import { ReactSortable } from 'react-sortablejs';
import classnames from 'classnames';

import { ArProjectStep, ArProjectSubstep } from '../../buzzcommon';
import StylizedScrollbars from '../stylized/scrollbars';
import { BlackDots, PlusIcon } from '../../images/icons';
import styles from './styles.module.scss';

const ENABLED_KEY = 'sortable-enabled';
const DISABLED_KEY = 'sortable-disabled';

interface IProps {
  stepId?: number;
  subStepId?: number;
  steps: ArProjectStep[];
  isEditActionState: boolean;
  isAnimationPlaying: boolean;
  onSelect: (stepIndex: number, substepIndex?: number) => void;
  onStepCreate: (name: string) => void;
  onSubStepCreate: (name: string, stepId: number, subStepId?: number) => void;
  reorderSubSteps: (
    stepId: number,
    newSubStepIndex: number | undefined,
    newSubSteps: ArProjectSubstep[]
  ) => void;
  setSubsteps: (stepIndex: number, substeps: ArProjectSubstep[]) => void;
}

interface IState {
  sortableKey: string;
}

class ScenarioSteps extends Component<IProps, IState> {
  state: IState = { sortableKey: ENABLED_KEY };
  private stepsRef: React.RefObject<HTMLDivElement> = createRef();

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    if (
      prevProps.isEditActionState !== this.props.isEditActionState ||
      prevProps.isAnimationPlaying !== this.props.isAnimationPlaying
    ) {
      this.setState({
        sortableKey:
          this.props.isEditActionState || this.props.isAnimationPlaying
            ? DISABLED_KEY
            : ENABLED_KEY,
      });
    }

    if (
      prevProps.stepId !== this.props.stepId ||
      prevProps.subStepId !== this.props.subStepId
    ) {
      this.scrollToElement();
    }
  }

  scrollToElement = () => {
    if (this.props.stepId === undefined || !this.stepsRef.current) return;

    const step = { ...this.props.steps[this.props.stepId] };
    const stepElement = document.getElementById(step.uuid);

    if (!stepElement) return;

    let left = stepElement.offsetLeft;

    if (this.props.subStepId !== undefined) {
      const substep = step.substeps[this.props.subStepId];
      const substepElement = document.getElementById(substep.uuid);

      if (!substepElement) return;

      left +=
        substepElement.offsetLeft -
        this.stepsRef.current.clientWidth / 2 +
        substepElement.clientWidth / 2;
    }

    this.stepsRef.current.parentElement?.parentElement?.scroll({
      left,
      behavior: 'smooth',
    });
  };

  onSubstepSelect = (
    e: React.MouseEvent,
    stepId: number,
    substepId?: number
  ) => {
    e.stopPropagation();
    if (this.props.isAnimationPlaying) return;

    this.props.onSelect(stepId, substepId);
  };

  onSubStepCreate = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>,
    stepId: number,
    substepId?: number,
    isRight?: boolean
  ) => {
    e.stopPropagation();

    if (this.props.isAnimationPlaying) return;

    let newSubIndex;
    if (substepId || substepId === 0) {
      newSubIndex = substepId ? substepId : 0;
      if (isRight) {
        newSubIndex = substepId + 1;
      }
    } else {
      newSubIndex = this.props.steps[stepId].substeps.length;
    }

    this.props.onSubStepCreate(
      `Sub step ${this.props.steps[stepId].substeps.length + 1}`,
      stepId,
      newSubIndex
    );
  };

  addSubStepBtn = (stepId: number) => (
    <Button
      className={classnames(styles.addSubstepButton, styles.substepLevelButton)}
      variant="contained"
      disableElevation={true}
      disabled={this.props.isAnimationPlaying}
      onClick={(e) => this.onSubStepCreate(e, stepId)}
    >
      <PlusIcon className={styles.plusIcon} />
    </Button>
  );

  renderSteps = () => {
    return this.props.steps.map((step, stepIndex) => {
      const substeps = step.substeps.map((substep, substepIndex) => ({
        ...substep,
        id: substep.uuid,
      }));

      return (
        <div
          className={classnames(styles.step, {
            [styles.selected]:
              this.props.stepId === stepIndex &&
              (this.props.subStepId === undefined || this.props.stepId === 0),
          })}
          key={step.uuid}
          id={step.uuid}
          onClick={(e: React.MouseEvent) => this.onSubstepSelect(e, stepIndex)}
        >
          <p className={styles.stepTitle}>{step.name}</p>
          {stepIndex !== 0 && (
            <div className={styles.subStepsSection}>
              <ReactSortable
                key={this.state.sortableKey}
                disabled={
                  this.props.isEditActionState || this.props.isAnimationPlaying
                }
                list={substeps}
                setList={(newState) =>
                  this.props.setSubsteps(stepIndex, newState)
                }
                onEnd={(newState) =>
                  this.props.reorderSubSteps(
                    stepIndex,
                    newState.newIndex,
                    this.props.steps[stepIndex].substeps
                  )
                }
              >
                {this.renderSubsteps(stepIndex)}
              </ReactSortable>

              {this.addSubStepBtn(stepIndex)}
            </div>
          )}
        </div>
      );
    });
  };

  renderSubsteps = (stepIndex: number) =>
    this.props.steps[stepIndex].substeps.map((substep, substepIndex) => (
      <Button
        key={substep.uuid}
        id={substep.uuid}
        className={classnames(styles.substep, styles.substepLevelButton, {
          [styles.selected]:
            this.props.stepId === stepIndex &&
            this.props.subStepId === substepIndex,
        })}
        variant="contained"
        disableElevation={true}
        onClick={(e) => this.onSubstepSelect(e, stepIndex, substepIndex)}
      >
        {stepIndex !== 0 && <BlackDots className={styles.dots} />}
        <div
          className={classnames(styles.addOnSide, styles.left)}
          onClick={(e) => this.onSubStepCreate(e, stepIndex, substepIndex)}
        >
          +
        </div>
        <p className={styles.scenarioSubStepTitle}>{substep.name}</p>
        <div
          className={classnames(styles.addOnSide, styles.right)}
          onClick={(e) =>
            this.onSubStepCreate(e, stepIndex, substepIndex, true)
          }
        >
          +
        </div>
      </Button>
    ));

  render() {
    return (
      <div className={styles.scrollbarsWrapper}>
        <StylizedScrollbars>
          <div
            className={styles['scenario-steps-scroll-wrap']}
            ref={this.stepsRef}
          >
            {this.renderSteps()}

            <div className={styles.stepWrap}>
              <Button
                className={styles.addStepBtn}
                disabled={
                  this.props.isEditActionState || this.props.isAnimationPlaying
                }
                onClick={() => {
                  if (!this.props.isAnimationPlaying) {
                    this.props.onStepCreate(
                      `step ${this.props.steps.length + 1}`
                    );
                  }
                }}
              >
                + New step
              </Button>
            </div>
          </div>
        </StylizedScrollbars>
      </div>
    );
  }
}

export default ScenarioSteps;
