import { Switch, SwitchProps } from '@material-ui/core';
import React from 'react';
import styles from './styles.module.scss';

const StylizedSwitch = (props: SwitchProps) => {
  return (
    <Switch
      {...props}
      classes={{
        track: styles.track,
        root: styles.switch,
        checked: styles.checked,
        thumb: styles.thumb,
      }}
    />
  );
};

export default StylizedSwitch;
