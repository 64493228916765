import React, { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import CodeMirror from 'react-codemirror';
import pretty from 'pretty';
import classnames from 'classnames';

import { HtmlEditorConfig } from './htmlEditorConfig';

import 'react-quill/dist/quill.snow.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/darcula.css';
import './editor-styles.m.scss';
import 'codemirror/addon/scroll/simplescrollbars';
import 'codemirror/addon/scroll/simplescrollbars.css';
import styles from './styles.module.scss';
import { ArProjectStep } from '../../../buzzcommon';
import { StylizedModal, StylizedButton } from '../../stylized';

interface Props {
  open: boolean;
  onClose: () => void;
  step: ArProjectStep;
  onStepChange: (name: string, description: string) => void;
  postScenarioFile: (file: File) => Promise<any>;
  formScenarioFileUrl: (fileName: string) => string;
}

const htmlEditorConfig = new HtmlEditorConfig();

const HtmlEditorModal = (props: Props) => {
  const {
    open,
    step,
    onClose,
    onStepChange,
    postScenarioFile,
    formScenarioFileUrl,
  } = props;
  const [descriptionHtml, setDescriptionHtml] = useState(step.description);
  const [titleName, setTitleName] = useState(step.name);
  const [isPreview, setIsPreview] = useState(false);

  const editorRef: any = useRef<ReactQuill>();

  useEffect(() => {
    htmlEditorConfig.editorRef = editorRef;
    htmlEditorConfig.postScenarioFile = postScenarioFile;
    htmlEditorConfig.formScenarioFileUrl = formScenarioFileUrl;
  }, []);

  return (
    <StylizedModal
      open={open}
      onClose={onClose}
      className={styles.htmlEditorModal}
    >
      <div className={styles.modalContent}>
        <div className={styles['header-block']}>
          <p className={styles.label}>Title:</p>

          <input
            className={styles['title-input']}
            value={titleName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTitleName(e.target.value)
            }
            type="text"
          />
        </div>

        <div className={styles['main-block']}>
          <p className={styles.label}>Instructions:</p>

          <div className={styles.editorBlock}>
            {isPreview ? (
              <CodeMirror
                value={pretty(descriptionHtml)}
                options={{
                  lineNumbers: true,
                  tabSize: 2,
                  readOnly: true,
                  mode: 'htmlmixed',
                  theme: 'darcula',
                  scrollbarStyle: 'simple',
                }}
              />
            ) : (
              <ReactQuill
                theme="snow"
                className={styles.editor}
                onChange={(content: string) => setDescriptionHtml(content)}
                ref={editorRef}
                placeholder={'Type your instruction here...'}
                defaultValue={descriptionHtml}
                modules={htmlEditorConfig.modules}
                formats={htmlEditorConfig.formats}
              />
            )}
          </div>

          <div className={styles.buttons}>
            <StylizedButton
              colorType="secondary"
              className={classnames(styles.button, styles.previewButton)}
              onClick={() => setIsPreview(!isPreview)}
            >
              {isPreview ? 'Edit' : 'Show code'}
            </StylizedButton>

            <div className={styles.actions}>
              <StylizedButton
                colorType="default"
                className={styles.button}
                onClick={() => onStepChange(titleName, descriptionHtml)}
              >
                Save
              </StylizedButton>
              <StylizedButton
                colorType="secondary"
                className={styles.button}
                onClick={onClose}
              >
                Close
              </StylizedButton>
            </div>
          </div>
        </div>
      </div>
    </StylizedModal>
  );
};

export default HtmlEditorModal;
