import { Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { createMaterialPreview } from '../../buzzcommon/utils/BuzzArUtils';
import { ArMaterial, ArMaterials, CommonMaterials } from '../../buzzcommon';

import styles from './styles.module.scss';
import { MaterialIcon } from '../../images/icons';
import StylizedScrollbars from '../stylized/scrollbars';
import { MaterialEditorModal } from '../modals';

interface StoredMaterials {
  selectedObjectsMaterials: ArMaterials;
  popularMaterials: ArMaterials;
  historyMaterials: ArMaterials;
}

interface Props {
  selectedObjects: number[];
  projectMaterials: ArMaterials;
  commonMaterials?: CommonMaterials;
  isMaterialEditMode: boolean;
  isProjectMaterialsLoading: boolean;
  thumbnailSceneRef: React.RefObject<HTMLDivElement>;
  onMaterialSelect: (material: ArMaterial) => void;
  getSelectedMaterials: () => ArMaterials;
  setIsMaterialEditMode: (isMaterialEditMode: boolean) => void;
  addThumbnailInProjectMaterials: () => Promise<void>;
}

const HISTORY_LIMIT = 20;
const POPULAR_LIMIT = 30;

const Materials = (props: Props) => {
  const {
    selectedObjects,
    isMaterialEditMode,
    isProjectMaterialsLoading,
    projectMaterials,
    getSelectedMaterials,
    onMaterialSelect,
    setIsMaterialEditMode,
    addThumbnailInProjectMaterials,
  } = props;

  const storedMaterials = useRef<StoredMaterials>({
    selectedObjectsMaterials: {},
    popularMaterials: {},
    historyMaterials: {},
  });

  const [localHistoryMaterials, setLocalHistoryMaterials] =
    useState<ArMaterials>({ ...storedMaterials.current.historyMaterials });
  const [commonMaterials, setCommonMaterials] = useState(props.commonMaterials);
  const [isMaterialEditorModalOpen, setIsMaterialEditorModalOpen] =
    useState(false);
  const [isCommonMaterialsLoaded, setIsCommonMaterialsLoaded] = useState(false);

  useEffect(() => {
    if (!props.commonMaterials) return;

    const limitedCommonMaterials: ArMaterials = {};
    const newCommon: CommonMaterials = { ...props.commonMaterials };

    for (const [category, materials] of Object.entries(newCommon)) {
      if (Object.values(limitedCommonMaterials).length >= POPULAR_LIMIT) break;

      for (const [uuid, material] of Object.entries(materials)) {
        if (
          Object.values(limitedCommonMaterials).length >= POPULAR_LIMIT ||
          material.thumbnail
        )
          break;

        const thumbnail = createMaterialPreview(material);
        limitedCommonMaterials[uuid] = { ...material, thumbnail };

        newCommon[category][uuid] = { ...limitedCommonMaterials[uuid] };
      }
    }

    setCommonMaterials(newCommon);
    storedMaterials.current.popularMaterials = limitedCommonMaterials;
  }, [props.commonMaterials]);

  useEffect(() => {
    const materials = getSelectedMaterials();

    Object.entries(materials).forEach(([uuid, material]) => {
      if (!materials[uuid].thumbnail) {
        materials[uuid].thumbnail = createMaterialPreview(material);
      }
    });

    storedMaterials.current.selectedObjectsMaterials = materials;
    addThumbnailInProjectMaterials();
  }, [selectedObjects]);

  useEffect(() => {
    if (!isMaterialEditMode) {
      storedMaterials.current.historyMaterials = { ...localHistoryMaterials };
    }
  }, [props.isMaterialEditMode]);

  useEffect(() => {
    if (!isMaterialEditorModalOpen || !commonMaterials) return;
    setIsCommonMaterialsLoaded(false);
    const newCommon = { ...commonMaterials };
    const commonEntries = [...Object.entries(commonMaterials)];

    addThumbnailToCommon(commonEntries, newCommon);
  }, [isMaterialEditorModalOpen]);

  const addThumbnailToCommon = (
    commonEntries: (string | ArMaterials)[][],
    newCommon: CommonMaterials
  ) => {
    let iteration = 0;
    const amount = 3;
    const commonEntiresClone = [...commonEntries];

    commonEntries.forEach(([cat, mat], index: number) => {
      if (iteration >= amount) return;
      const category: string = cat as string;
      const materials = mat as ArMaterials;
      newCommon[category] = { ...materials, ...newCommon[category] };
      Object.entries(materials).forEach(([uuid, material]) => {
        if (iteration >= amount) return;

        if (!newCommon[category][uuid].thumbnail) {
          newCommon[category][uuid].thumbnail = createMaterialPreview(material);
        }
        delete (commonEntiresClone[index][1] as ArMaterials)[uuid];
        iteration += 1;
      });
      if (
        !commonEntiresClone[index] ||
        !commonEntiresClone[index].length ||
        Object.keys(commonEntiresClone[index][1] as ArMaterials).length === 0
      ) {
        commonEntiresClone.splice(0, 1);
      }
    });

    if (!commonEntiresClone || !commonEntiresClone.length) {
      setCommonMaterials(newCommon);
      setIsCommonMaterialsLoaded(true);
      return;
    } else {
      setTimeout(() => addThumbnailToCommon(commonEntiresClone, newCommon));
    }
  };

  const handleMaterialSelect = (selectedMaterial: ArMaterial) => {
    onMaterialSelect(selectedMaterial);

    if (localHistoryMaterials[selectedMaterial.uuid]) {
      delete localHistoryMaterials[selectedMaterial.uuid];
    }

    const newHistory = {
      [selectedMaterial.uuid]: selectedMaterial,
      ...localHistoryMaterials,
    };
    let slicedHistory;
    const entires = Object.entries(newHistory);
    if (entires.length >= HISTORY_LIMIT) {
      slicedHistory = Object.fromEntries(entires.slice(0, HISTORY_LIMIT));
    }
    setLocalHistoryMaterials(slicedHistory || newHistory);
  };

  const materialsToJsx = (materials: ArMaterials, isSelected: boolean) =>
    Object.values(materials).map((material) => (
      <div
        key={material.uuid}
        className={classnames(styles.element, {
          [styles.selected]: isSelected,
        })}
        onClick={() => {
          handleMaterialSelect(material);
        }}
      >
        {material.thumbnail ? (
          <img src={material.thumbnail} />
        ) : (
          <div className={styles.noThumb}>
            <p>?</p>
          </div>
        )}
      </div>
    ));

  const renderMaterialElements = () => {
    const selectedElements = materialsToJsx(
      storedMaterials.current.selectedObjectsMaterials as ArMaterials,
      true
    );
    const historyElements = materialsToJsx(
      storedMaterials.current.historyMaterials as ArMaterials,
      false
    );
    const popularElements = materialsToJsx(
      storedMaterials.current.popularMaterials as ArMaterials,
      false
    );

    return (
      <div className={styles.elementsWrapper}>
        <StylizedScrollbars disabledHorizontal={true} withScrollGradients={true}>
          <div className={styles.elements}>
            {[...selectedElements, ...historyElements, ...popularElements]}
          </div>
        </StylizedScrollbars>
      </div>
    );
  };

  return (
    <div
      className={classnames(
        styles.body,
        !!selectedObjects.length && isMaterialEditMode && styles.open,
        !selectedObjects.length && styles.disabled
      )}
      onClick={
        !isMaterialEditMode && selectedObjects.length
          ? () => setIsMaterialEditMode(true)
          : undefined
      }
    >
      <div
        className={styles.main}
        onClick={
          selectedObjects.length
            ? (e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsMaterialEditMode(!isMaterialEditMode);
              }
            : undefined
        }
      >
        <MaterialIcon className={styles.materialIcon} />
        <p className={styles.title}>Materials</p>
      </div>

      <div
        className={classnames(
          styles['material-tab'],
          !!selectedObjects.length && isMaterialEditMode && styles.open
        )}
      >
        {isProjectMaterialsLoading ? (
          <CircularProgress />
        ) : (
          <>
            {!!selectedObjects.length &&
              isMaterialEditMode &&
              renderMaterialElements()}

            <Button
              className={styles['show-more-btn']}
              onClick={() => setIsMaterialEditorModalOpen(true)}
            >
              Show all
            </Button>
          </>
        )}
      </div>

      {isMaterialEditorModalOpen && (
        <MaterialEditorModal
          open={isMaterialEditorModalOpen}
          isCommonMaterialsLoaded={isCommonMaterialsLoaded}
          commonMaterials={commonMaterials}
          projectMaterials={projectMaterials}
          onClose={() => setIsMaterialEditorModalOpen(false)}
          onMaterialSelect={handleMaterialSelect}
        />
      )}
    </div>
  );
};

export default Materials;
