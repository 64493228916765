import { Button, InputBase } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';

import PageList, { ListElement } from '../page-list';
import StylizedScrollbars from '../stylized/scrollbars';
import { TrashBin } from '../../images/icons';
import { ArProjectStep, ArProjectSubstep } from '../../buzzcommon';

import styles from './styles.module.scss';

interface Props {
  step: ArProjectStep;
  substep: ArProjectSubstep;
  substepIndex: number;
  onClose: () => void;
  deleteSubstep: (id: number) => void;
  createAction: () => void;
  deleteAction: (id: number) => void;
  selectAction: (id: number) => void;
  onStepSelect: () => void;
  updateSubstepInfo: (
    name: string,
    duration: number,
    description: string
  ) => void;
}

const SubstepPage = (props: Props) => {
  const {
    step,
    substep,
    substepIndex,
    onClose,
    deleteSubstep,
    createAction,
    deleteAction,
    selectAction,
    updateSubstepInfo,
    onStepSelect,
  } = props;

  const [elements, setElements] = useState<ListElement[]>();
  const [title, setTitle] = useState({
    isEdit: false,
    value: substep.name,
  });
  const [duration, setDuration] = useState({
    isEdit: false,
    value: substep.duration,
    temporaryValue: substep.duration.toString(),
  });
  const [description, setDescription] = useState(substep.description);

  useEffect(() => {
    setDuration({
      isEdit: false,
      value: substep.duration,
      temporaryValue: substep.duration.toString(),
    });
  }, [substep.duration]);

  useEffect(() => {
    setTitle({ isEdit: false, value: substep.name });
  }, [substep.name]);

  useEffect(() => {
    setDescription(substep.description);
  }, [substep.description]);

  useEffect(() => {
    setElements(
      substep.actions.map((action, index) => {
        return { id: index, name: action.actionType };
      })
    );
  }, [substep.actions]);

  const onTitleUpdate = () => {
    setTitle({ ...title, isEdit: false });
    updateSubstepInfo(title.value, duration.value, description);
  };

  const onDurationUpdate = () => {
    const value = parseFloat(duration.temporaryValue.replace(',', '.'));
    updateSubstepInfo(title.value, value, description);
    setDuration({ ...duration, isEdit: false, value });
  };

  return (
    <div className={styles.substep}>
      <div className={styles.stepInfoWrapper}>
        <div className={styles.stepInfo} onClick={onStepSelect}>
          <p className={styles.stepName}>{step.name}</p>
          {!!step.description && (
            <div className={styles.stepDescription}>
              {ReactHtmlParser(step.description)}
            </div>
          )}
        </div>
      </div>

      <div className={styles.scrollbarsWrapper}>
        <StylizedScrollbars>
          <div className={styles.main}>
            <div className={styles.name}>
              {title.isEdit ? (
                <InputBase
                  className={styles.input}
                  value={title.value}
                  onChange={(e) =>
                    setTitle({ ...title, value: e.target.value })
                  }
                  onBlur={onTitleUpdate}
                  onKeyDown={(e) => e.key === 'Enter' && onTitleUpdate()}
                />
              ) : (
                <p
                  className={styles.label}
                  onClick={() => setTitle({ ...title, isEdit: true })}
                >
                  {title.value}
                </p>
              )}
            </div>

            <div className={styles.duration}>
              <p className={styles.title}>Duration: </p>
              <>
                {duration.isEdit ? (
                  <InputBase
                    className={styles.input}
                    type="number"
                    value={duration.temporaryValue}
                    onChange={(e) =>
                      setDuration({
                        ...duration,
                        temporaryValue: e.target.value,
                      })
                    }
                    onBlur={onDurationUpdate}
                    onKeyDown={(e) => e.key === 'Enter' && onDurationUpdate()}
                  />
                ) : (
                  <p
                    className={styles.label}
                    onClick={() => setDuration({ ...duration, isEdit: true })}
                  >
                    {duration.value} sec
                  </p>
                )}
              </>
            </div>

            {elements && (
              <PageList
                elements={elements}
                title="Actions:"
                addButtonText="+ Add action"
                onSelect={selectAction}
                onDelete={deleteAction}
                onElementAdd={createAction}
              />
            )}

            <div className={styles.notes}>
              <p className={styles.notesTitle}>Notes</p>
              <InputBase
                className={styles.notesInput}
                multiline={true}
                rows={2}
                placeholder="Type your notes here..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                onBlur={() =>
                  updateSubstepInfo(title.value, duration.value, description)
                }
              />
            </div>
          </div>
        </StylizedScrollbars>
      </div>

      <div className={styles.buttons}>
        <Button className={styles.closeButton} onClick={() => onClose()}>
          Close
        </Button>

        <Button
          className={styles.deleteSubstepButton}
          onClick={() => deleteSubstep(substepIndex)}
        >
          <TrashBin className={styles.trashBinIcon} />
          Delete substep
        </Button>
      </div>
    </div>
  );
};

export default SubstepPage;
