import classnames from 'classnames';
import React, { CSSProperties, useState } from 'react';
import Scrollbars, { positionValues } from 'react-custom-scrollbars';

import styles from './styles.module.scss';

interface ScrollbarsProps {
  children: React.ReactNode;
  disabledVertical?: boolean;
  disabledHorizontal?: boolean;
  withScrollGradients?: boolean;
}

const StylizedScrollbars = (props: ScrollbarsProps) => {
  const {
    disabledVertical,
    disabledHorizontal,
    children,
    withScrollGradients,
    ...rest
  } = props;

  const thumbStyles: CSSProperties = {
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  };

  const verticalThumbStyle: CSSProperties = {
    display: disabledVertical ? 'none' : 'block',
    width: '10px',
  };

  const horizontalThumbStyle: CSSProperties = {
    display: disabledHorizontal ? 'none' : 'block',
    height: '10px',
  };

  const renderThumbVertical = ({ style, ...thumbProps }: any) => (
    <div
      style={{ ...style, ...thumbStyles, ...verticalThumbStyle }}
      {...thumbProps}
    />
  );

  const renderThumbHorizontal = ({ style, ...thumbProps }: any) => (
    <div
      style={{ ...style, ...thumbStyles, ...horizontalThumbStyle }}
      {...thumbProps}
    />
  );

  const [isTopShadow, setIsTopShadow] = useState(false);
  const [isBottomShadow, setIsBottomShadow] = useState(false);

  const scrollHandler = (values: positionValues) => {
    if (values.clientHeight - values.scrollHeight < 0) {
      setIsTopShadow(values.top > 0);

      setIsBottomShadow(values.top < 1);
    } else {
      setIsTopShadow(false);
      setIsBottomShadow(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      {withScrollGradients && (
        <div
          className={classnames(styles.topShadow, {
            [styles.invisibleShadow]: !isTopShadow,
          })}
        />
      )}
      {withScrollGradients && (
        <div
          className={classnames(styles.bottomShadow, {
            [styles.invisibleShadow]: !isBottomShadow,
          })}
        />
      )}
      <Scrollbars
        renderThumbHorizontal={renderThumbHorizontal}
        renderThumbVertical={renderThumbVertical}
        onScrollFrame={(values) => scrollHandler(values)}
        onUpdate={(values) => scrollHandler(values)}
        {...rest}
      >
        <div
          className={classnames(
            styles.scrollbarsBody,
            { [styles.disabledVertical]: disabledVertical },
            { [styles.disabledHorizontal]: disabledHorizontal }
          )}
        >
          {children}
        </div>
      </Scrollbars>
    </div>
  );
};

export default StylizedScrollbars;
