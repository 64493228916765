import { Fab, FabProps, Tooltip } from '@material-ui/core';
import classnames from 'classnames';
import React, { useState } from 'react';
import styles from './styles.module.scss';

interface StylizedFabProps extends FabProps {
  buttonType?: 'default' | 'secondary';
  isActive?: boolean;
  tooltipTitle?: string;
  iconClassName?: string;
  isFixating?: boolean;
  fixTime?: number;
}

const WithTooltip = (props: {
  children: React.ReactElement;
  tooltipTitle?: string;
  className?: string;
}) =>
  props.tooltipTitle ? (
    <Tooltip title={props.tooltipTitle} className={props.className}>
      {props.children}
    </Tooltip>
  ) : (
    props.children
  );

const StylizedFab = (props: StylizedFabProps) => {
  const {
    tooltipTitle,
    children,
    className,
    buttonType,
    isActive,
    iconClassName,
    isFixating,
    onClick,
    fixTime,
    ...rest
  } = props;

  let buttonStyle: string;

  switch (buttonType) {
    case 'secondary': {
      buttonStyle = styles.secondary;
      break;
    }
    case 'default':
    default: {
      buttonStyle = styles.default;
      break;
    }
  }

  const [isFixed, setIsFixed] = useState(false);

  const modifiedOnClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (isFixating) setIsFixed(true);
    if (onClick) onClick(e);
    setTimeout(() => setIsFixed(false), fixTime || 3000);
  };

  return (
    <WithTooltip tooltipTitle={tooltipTitle} className={className}>
      <div className={styles.wrapper}>
        <Fab
          className={classnames(
            styles.icon,
            buttonStyle,
            {
              [styles.fixed]: isFixed,
              [styles.active]: isActive,
            },
            iconClassName
          )}
          onClick={isFixating ? modifiedOnClick : onClick}
          {...rest}
        >
          {children}
        </Fab>
      </div>
    </WithTooltip>
  );
};

export default StylizedFab;
