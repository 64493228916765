import { Button, ButtonGroup, Fade, Modal } from '@material-ui/core';
import React, { useState } from 'react';
import classnames from 'classnames';
import Scrollbars from 'react-custom-scrollbars';

import { useEffect } from 'react';

import styles from './styles.module.scss';
import { CategorizedTools, ExtendedTool } from '../../../buzzcommon';
import { PackageIcon } from '../../../images/icons';
import { StylizedModal, StylizedButton } from '../../stylized';

interface Props {
  open: boolean;
  categorizedTools: CategorizedTools;
  onClose: () => void;
  addToolToProject: (uniqueId: string) => void;
}

enum TabName {
  Custom = 'Custom',
  Common = 'Common',
}

const ToolsModal = (props: Props) => {
  const { open, categorizedTools, onClose, addToolToProject } = props;

  const [tabName, setTabName] = useState(TabName.Custom);
  const [activeFolder, setActiveFolder] = useState('');
  const [customTools, setCustomTools] = useState<ExtendedTool[]>([]);

  useEffect(() => {
    let custom: ExtendedTool[] = [];

    Object.values(categorizedTools).forEach((tools) => {
      custom = [...custom, ...tools.filter((tool) => tool.custom)];
    });

    setCustomTools(custom);
  }, [categorizedTools]);

  const renderCommonFolders = () => {
    if (!categorizedTools)
      return <div className={styles.noItemsMessage}>No categories</div>;

    return (
      <div className={styles.scrollbarsWrapper}>
        <Scrollbars>
          <div className={styles.folders}>
            {Object.getOwnPropertyNames(categorizedTools).map(
              (categoryName, index) => (
                <div
                  key={index}
                  className={styles.folder}
                  onClick={() => setActiveFolder(categoryName)}
                >
                  <PackageIcon className={styles.packageIcon} />
                  <p className={styles.title}>{categoryName}</p>
                </div>
              )
            )}
          </div>
        </Scrollbars>
      </div>
    );
  };

  const renderTools = (tools: ExtendedTool[]) => {
    if (!tools.length)
      return <div className={styles.noItemsMessage}>No tools</div>;

    return (
      <div className={styles.scrollbarsWrapper}>
        <Scrollbars>
          <div className={styles.tools}>
            {Object.values(tools).map((tool) => (
              <div
                key={tool.uniqueId}
                className={styles.tool}
                onClick={() => addToolToProject(tool.uniqueId)}
              >
                {tool.thumbUrl && (
                  <img
                    src={tool.thumbUrl}
                    className={styles.image}
                    alt="thumbnail"
                  />
                )}
              </div>
            ))}
          </div>
        </Scrollbars>
      </div>
    );
  };

  const renderTabContent = () => {
    switch (tabName) {
      case TabName.Custom:
        return renderTools(customTools);
      case TabName.Common:
        return categorizedTools && activeFolder
          ? renderTools(categorizedTools[activeFolder])
          : renderCommonFolders();
    }
  };

  const onCommonMaterialsClick = () => {
    setTabName(TabName.Common);
    setActiveFolder('');
  };

  return (
    <StylizedModal open={open} onClose={onClose} title="Gallery">
      <div className={styles.body}>
        <ButtonGroup className={styles.buttonGroup}>
          <Button
            onClick={() => setTabName(TabName.Custom)}
            className={classnames(styles.tabButton, styles.firstButton, {
              [styles.bold]: tabName === TabName.Custom,
            })}
          >
            {TabName.Custom}
          </Button>
          <Button
            onClick={onCommonMaterialsClick}
            className={classnames(styles.tabButton, styles.lastButton, {
              [styles.bold]: tabName === TabName.Common,
            })}
          >
            {TabName.Common}
          </Button>
        </ButtonGroup>

        <div className={styles.elements}>{renderTabContent()}</div>

        <StylizedButton
          colorType="secondary"
          className={styles.closeButton}
          onClick={onClose}
        >
          Close
        </StylizedButton>
      </div>
    </StylizedModal>
  );
};

export default ToolsModal;
