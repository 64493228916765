import React, { createRef, useState } from 'react';
import styles from './styles.module.scss';
import classnames from 'classnames';

interface IProps {
  file: File | undefined;
  onFileSelect: (file: File) => void;
  onInvalidFileSelected?: (errorMessage: string) => void;
  acceptExtensions?: string[];
}

const Draggable = (props: IProps) => {
  const { file, onFileSelect, acceptExtensions, onInvalidFileSelected } = props;

  const uploadModelRef = createRef<HTMLInputElement>();
  const [dragging, setDragging] = useState<boolean>(false);

  const handleUploadClick = () => uploadModelRef.current?.click();

  const fileSelectedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) selectFiles(files);

    event.target.value = '';
  };

  const overrideEventDefaults = (event: React.DragEvent<HTMLDivElement>) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const onDrag = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    setDragging(true);
  };

  const onDragEnter = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    setDragging(true);
  };

  const onDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);
    setDragging(dragging ? false : true);
  };

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    overrideEventDefaults(event);

    const files = event.dataTransfer.files;
    selectFiles(files);

    setDragging(false);
  };

  const selectFiles = (files: FileList) => {
    if (files && files[0]) {
      if (isValidFile(files[0])) {
        onFileSelect(files[0]);
      } else if (onInvalidFileSelected) {
        onInvalidFileSelected('Unable to select file: Incorrect extension');
      }
    }
  };

  const getFileExtension = (selectedFile: File) =>
    selectedFile && selectedFile.name.split('.').pop();

  const isValidFile = (selectedFile: File) => {
    const fileExtension = getFileExtension(selectedFile);

    return (
      fileExtension !== undefined && acceptExtensions?.includes(fileExtension)
    );
  };

  const isImage = () => {
    if (!file) return false;

    const fileExtension = getFileExtension(file);
    return fileExtension && ['jpg', 'jpeg', 'png'].includes(fileExtension)
      ? true
      : false;
  };

  const acceptExtensionsToString = () =>
    acceptExtensions && acceptExtensions.map((value) => `.${value}`).join(', ');

  return (
    <div className={styles['upload-field']}>
      <div
        className={classnames(styles['upload-area'], {
          [styles.dragging]: dragging,
        })}
        draggable={true}
        onDragStart={onDrag}
        onDragEnd={onDrag}
        onDragOver={onDrag}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onClick={handleUploadClick}
      >
        {isImage() ? (
          <img
            src={URL.createObjectURL(file)}
            className={styles['drag-and-drop-image']}
            alt="drag-and-drop-image"
          />
        ) : (
          <div className={styles['drag-and-drop-text']}>
            <div className={styles['drag-and-drop-text-main']}>
              <p style={{ fontWeight: 'bold' }}>Drag & Drop</p>
              <p>file here or click to upload your model</p>
            </div>

            {acceptExtensions && <p>(Only {acceptExtensionsToString()})</p>}

            {file && <p>{`Selected file: ${file.name}`}</p>}
          </div>
        )}
      </div>

      <input
        type="file"
        accept={acceptExtensionsToString()}
        onChange={fileSelectedHandler}
        ref={uploadModelRef}
        style={{ display: 'none' }}
      />
    </div>
  );
};

export default Draggable;
