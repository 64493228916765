import { Button, Select, MenuItem } from '@material-ui/core';
import classnames from 'classnames';
import React, { useState } from 'react';

import { changedActionParam, IActiveAction } from '../../buzzcommon';
import { ActionTypes, EffectsTypes } from '../../types/default-objects';
import { AdvanceInputsForAction } from '../advance-inputs-for-actions';

import { AxisIcon, Toggle } from '../../images/icons';
import styles from './styles.module.scss';
import {
  StylizedSliders,
  StylizedScrollbars,
  StylizedFab,
  StylizedButton,
} from '../stylized';
interface Props {
  action: IActiveAction;
  isNew: boolean;
  onActiveActionChange: (
    action: IActiveAction,
    changedParam: changedActionParam
  ) => void;
  onChangeCenter: (onCenterChange: 'moveCenter' | 'resetCenter') => void;
  onInitialPositionSet: () => void;
  saveAction: () => void;
  onClose: () => void;
  onActionChange: (
    actionParam: 'actionType' | 'effects',
    value: string
  ) => void;
  onCancelActionEditing: () => void;
  onResetAxis: (
    changeParam: 'resetAxisX' | 'resetAxisY' | 'resetAxisZ'
  ) => void;
  onMoveAxis: (changeParam: 'moveAxisStart' | 'moveAxisEnd') => void;
  onActionTransformChange: (
    transformParam: 'axisRotation' | 'screwMove' | 'screwTurns',
    changedValue: number
  ) => void;
}

const angleMarks = [
  { value: 0, label: '-180°' },
  { value: 9, label: '-90°' },
  { value: 18, label: '0°' },
  { value: 27, label: '90°' },
  { value: 36, label: '180°' },
];

const turnMarks = [
  { value: -10, label: '-10' },
  { value: -5, label: '-5' },
  { value: 0, label: '0' },
  { value: 5, label: '5' },
  { value: 10, label: '10' },
];

const moveMarks = [
  { value: -5, label: '-5' },
  { value: -2.5, label: '-2.5' },
  { value: 0, label: '0' },
  { value: 2.5, label: '2.5' },
  { value: 5, label: '5' },
];

const ActionPage = (props: Props) => {
  const {
    action,
    isNew,
    onActiveActionChange,
    onChangeCenter,
    onInitialPositionSet,
    saveAction,
    onActionChange,
    onCancelActionEditing,
    onResetAxis,
    onActionTransformChange,
    onMoveAxis,
  } = props;

  const [selectedEffect, setSelectedEffect] = useState(
    action.effects.length ? action.effects[0].type : ''
  );
  const [isAdvancedOptions, setIsAdvancedOptions] = useState(false);

  const onSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const effect = event.target.value as string;
    onActionChange('effects', effect);
    setSelectedEffect(event.target.value as string);
  };

  const handleActionTransformChange = (
    transformParam: 'axisRotation' | 'screwMove' | 'screwTurns',
    changedValue: number | number[]
  ) => {
    const value =
      typeof changedValue === 'number' ? changedValue : changedValue[0];

    onActionTransformChange(transformParam, value);
  };

  const renderActionTypeButton = (type: ActionTypes, title: string) => {
    return (
      <Button
        className={classnames(styles.typeButton, {
          [styles.activeButton]: action.actionType === type,
        })}
        onClick={() => onActionChange('actionType', type)}
      >
        {title}
      </Button>
    );
  };

  const renderSliders = () => {
    if (action.actionType === ActionTypes.transform) return;

    if (action.actionType === ActionTypes.axisRotation) {
      return (
        <div className={styles.slider}>
          <StylizedSliders
            min={0}
            max={36}
            step={0.5}
            track={false}
            defaultValue={0}
            scale={(x) => (x - 18) * 10}
            marks={angleMarks}
            valueLabelDisplay="auto"
            value={action.transform.axisRotation / 10 + 18}
            getAriaValueText={(value: number) => `${value}°`}
            onChange={(e, value: number | number[]) =>
              handleActionTransformChange('axisRotation', value)
            }
          />
        </div>
      );
    }

    if (action.actionType === ActionTypes.screwUnscrew) {
      return (
        <div className={styles.sliders}>
          <div className={styles.sliderBlock}>
            <p>Move:</p>
            <div className={styles.slider}>
              <StylizedSliders
                min={-5}
                max={5}
                step={0.5}
                track={false}
                defaultValue={0}
                marks={moveMarks}
                valueLabelDisplay="auto"
                value={action.transform.screwMove}
                onChange={(e, value: number | number[]) =>
                  handleActionTransformChange('screwMove', value)
                }
              />
            </div>
          </div>

          <div className={styles.sliderBlock}>
            <p>Turn:</p>
            <div className={styles.slider}>
              <StylizedSliders
                min={-10}
                max={10}
                step={0.5}
                track={false}
                defaultValue={0}
                marks={turnMarks}
                valueLabelDisplay="auto"
                value={action.transform.screwTurns}
                onChange={(e, value: number | number[]) =>
                  handleActionTransformChange('screwTurns', value)
                }
              />
            </div>
          </div>
        </div>
      );
    }
  };

  const tooltips: { [key: string]: string } = {
    transform: 'Move/scale/rotate selected objects to their final position',
    axisRotation: 'Set rotation axis and angle for selected objects',
    screw: 'Set rotation axis and screw turns for selected objects',
    default: 'Move/scale/rotate selected objects to their final position',
  };

  return (
    <div className={styles.actionPage}>
      <div className={styles.scrollbarsWrappers}>
        <StylizedScrollbars disabledHorizontal={true}>
          <div className={styles.actionHeader}>
            <p className={styles.title}>
              {isNew ? 'New action' : 'Edit action'}
            </p>
            <div className={styles.typesWrapper}>
              <p className={styles.typesTitle}>Actions:</p>
              <div className={styles.actionTypes}>
                {renderActionTypeButton(ActionTypes.transform, 'Transform')}
                {renderActionTypeButton(ActionTypes.screwUnscrew, 'Screw')}
                {renderActionTypeButton(
                  ActionTypes.axisRotation,
                  'Axis Rotation'
                )}
              </div>
              <p className={styles.secondaryInformation}>
                {tooltips[action.actionType] || tooltips.default}
              </p>
            </div>
          </div>

          <div className={styles.main}>
            {renderSliders()}

            <div className={styles.actionEffects}>
              <p className={styles.title}>Action effects:</p>
              <Select
                className={styles.select}
                value={selectedEffect}
                onChange={onSelectChange}
                classes={{
                  root: styles.effectSelect,
                  outlined: styles.outlinedSelect,
                  select: styles.selectItem,
                  icon: styles.selectIcon,
                }}
                MenuProps={{ classes: { paper: styles.menu } }}
              >
                <MenuItem value="" classes={{ root: styles.menuItem }}>
                  <em>None</em>
                </MenuItem>
                {Object.keys(EffectsTypes).map((key, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={key}
                      classes={{ root: styles.menuItem }}
                    >
                      {key}
                    </MenuItem>
                  );
                })}
              </Select>
            </div>

            <div
              className={classnames(styles.mainButtons, {
                [styles.isTransformAction]:
                  action.actionType === ActionTypes.transform,
              })}
            >
              <div className={styles.defaultAxes}>
                <p>Default axes:</p>

                <div className={styles.axesButtons}>
                  <StylizedFab
                    iconClassName={styles.axisButton}
                    tooltipTitle={'Reset axis X'}
                    onClick={() => onResetAxis('resetAxisX')}
                    isFixating={true}
                  >
                    <AxisIcon
                      className={classnames(styles.axis, styles.axisX)}
                    />
                  </StylizedFab>

                  <StylizedFab
                    iconClassName={styles.axisButton}
                    tooltipTitle={'Reset axis Y'}
                    onClick={() => onResetAxis('resetAxisY')}
                    isFixating={true}
                  >
                    <AxisIcon
                      className={classnames(styles.axis, styles.axisY)}
                    />
                  </StylizedFab>

                  <StylizedFab
                    iconClassName={styles.axisButton}
                    tooltipTitle={'Reset axis Z'}
                    onClick={() => onResetAxis('resetAxisZ')}
                    isFixating={true}
                  >
                    <AxisIcon
                      className={classnames(styles.axis, styles.axisZ)}
                    />
                  </StylizedFab>
                </div>
              </div>

              <Button
                className={classnames(styles.advanced, {
                  [styles.isExpanded]: isAdvancedOptions,
                })}
                onClick={() => setIsAdvancedOptions(!isAdvancedOptions)}
              >
                <p>
                  {action.actionType === ActionTypes.transform
                    ? 'Advanced options'
                    : 'Custom axis'}
                </p>
                <Toggle className={styles.toggleIcon} />
              </Button>
            </div>

            {isAdvancedOptions && (
              <AdvanceInputsForAction
                activeAction={action}
                actionType={action.actionType}
                onActiveActionChange={onActiveActionChange}
                onChangeCenter={onChangeCenter}
                onSetInitialPosition={onInitialPositionSet}
                onMoveAxis={onMoveAxis}
              />
            )}
          </div>
        </StylizedScrollbars>
      </div>

      <div className={styles.buttons}>
        <StylizedButton
          colorType="default"
          className={styles.saveButton}
          onClick={saveAction}
        >
          Save
        </StylizedButton>

        <StylizedButton
          colorType="secondary"
          className={styles.backButton}
          onClick={onCancelActionEditing}
        >
          Back
        </StylizedButton>
      </div>
    </div>
  );
};

export default ActionPage;
