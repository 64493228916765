import React, { useState } from 'react';
import { CircularProgress } from '@material-ui/core';

import styles from './styles.module.scss';
import { ArModel } from '../../../buzzcommon';
import Draggable from '../../draggable';
import { StylizedModal, StylizedButton } from '../../stylized';

interface IProps {
  open: boolean;
  onClose: () => void;
  onModelUpload: (file: File) => Promise<ArModel | undefined>;
  onProjectSave: () => Promise<boolean>;
}

const UploadModal = (props: IProps) => {
  const { open, onClose, onModelUpload } = props;

  const [modelFile, setModelFile] = useState<File | undefined>();
  const [isFileUploading, setIsFileUploading] = useState(false);

  const modelSelectedHandler = (file: File) => {
    setModelFile(file);
  };

  const fileSavedHandler = async () => {
    if (!modelFile) return;

    setIsFileUploading(true);
    await onModelUpload(modelFile);
    setIsFileUploading(false);
  };

  return (
    <StylizedModal
      open={open}
      onClose={onClose}
      className={styles.modalContent}
    >
      <div className={styles.modalBody}>
        <div className={styles['draggable-model']}>
          <Draggable
            onFileSelect={modelSelectedHandler}
            file={modelFile}
            acceptExtensions={['fbx']}
          />
        </div>

        <div className={styles.buttons}>
          <StylizedButton
            colorType="default"
            onClick={fileSavedHandler}
            disabled={isFileUploading}
            className={styles.button}
          >
            {isFileUploading ? (
              <CircularProgress
                size={24}
                className={styles['progress-loader']}
              />
            ) : (
              <span>Upload</span>
            )}
          </StylizedButton>

          <StylizedButton
            colorType="secondary"
            onClick={onClose}
            disabled={isFileUploading}
            className={styles.button}
          >
            Cancel
          </StylizedButton>
        </div>
      </div>
    </StylizedModal>
  );
};

export default UploadModal;
