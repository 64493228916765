import React, { useState } from 'react';
import { ExtendedTool, Tool } from '../../../buzzcommon';
import { TrashBin } from '../../../images/icons';
import { StylizedModal, StylizedInput, StylizedButton } from '../../stylized';
import AbsoluteLoader from '../../stylized/AbsoluteLoader/AbsoluteLoader';
import styles from './styles.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  tool: ExtendedTool;
  onToolDelete: (tool: ExtendedTool) => Promise<void>;
  onToolChange: (oldTool: ExtendedTool, newTool: ExtendedTool) => Promise<Tool>;
  addToolToProject: (uniqueId: string) => Promise<void>;
}

const ToolInformationModal = (props: Props) => {
  const { open, tool, onClose, onToolDelete, onToolChange, addToolToProject } =
    props;

  const [name, setName] = useState(tool.name);

  const [isLoading, setIsLoading] = useState(false);

  const handleToolChange = () => {
    setIsLoading(true);
    const newTool: ExtendedTool = { ...tool, name };
    onToolChange(tool, newTool);
    setIsLoading(false);
    onClose();
  };

  const handleToolDelete = () => {
    setIsLoading(true);
    onToolDelete(tool);
    setIsLoading(false);
    onClose();
  };

  const handleToolAdd = () => {
    setIsLoading(true);
    addToolToProject(tool.uniqueId);
    setIsLoading(false);
    onClose();
  };

  return (
    <StylizedModal
      open={open}
      onClose={onClose}
      className={styles.toolInformationModal}
      title="Tool information"
    >
      <div className={styles.body}>
        {isLoading && <AbsoluteLoader />}
        <StylizedInput
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={styles.input}
          inputClass={styles.inputElement}
        />

        <img src={tool.thumbUrl} className={styles.thumbImage} />

        <div className={styles.buttons}>
          <TrashBin onClick={handleToolDelete} className={styles.trashbin} />

          <div className={styles.centeredButton}>
            <StylizedButton
              colorType="default"
              className={styles.button}
              onClick={handleToolAdd}
            >
              Add to scene
            </StylizedButton>

            <StylizedButton
              colorType="default"
              className={styles.button}
              onClick={handleToolChange}
            >
              Save
            </StylizedButton>
          </div>

          <StylizedButton
            colorType="secondary"
            className={styles.closeButton}
            onClick={onClose}
          >
            Close
          </StylizedButton>
        </div>
      </div>
    </StylizedModal>
  );
};

export default ToolInformationModal;
