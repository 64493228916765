import { Slider, SliderProps, SliderTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import classnames from 'classnames';
import React, { ChangeEvent, FormEvent } from 'react';

import styles from './styles.module.scss';

interface Props extends Omit<SliderProps, 'onChange'> {
  onChange:
    | ((event: ChangeEvent<{}>, value: number | number[]) => void)
    | undefined;
}

const StylizedSlider = (props: Props) => {
  const { className, ...rest } = props;
  return (
    <Slider
      {...rest}
      className={classnames(styles.stylizedSlider, props.className)}
      classes={{
        root: styles.root,
        rail: styles.rail,
        markLabel: styles.markLabel,
        thumb: styles.thumb,
        mark: styles.mark,
      }}
    />
  );
};

export default StylizedSlider;
