import { Tool } from '../light-editor/src/buzzcommon';
import { api, apiConfig, BACKEND_URL } from './configure';
import {
  ToolsResponse,
  ToolResponse,
  FileResponse,
  AddItemToProjectResponse,
  WithErrors,
} from './types';

export const handleResponse = <T>(apiResponse: {
  data: T;
  errors?: string[];
}) => {
  if (apiResponse.errors) {
    throw Error(apiResponse.errors[0]);
  }

  return apiResponse.data;
};

export const getGallery = async () => {
  const url = `/api/gallery/items?ticket=${apiConfig.token}`;

  return handleResponse<ToolsResponse['data']>(
    (await api.get<ToolsResponse>(url)).data
  );
};

export const getGalleryItem = async (uniqueId: string) => {
  const url = `/api/gallery/items/${uniqueId}?ticket=${apiConfig.token}`;

  return handleResponse<ToolResponse['data']>(
    (await api.get<ToolResponse>(url)).data
  );
};

export const getGalleryItemThumb = async (uniqueId: string) => {
  const url = `/api/gallery/items/${uniqueId}/thumb?ticket=${apiConfig.token}`;

  return handleResponse<FileResponse['data']>(
    (await api.get<FileResponse>(url)).data
  );
};

export const getGalleryItemFbxUrl = (uniqueId: string) => {
  return `${BACKEND_URL}/api/gallery/items/${uniqueId}/fbx?ticket=${apiConfig.token}`;
};

export const getGalleryItemFbx = async (uniqueId: string) => {
  const url = `/api/gallery/items/${uniqueId}/fbx?ticket=${apiConfig.token}`;

  return handleResponse<FileResponse['data']>(
    (await api.get<FileResponse>(url)).data
  );
};

export const createGalleryItem = async (tool: Tool) => {
  const url = `/api/gallery/items?ticket=${apiConfig.token}`;

  return handleResponse<ToolResponse['data']>(
    (await api.post<ToolResponse>(url, tool)).data
  );
};

export const updateGalleryItem = async (tool: Tool) => {
  const url = `/api/gallery/items?ticket=${apiConfig.token}`;

  return handleResponse<ToolResponse['data']>(
    (await api.patch<ToolResponse>(url, tool)).data
  );
};

export const uploadGalleryItemFbx = async (uniqueId: string, file: File) => {
  const url = `/api/gallery/items/${uniqueId}/uploadfbx?ticket=${apiConfig.token}`;

  const formData = new FormData();
  formData.append('file', file);

  return handleResponse<ToolResponse['data']>(
    (await api.post<ToolResponse>(url, formData)).data
  );
};

export const uploadGalleryItemThumb = async (uniqueId: string, file: File) => {
  const url = `/api/gallery/items/${uniqueId}/uploadthumb?ticket=${apiConfig.token}`;

  const formData = new FormData();
  formData.append('file', file);

  return handleResponse<ToolResponse['data']>(
    (await api.post<ToolResponse>(url, formData)).data
  );
};

export const deleteGalleryItem = async (uniqueId: string) => {
  const url = `/api/gallery/items?ticket=${apiConfig.token}`;
  const data = { uniqueId };

  return handleResponse<void>(await api.delete(url, { data }));
};

export const addGalleryItemToProject = async (toolId: string) => {
  const url = `/api/scenarios/${apiConfig.scenarioId}/projects/${apiConfig.projectId}/addgalleryitem/${toolId}?ticket=${apiConfig.token}`;

  return handleResponse<AddItemToProjectResponse['data']>(
    (await api.post<AddItemToProjectResponse>(url)).data
  );
};

export const addModelToGallery = async (modelId: number, tool: Tool) => {
  const url = `/api/scenarios/${apiConfig.scenarioId}/projects/${apiConfig.projectId}/models/${modelId}/togallery?ticket=${apiConfig.token}`;

  return handleResponse<ToolResponse['data']>(
    (await api.post<ToolResponse>(url, tool)).data
  );
};
